import React from "react";

const LeaveTypeIcon = ({ width, height }) => {
  return (
<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 5H1" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 1H1" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 9H1" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 13H1" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};

export default LeaveTypeIcon;
