import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import {
  Circle,
  MapContainer,
  Marker,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { CompanyBranchesIcon } from "../../icons/General";

const BranchMap = ({ setCityPosition, cityPosition, setFieldValue, radius }) => {
  const mapRef = useRef();
  const [center, setCenter] = useState([53.78213424391142, 20.440100480796453]);

  useEffect(() => {
    if (cityPosition && cityPosition?.length < 1) return;
    mapRef.current?.flyTo(cityPosition, mapRef?.current?.getZoom(), {
      duration: 1.5,
      animate: true,
    });
  }, [cityPosition]);

  const selectIcon = () => {
    const mapIcon = L.divIcon({
      className: "lastPolygonIdMarker",
      html: renderToString(
        <div className=" relative">
          <CompanyBranchesIcon width={40} />
        </div>
      ),
    });
    return mapIcon;
  };

  const DetectClick = () => {
    useMapEvents({
      click: (e) => {
        const lat = e?.latlng?.lat;
        const long = e?.latlng?.lng;
        setFieldValue("lat", lat);
        setFieldValue("long", long);
        setCityPosition([lat, long]);
        setCenter([lat, long]);
      },
    });
  };

  return (
    <div className="w-full mobile:min-w-[95vw] laptop:min-w-0 h-full rounded-xl z-0 relative pt-4">
      <MapContainer
        ref={mapRef}
        center={center}
        zoom={10}
        minZoom={2}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
      >
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />

        {cityPosition && cityPosition?.length > 0 && (
          <>
            {" "}
            {radius > 0 && (
              <Circle
                center={cityPosition}
                pathOptions={{ fillColor: "red" }}
                radius={radius}
              />
            )}
            <Marker icon={selectIcon()} position={cityPosition} />
          </>
        )}
        <DetectClick />
      </MapContainer>
    </div>
  );
};

export default BranchMap;
