import { Dropdown, Popover, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LuLogOut } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../context/NotifacitionContext";
import { useAuthContext } from "../../context/UserContext";
import { getRequestHttps } from "../../libs/AllGetRequest/HttpsHelper";
import WorldIcon from "../icons/WorldIcon";

const Header = () => {
  const { title, isEditMode, setIsEditMode, setSelectCompany, selectCompany } =
    useNotificationContext();
  const [t, i18n] = useTranslation("global");
  const { profil, logOut } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchValueLength, setSearchValueLength] = useState();
  const [searchFocus, setSearchFocus] = useState();
  const [searchValue, setSearchValue] = useState();
  const [searchLoading, setSearchloading] = useState(false);
  const [searchData, setSearchData] = useState();
  const [open, setOpen] = useState(false);

  const [selectOptions, setSelectOptions] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const items = [
    {
      key: "en",
      label: <p>English</p>,
      disabled: i18n.language === "en",
    },
    {
      key: "tr",
      label: <p>Türkçe</p>,
      disabled: i18n.language === "tr",
    },
    {
      key: "da",
      label: <p>Dansk</p>,
      disabled: i18n.language === "da",
    },
  ];

  const getCompanies = async () => {
    let data;
    try {
      data = await getRequestHttps("v1/company");
      const companiesList = data?.map((e) => {
        return {
          branch: e?.branch_label,
          department: e?.department_label,
          disabled: false,
          isActive: e?.is_active,
          label: e?.name,
          value: e?.id,
          image: e?.logo,
        };
      });
      setCompanyList(companiesList);
    } catch (e) {}
  };

  useEffect(() => {
    profil.isSuperAdmin === 0 && getCompanies();
  }, []);

  useEffect(() => {
    if (!companyList) return;
    const value = companyList.map((item) => {
      return {
        ...item,
        disabled:
          item.value === selectCompany?.company_id
            ? true
            : profil?.userType === 1
            ? !item?.isActive
            : false,
      };
    });
    setSelectOptions(value);
  }, [selectCompany?.company_id, companyList?.length]);

  const onChange = async (e, value) => {
    navigate("/company");
    setSelectCompany({
      image: value?.image,
      company_name: value?.label,
      company_id: value?.value,
      branch: value?.branch,
      department: value?.department,
    });
    localStorage.setItem(
      "selectCompany",
      JSON.stringify({
        image: value?.image,
        company_name: value?.label,
        company_id: value?.value,
        branch: value?.branch,
        department: value?.department,
      })
    );
  };

  // useEffect(() => {
  //   const fetcher = async () => {
  //     if (searchValue?.length > 2) {
  //       setSearchloading(true);
  //       try {
  //         const data = await getRequestHttps("search", {
  //           search: searchValue,
  //         });
  //         console.log("data", data);
  //         setSearchData(data);
  //       } finally {
  //         setSearchloading(false);
  //       }
  //     } else if (searchValue?.length <= 2 || !searchValue) {
  //       setSearchData();
  //     }
  //   };
  //   fetcher();
  // }, [searchFocus, searchValue]);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang.key);
    localStorage.setItem("lng", lang.key);
  };

  const menuProps = {
    items,
    onClick: handleChangeLanguage,
  };

  const changeEditMode = (e) => {
    localStorage.setItem("editMode", e);
    setIsEditMode(e);
  };

  return (
    <div className="flex justify-between w-full mb-2">
      <div className="flex gap-3 text-sm md:text-base capitalize">
        {title?.map((item, index) => (
          <div
            key={index}
            className={`flex gap-3 font-medium cursor-default ${
              index !== title?.length - 1
                ? "text-[#858f91b3]"
                : "text-[#53626F]"
            }`}
          >
            {item?.title ? (
              <Link
                className="hover:text-[#53626F] duration-200 hover:underline"
                to={item?.href}
              >
                {item?.title}{" "}
              </Link>
            ) : (
              <p className={`capitalize textHeaderTitle ml-4`}>{item}</p>
            )}
            {index !== title?.length - 1 && <p>/</p>}
          </div>
        ))}
      </div>

      <div className="flex gap-5 items-center justify-end">
        {profil.isSuperAdmin === 0 &&
          location?.pathname?.split("/")[1] !== "companies" && (
            <Link
              onClick={() => {
                localStorage.removeItem("selectCompany");
                setSelectCompany();
              }}
              to="/companies"
              className="p-1 text-sm text-[#acb3b5] rounded-lg border hover:bg-[#407BFF] hover:text-white duration-300 relative flex"
            >
              {t("general.companies_page")}
            </Link>
          )}
        {profil.isSuperAdmin === 0 && (
          <Select
            placeholder={t("general.select_company")}
            optionFilterProp="label"
            style={{ color: "#acb3b5" }}
            value={selectCompany?.company_name}
            className="w-36 text-[#acb3b5] rounded-lg"
            options={selectOptions}
            size="middle"
            showSearch
            allowClear={false}
            // value={state?.value}
            onChange={(e, value) => onChange(e, value)}
          />
        )}

        {profil?.isSuperAdmin === 0 && (
          <div className="flex gap-1 items-center">
            <div className="row">
              <div className="col-6 text-end fw-bold">
                <label htmlFor="edit_mode" className="formTitle mt-0">
                  {t("general.edit")}
                </label>
              </div>
              <div className="col-6">
                <Switch
                  id="edit_mode"
                  className="custom-switch"                  
                  checked={isEditMode}
                  onChange={(e) => changeEditMode(e)}
                  checkedChildren={t("general.open_edit")}
                  unCheckedChildren={t("general.close_edit")}
                />
              </div>
            </div>
          </div>
        )}
        <Dropdown
          menu={menuProps}
          on
          placement="bottomRight"
          trigger={["click"]}
        >
          <div className="me-5 cursor-pointer hover:scale-110 duration-300 text-[#acb3b5] flex items-center space-x-1">
          <p className="m-0 me-2 infoTexts">{t("general.language")}</p>
            <WorldIcon />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
