import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useNotificationContext } from "../../context/NotifacitionContext";
import { useAuthContext } from "../../context/UserContext";
import Dashboard from "../../helpers/Dashboard";
import Login from "../../pages/Login";
import ResetPassword from "../../pages/ResetPassword";
import Register from "../../pages/Register";
import MapWrapper from "../../pages/MapWrapper/MapWrapper";
import { PersonelDetail } from "../Modules/Personel";
import BranchRoutes from "../RoutesComponents/BranchRoutes";
import CompaniesRoutes from "../RoutesComponents/CompaniesRoutes";
import CompanyRoutes from "../RoutesComponents/CompanyRoutes";
import DepartmentRoutes from "../RoutesComponents/DepartmentRoutes";
import PersonelRoutes from "../RoutesComponents/PersonelRoutes";
import UsersRoutes from "../RoutesComponents/UsersRoutes";
import Agreement from "../../pages/Agreement";
import TermsOfUse from "../../pages/TermsOfUse";
import Subscription from "../../pages/Subscription";
import Report from "../../pages/Report";
import Message from "../../pages/Message";

const RoutesPage = () => {
  const { profil } = useAuthContext();
  const { selectCompany } = useNotificationContext();

  const user = JSON.parse(localStorage.getItem("user"));
  const RestrictedAccess = user?.restrictedAccess;

  return (
    <Routes>
      {profil ? (
        <>
          <Route path="/" element={<Dashboard />}>
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    profil?.isSuperAdmin === 0
                      ? "/companies"
                      : profil?.isSuperAdmin === 1
                        ? RestrictedAccess
                          ? "/subscription"
                          : "/company"
                        : `/staff`
                  }
                />
              }
            />
            {profil?.isSuperAdmin === 0 && (
              <>
                <Route path="companies/*" element={<CompaniesRoutes />} />
                {profil.userType === 0 && (
                  <Route path="users/*" element={<UsersRoutes />} />
                )}
              </>
            )}
            {selectCompany && (
              <>
                {profil.isSuperAdmin !== 2 && !RestrictedAccess && (
                  <>
                    <Route path="company/*" element={<CompanyRoutes />} />

                    <Route path="branches/*" element={<BranchRoutes />} />

                    <Route path="department/*" element={<DepartmentRoutes />} />

                    <Route path="staff/*" element={<PersonelRoutes />} />

                    <Route path="/map" element={<MapWrapper />} />

                    <Route path="/subscription" element={<Subscription />} /> {/* New Route */}

                    <Route path="/report" element={<Report />} /> {/* New Route */}

                    <Route path="/message" element={<Message />} /> {/* New Route */}
                  </>
                )}
                {profil.isSuperAdmin !== 2 && RestrictedAccess && (
                  <Route path="/subscription" element={<Subscription />} />
                )}
                {profil.isSuperAdmin === 2 && (
                  <Route index path={`staff`} element={<PersonelDetail />} />
                )}
                {profil.userType === 2 && (
                  <Route path="/message" element={<Message/>}/>
                )}
              </>
            )}
          </Route>
          <Route
            path="*"
            element={
              <Navigate
                to={
                  profil?.isSuperAdmin === 0
                    ? "/companies"
                    : profil?.isSuperAdmin === 1
                      ? RestrictedAccess
                        ? "/subscription"
                        : "/company"
                      : `/staff`
                }
              />
            }
          />
        </>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={ <ResetPassword/>} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
      <Route path="/register" element={<Register />} />
      <Route path="/agreement" element={<Agreement />} /> {/* New Route */}
      <Route path="/terms-of-use" element={<TermsOfUse />} /> {/* New Route */}
    </Routes>
  );
};

export default RoutesPage;
