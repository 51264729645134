import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { addSuperUserSchema } from "../../../helpers/FormSchemas/FormErrorMessage";
import { inits } from "../../../helpers/FormSchemas/FormSchemas";
import { postOrPutRequestHttps } from "../../../libs/AllGetRequest/HttpsHelper";

const AddSuperAdmin = ({ checkSuperAdmin }) => {
  const [t] = useTranslation("global");

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  const sumbitFunction = (e, setSubmitting, reset) => {
    delete e.confirm_password;

    postOrPutRequestHttps("createSuperAdmin", "POST", e)
      .then((res) => {
        reset();
        if (!toast.isActive("addSuccess")) {
          toast.success(t("super_admin.add_success"), {
            toastId: "addSuccess",
          });
        }
        checkSuperAdmin(true);
      })
      .catch((c) => {
        console.log("c", c);
        if (!toast.isActive("addError")) {
          toast.error(t("super_admin.add_error"), { toastId: "addError" });
        }
      });
    setSubmitting(false);
  };

  return (
    <div>
      <p className="font-semibold text-lg tracking-wider text-center text-[#858F91] capitalize mb-3">
        {t("crud.add_super_admin.title")}
      </p>
      <Formik
        validationSchema={addSuperUserSchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={inits.addSuperUser}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form
              className="flex flex-col h-full w-full justify-between"
              style={{ fontFamily: "Poppins" }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 xl:gap-x-30 gap-x-5">
                <div className="flex flex-col">
                  <label htmlFor="name_surname" className="formTitle required">
                    {t("crud.add_super_admin.name")}
                  </label>
                  <div>
                    <Field
                      name="name_surname"
                      className="formInput"
                      //   options={EncryptionOptions}
                      //   component={MultiSelectField}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="name_surname" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="email" className="formTitle required">
                    {t("crud.add_super_admin.email")}
                  </label>
                  <div>
                    <Field
                      name="email"
                      className="formInput"
                      //   options={EncryptionOptions}
                      //   component={MultiSelectField}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="password" className="formTitle required">
                    {t("general.password")}
                  </label>
                  <div>
                    <div className="relative">
                      <Field
                        type={showPassword.password ? "text" : "password"}
                        name="password"
                        className="formInput"
                      />
                      <span
                        className="absolute right-3 top-[45%] cursor-pointer"
                        onClick={() =>
                          setShowPassword((prev) => {
                            return { ...prev, password: !prev.password };
                          })
                        }
                      >
                        {showPassword.password ? (
                          <RiEyeLine />
                        ) : (
                          <RiEyeCloseLine />
                        )}
                      </span>
                    </div>
                    <div className="text-red-600">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="confirm_password"
                    className="formTitle required"
                  >
                    {t("crud.personnel.confirm_password")}
                  </label>
                  <div>
                    <div className="relative">
                      <Field
                        type={showPassword.confirm ? "text" : "password"}
                        name="confirm_password"
                        className="formInput"
                      />
                      <span
                        className="absolute right-3 top-[45%] cursor-pointer"
                        onClick={() =>
                          setShowPassword((prev) => {
                            return { ...prev, confirm: !prev.confirm };
                          })
                        }
                      >
                        {showPassword.confirm ? (
                          <RiEyeLine />
                        ) : (
                          <RiEyeCloseLine />
                        )}
                      </span>
                    </div>
                    <div className="text-red-600">
                      <ErrorMessage name="confirm_password" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-5">
                <button
                  type="submit"
                  className="bg-[#407BFF] font-semibold text-base xl:text-lg text-white xl:px-20 px-5 py-3 rounded-xl hover:bg-[#3db5e4] duration-300 relative flex"
                >
                  {t("general.save")}
                  {/* {loading ? (
                <AiOutlineLoading3Quarters className="ml-4 animate-spin absolute right-8 top-4" />
              ) : (
                ""
              )} */}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddSuperAdmin;
