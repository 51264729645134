import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCross1 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { addBrancSchema } from "../../../helpers/FormSchemas/FormErrorMessage";
import { inits } from "../../../helpers/FormSchemas/FormSchemas";
import { ChangeLatlong, SwitchField } from "../../../helpers/Function/Function";
import {
  getRequestHttps,
  postOrPutRequestHttps,
} from "../../../libs/AllGetRequest/HttpsHelper";
import BranchMap from "./BranchMap";
import CitySearchList from "./CitySearchList";

const AddBranch = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectCompany } = useNotificationContext();
  const citySearch = "https://nominatim.openstreetmap.org/search?";

  const [branchDetail, setBranchDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [cityPosition, setCityPosition] = useState();

  const getDetailData = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/branch-detail`, {
        company_id: selectCompany?.company_id,
        branch_id: serial,
      });

      const formValue = {
        branch_name: data?.name,
        address: data?.address,
        description: data?.description,
        lat: data?.lat_long?.lat,
        long: data?.lat_long?.long,
        radius: data?.lat_long?.radius,
      };
      if (data?.lat_long?.lat && data?.lat_long?.long) {
        setCityPosition([data?.lat_long?.lat, data?.lat_long?.long]);
      }
      setBranchDetail(formValue);
    } catch (error) {
      console.error("Error fetching branch details:", error);
    }
  };

  useEffect(() => {
    if (id) getDetailData(id);
  }, [id]);

  const submitFunction = async (e, setSubmitting, resetForm) => {
    const formData = {
      company_id: selectCompany?.company_id,
      name: e?.branch_name,
      description: e?.description,
      address: e?.address,
      lat_long: {
        lat: e?.lat,
        long: e?.long,
        radius: e?.radius,
      },
    };

    const updateForm = { ...formData, id: +id };

    const request = id
      ? postOrPutRequestHttps(`v1/branch`, "PUT", updateForm, {
          company_id: selectCompany?.company_id,
        })
      : postOrPutRequestHttps("v1/branch", "POST", formData, {
          company_id: selectCompany?.company_id,
        });

    toast.promise(request, {
      pending: t("crud.general." + (id ? "updating" : "adding")),
      success: {
        onClose: () => navigate("/branches"),
        autoClose: 1000,
        render: t("crud.general." + (id ? "updated" : "added")),
      },
      error: t("super_admin.add_error"),
    });

    setSubmitting(false);
  };

  const fetcher = async (search) => {
    setLoading(true);
    const params = {
      q: search,
      format: "json",
      addressdetails: 1,
      polygon_geojson: 0,
    };
    const queryString = new URLSearchParams(params).toString();

    try {
      const data = await axios(`${citySearch}${queryString}`);
      setCityList(data?.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-5 pl-4 mr-5 p-5 bg-[#ffffff] flex flex-col items-center form-page">
      <div className="custom-border-b justify-content-center w-100">
      <p className="tracking-wider mb-4 capitalize addTitle">
        {id
          ? t("branches.edit_branch") + " " + branchDetail?.branch_name
          : t("branches.add_branch")}
      </p>
      </div>

      <Formik
        validationSchema={addBrancSchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          submitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={{
          ...inits.addBranches,
          ...(branchDetail || {}),
        }}
      >
        {({ values, setFieldValue }) => {
          const isHomeBranch = values.branch_name === "Home";

          return (
            <Form
              className="flex flex-col h-full w-full justify-between mt-5"
              style={{ fontFamily: "Poppins" }}
            >
              <ChangeLatlong
                lat={values?.lat}
                long={values?.long}
                setCityPosition={setCityPosition}
              />
              <div className="row">
                <div className="row">
                <div className="col-4">
                    <label htmlFor="branch_name" className="titleForm required">
                      {t("crud.add_branch.branch_name")}
                    </label>
                    <div>
                      <Field name="branch_name" className="inputForm mt-2 px-2" />
                      <div className="text-red-600">
                        <ErrorMessage name="branch_name" />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <label htmlFor="description" className="titleForm">
                      {t("general.description")}
                    </label>
                    <div>
                      <Field name="description" className="inputForm mt-2 px-2" />
                      <div className="text-red-600">
                        <ErrorMessage name="description" />
                      </div>
                    </div>
                  </div>

                <div className="col-4">
                  <label htmlFor="address" className="titleForm">
                    {t("general.address")}
                  </label>
                  <div className="relative">
                    <Field
                      name="address"
                      className={`inputForm px-2 mt-2 ${isHomeBranch ? 'bg-[#c4c1c1]' : ''}`}
                      disabled={isHomeBranch}
                    />
                    <button
                      type="button"
                      disabled={values?.address?.length < 3}
                      onClick={() => {
                        setOpenList(true);
                        fetcher(values.address);
                      }}
                      className="absolute top-2 rounded-r-lg right-0 w-20 items-center flex justify-center h-30 bg-gray-300 disabled:bg-gray-400 cursor-pointer"
                    >
                      {t("general.search")}
                    </button>
                    <div className="text-red-600">
                      <ErrorMessage name="address" />
                    </div>
                  </div>
                  <>
                    <div
                      className={`w-full bg-[#f3f2f2] absolute z-10 rounded-xl shadow-md duration-300 overflow-y-auto ${
                        openList ? "h-40" : "h-0"
                      }`}
                    >
                      <div className="absolute right-3 top-3">
                        <button
                          className="duration-300 text-[#858F91] cursor-pointer hover:text-[#407BFF]"
                          type="button"
                          onClick={() => setOpenList(false)}
                        >
                          <RxCross1 />
                        </button>
                      </div>
                      <CitySearchList
                        setFieldValue={setFieldValue}
                        cityList={cityList}
                        setCityList={setCityList}
                        setCityPosition={setCityPosition}
                        setOpenList={setOpenList}
                        loading={loading}
                      />
                    </div>
                  </>
                </div>
                </div>
                <div className="row mt-5">
                  <div className="col-4">
                  <label htmlFor="radius" className={`titleForm ${isHomeBranch ? '' : 'required'}`}>
                      {t("general.lat")}
                    </label>
                    <div>
                      <Field
                        name="lat"
                        className={`inputForm mt-2 px-2 ${isHomeBranch ? 'bg-[#c4c1c1]' : ''}`}
                        type="number"
                        disabled={isHomeBranch}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="lat" />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                  <label htmlFor="radius" className={`titleForm ${isHomeBranch ? '' : 'required'}`}>
                      {t("general.long")}
                    </label>
                    <div>
                      <Field
                        name="long"
                        className={`inputForm mt-2 px-2 ${isHomeBranch ? 'bg-[#c4c1c1]' : ''}`}
                        type="number"
                        disabled={isHomeBranch}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="long" />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                  <label htmlFor="radius" className={`titleForm ${isHomeBranch ? '' : 'required'}`}>
                      {t("general.radius")}
                    </label>
                    <div>
                      <Field
                        name="radius"
                        className={`inputForm mt-2 px-2 ${isHomeBranch ? 'bg-[#c4c1c1]' : ''}`}
                        type="number"
                        disabled={isHomeBranch}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="radius" />
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div className="w-full h-96 mt-4">
                <BranchMap
                  radius={values?.radius}
                  setCityPosition={setCityPosition}
                  cityPosition={cityPosition}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="flex justify-end mt-5">
                <button
                  type="submit"
                  className="xl:px-20 px-5 py-3 duration-300 relative flex submitButton"
                >
                  {t("general.save")}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddBranch;
