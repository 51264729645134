const inits = {
  addCompanies: {
    company_logo: "",
    company_name: "",
    company_description: "",
    country: "",
    company_address: "",
    lat: "",
    long: "",
    radius: 250,

    is_active: true,
    tax_number: "",
    max_stuff_count: "",

    branch: false,
    branch_label: "Branches",
    department: false,
    department_label: "Department",

    manager: [],
    manager_name: "",
    manager_email: "",
    manager_password: "",
    manager_confirm_password: "",
    manager_phone: "",
    // managers: [
    //   {
    //     name: "",
    //     email: "",
    //     password: "",
    //     confirm_password: "",
    //     phone: "",
    //   },
    // ],
  },

  addBranches: {
    branch_name: "",
    address: "",
    // manager: [],
    description: "",
    lat: "",
    long: "",
    radius: 250,
  },

  addDepartment: {
    department_name: "",
    branch: "",
    description: "",
    address: "",
    location: "",
    lat: "",
    long: "",
    radius: 250,
    location: true,
    location_tracking: "",
  },

  addPersonnel: {
    personnel_name: "",
    email: "",
    position: "",
    branch: [],
    department: [],
    password: "",
    type_id: 2,
    phone: "",
    staff_id: "",
    confirm_password: "",
    hours: "",
    days: [],
    holiday: "",
    company_ids: [],
    phone_identity: null,
  },

  companySettings: {
    company_name: "",
    description: "",
    logo: "",
    country: "",
    city: "",
    lat: "",
    long: "",
    radius: "",
  },

  addSuperUser: {
    name_surname: "",
    email: "",
    password: "",
    confirm_password: "",
  },

  manuelLogin: {
    department_id: "",
    description: "",
    record_datetime: "",
    is_record_login: false,
    branch_id: "",
  },

  addLeaves: {
    label_en: "",
    label_tr: "",
    label_x: "",
  },

  work_calendar: {
    branch: "",
    department: "",
    start_date: "",
    end_date: "",
  },
};

export { inits };
