import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNotificationContext } from "../../../../context/NotifacitionContext";
import { useTranslation } from "react-i18next";
import { getRequestHttps } from "../../../../libs/AllGetRequest/HttpsHelper";
import BranchInfo from "./BranchInfo";
import LeaveInfo from "./LeaveInfo";
import CompanyBranchesInfo from "./CompanyBranchesInfo";
import CompanyDepartmentInfo from "./CompanyDepartmentInfo";
import CompanyDetail from "./CompanyDetail";
import CompanyMap from "./CompanyMap";
import { calculateDaysBetweenDates } from "../../../../helpers/Function/Function";

const CompanyInfo = () => {
  const [t] = useTranslation("global");
  const { selectCompany } = useNotificationContext();

  const [companyInfo, setCompanyInfo] = useState();
  const [branchInfo, setBranhInfo] = useState([]);
  const [companyLeaveList, setCompanyLeaveList] = useState([]);

  const [departmentInfo, setDepartmentInfo] = useState([]);
  const [departmentCard, setDepartmentCard] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const [selectBranches, setSelectBranches] = useState();
  const [selectData, setSelectData] = useState();

  const getCompanyInfo = async () => {
    let data;
    try {
      data = await getRequestHttps(`v1/company-dashboard`, {
        company_id: selectCompany?.company_id,
      });
      data?.company && setCompanyInfo(data.company);
    } catch (e) {
      console.log("e", e);
    }

    const userData = await getRequestHttps(
      "v1/user",
      {
        company_id: selectCompany?.company_id,
      },
      "result"
    );

    const totalUsers = userData?.filter(user => user.type_id === 2).length || 0;

    // Toplam yönetici sayısı (type_id'si 1 olanlar)
    const totalManagers = userData?.filter(user => user.type_id === 1).length || 0;

    setCompanyInfo((prevInfo) => ({
      ...prevInfo,
      totalUsers,
      totalManagers,
    }));

    const branchInfos = [
      {
        title: data?.company?.name,
        id: -1,
        lat: data?.company?.lat_long?.lat,
        long: data?.company?.lat_long?.long,
        radius: data?.company?.lat_long?.radius,
        main: true,
      },
    ];

    const branchInfo = data?.branch?.map((item, i) => {
      branchInfos.push({
        id: item?.id,
        title: item?.name,
        department: item?.departments_count,
        city: item?.city,
        total: item?.user_count,
        login: item?.logged_user_count,
        logout: item?.logout,
        lat: item?.lat_long?.lat,
        long: item?.lat_long?.long,
        radius: item?.lat_long?.radius,
        main: false,
      });
    });

    setSelectBranches(-1);

    const departmentInfo = data?.department?.map((item, i) => {
      return {
        id: item?.id,
        title: item?.name,
        department: item?.departments_count,
        city: item?.city,
        total: item?.user_count,
        login: item?.logged_user_count,
        logout: item?.logout,
        lat: item?.lat_long?.lat,
        long: item?.lat_long?.long,
        radius: item?.lat_long?.radius,
        address: item?.isSameAddress,
        isDepartment: true,
      };
    });

    setDepartmentCard(departmentInfo);

    Array.isArray(data?.branch) ? setBranhInfo(branchInfos) : setBranhInfo([]);
    Array.isArray(data?.department)
      ? setDepartmentInfo(data?.department)
      : setDepartmentInfo([]);
  };

  const getCompanyLeaveList = async () => {
    try {
      const data = await getRequestHttps(`v1/work-break`, {
        company_id: selectCompany?.company_id,
        start_date: new Date(), // Geçerli tarih veya istediğiniz tarih
        limit: 100,
      });
  
      const dataArr = data?.map((item, i) => {
        return {
          ids: i,
          start_date: item?.start_date ? new Date(item?.start_date) : null,
          end_date: item?.end_date
            ? item?.end_date === item?.start_date
              ? new Date(item?.end_date)
              : moment(item?.end_date).subtract(1, "day")
            : null,
          day: calculateDaysBetweenDates(item?.start_date, item?.end_date),
          status: item?.status,
          description: item?.description,
          id: item?.id,
          label_en: item?.label_en,
          label_tr: item?.label_tr,
          label_x: item?.label_x,
          break_label_id: item?.break_label_id,
        };
      });
  
      setCompanyLeaveList(dataArr);
  
    } catch (error) {
      console.error("Error fetching company leave list:", error);
    }
  };

  useEffect(() => {
    const data = departmentInfo.filter(
      (item) => item?.branch_id === selectBranches && item
    );
    setDepartmentData(data);

    const selectBranch = selectBranches
      ? branchInfo?.filter((e) => e.id === selectBranches)
      : branchInfo;

    setSelectData(selectBranch[0]);
  }, [selectBranches, departmentInfo?.length]);

  useEffect(() => {
    getCompanyInfo();
  }, [selectCompany?.company_id]);

  return (
    <div
    style={{ gridTemplateRows: "1fr 1fr" }}
      className="grid grid-cols-1 grid-rows-2 md:grid-cols-2 xl:grid-cols-8 gap-x-5 pl-4 pt-3 pr-5 h-full z-0"
    >
      <div className="h-[300px] min-h-[200px] bg-white relative border-[1px] rounded-xl col-span-1 md:col-span-2 xl:col-span-4">
        <CompanyMap
          data={branchInfo}
          companyInfo={companyInfo}
          selectBranches={selectBranches}
          departmentInfo={departmentCard}
        />
      </div>
      <div className="h-[300px] min-h-[200px] relative rounded-xl col-span-1 md:col-span-2 xl:col-span-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <div className="flex flex-col items-left">
            <h2 className="mb-2 totalTexts">{t("company.total_users")}</h2>
            <div className="relative h-[80px] w-full rounded-xl companyInfo flex items-center justify-center mt-2">
              <div className="p-4 totalTexts text-center">
                <p style={{ color: '#f39c12' }}>{t("general.personnel")}: {companyInfo?.totalUsers}</p>
                <p style={{ color: 'blue' }}>{t("branches.manager")}: {companyInfo?.totalManagers}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-left">
            <h2 className="mb-2 totalTexts">{t("company.total_branches")}</h2>
            <div className="relative h-[80px] w-full rounded-xl companyInfo flex items-center justify-center mt-2">
              <div className="p-4 totalTexts text-center">
                <p style={{ color: 'limegreen' }}>{branchInfo?.length}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-left">
            <h2 className="mb-2 totalTexts">{t("company.total_department")}</h2>
            <div className="relative h-[80px] w-full rounded-xl companyInfo flex items-center justify-center mt-2">
              <div className="p-4 totalTexts text-center">
                <p style={{ color: 'blue' }}>{departmentInfo?.length}</p>
              </div>
            </div>
          </div>

        </div>
        <div className="col-span-1 md:col-span-2 xl:col-span-4 flex gap-3 mt-16">
          <h2 className="totalTexts">{t("company.company_info")}</h2>
        </div>
        <hr className="divider mt-2"></hr>
        <div className="col-span-1 md:col-span-2 xl:col-span-4 flex gap-3">
          <div className="relative h-[80px] w-full rounded-xl flex items-center justify-start">
            <div className="totalTexts">
              <p>{t("general.address")}</p>
              <p className="infoTexts">{companyInfo?.address}</p>
            </div>
          </div>
          <div className="relative h-[80px] w-full rounded-xl flex items-center justify-start">
            <div className="totalTexts">
              <p>{t("general.description")}</p>
              <p className="infoTexts">{companyInfo?.description}</p>
            </div>
          </div>
          <div className="relative h-[80px] w-full rounded-xl flex items-center justify-center"></div>
        </div>
      </div>
      {selectCompany?.branch && (
        <div className=" min-h-[200px] h-[300px] rounded-xl overflow-y-auto col-span-1 md:col-span-2 xl:col-span-4 mt-2">
          <CompanyDetail data={companyInfo} />
        </div>
      )}
      {selectCompany && (
        <div
          className={` min-h-[200px] h-[300px] rounded-xl overflow-y-auto col-span-1 md:col-span-2 xl:col-span-4 mt-2
            }`}
        >
          <LeaveInfo data={selectCompany} />
        </div>
      )}
      {/* {selectCompany?.department && (
        <div className=" min-h-[200px] h-[300px] rounded-xl overflow-y-auto col-span-1 md:col-span-2 xl:col-span-2 mt-2">
          <CompanyDepartmentInfo data={departmentData} />
        </div>
      )} */}
    </div>
  );
};

export default CompanyInfo;
