import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DepartmentHome from "../components/Modules/Department/DepartmentHome";
import { useNotificationContext } from "../context/NotifacitionContext";
import {
  deleteRequestHttps,
  getRequestHttps,
} from "../libs/AllGetRequest/HttpsHelper";

const Department = () => {
  const [t, i18n] = useTranslation("global");
  const { selectCompany, setTitle } = useNotificationContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    // setTitle([selectCompany?.department]);
    setTitle([t("sidebar.department")]);
  }, [i18n.language]);

  const getLoginCount = (targetIds, list) => {
    const data = list.find((item) => item?.department_id == targetIds && item);
    return data ? data?.total_logged_user : 0;
  };

  const getDepartment = async () => {
    setLoading(true);
    let data;

    try {
      data = await getRequestHttps("v1/department", {
        company_id: selectCompany?.company_id,
      });

      const logoutCount = await getRequestHttps(
        "v1/departments-user-logged-status",
        {
          company_id: selectCompany?.company_id,
        }
      );

      const companiesList = data?.map((e) => {
        return {
          id: e?.id,
          title: e?.name,
          total: e?.user_count,
          login:
            logoutCount?.length > 0
              ? getLoginCount(e?.id, logoutCount) || 0
              : 0,
          // logout: Math.floor(Math.random() * (30 - 10)) + 10,
          description: e?.description || "-",
          branch: e?.branch_name || "-",
        };
      });
      setData(companiesList);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDepartment();
  }, [selectCompany?.company_id]);

  const handleDelete = (id) => {
    toast.promise(
      deleteRequestHttps(
        `v1/department`,
        {},
        {
          company_id: selectCompany?.company_id,
          id: id,
        }
      ),
      {
        pending: t("crud.general.deleting"),
        success: {
          onClose: () => {
            getDepartment();
          },
          autoClose: 1000,
          render: t("crud.general.deleted"),
        },
        error: t("super_admin.add_error"),
      }
    );
  };

  return (
    <div className="mt-5 pl-4 pr-5">
      <div className="custom-border-b"></div>
      <DepartmentHome
        data={data}
        loading={loading}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default Department;
