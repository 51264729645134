import React from "react";

const AddCompanyIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill="#cfd8dc"
          d="M5.5 7.25a.75.75 0 0 0 0-1.5h-2v-1.5h2a.75.75 0 0 0 0-1.5H2.75A.75.75 0 0 0 2 3.5v12.25a.75.75 0 0 0 1.5 0v-2.5h2a.75.75 0 0 0 0-1.5h-2v-1.5h2a.75.75 0 0 0 0-1.5h-2v-1.5z"
          opacity="1"
          data-original="#cfd8dc"
        ></path>
        <path
          fill="#607d8b"
          d="M10.5 0h-5a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h5a.75.75 0 0 0 .75-.75v-15A.75.75 0 0 0 10.5 0z"
          opacity="1"
          data-original="#607d8b"
        ></path>
        <g fill="#90a4ae">
          <path
            d="M8 12.5c0 .414.336.75.75.75h2.5v-1.5h-2.5a.75.75 0 0 0-.75.75zM8 9.5c0 .414.336.75.75.75h2.5v-1.5h-2.5A.75.75 0 0 0 8 9.5zM8 6.5c0 .414.336.75.75.75h2.5v-1.5h-2.5A.75.75 0 0 0 8 6.5zM8 3.5c0 .414.336.75.75.75h2.5v-1.5h-2.5A.75.75 0 0 0 8 3.5zM19.032 15.222A.747.747 0 0 0 18.5 15H.75a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h17.718a.75.75 0 0 0 .75-.745l.032-4.5a.75.75 0 0 0-.218-.533z"
            fill="#90a4ae"
            opacity="1"
            data-original="#90a4ae"
          ></path>
        </g>
        <path
          fill="#cfd8dc"
          d="M5.5 7.25a.75.75 0 0 0 0-1.5h-2v-1.5h2a.75.75 0 0 0 0-1.5H2.75A.75.75 0 0 0 2 3.5v12.25a.75.75 0 0 0 1.5 0v-2.5h2a.75.75 0 0 0 0-1.5h-2v-1.5h2a.75.75 0 0 0 0-1.5h-2v-1.5z"
          opacity="1"
          data-original="#cfd8dc"
        ></path>
        <path
          fill="#607d8b"
          d="M10.5 0h-5a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h5a.75.75 0 0 0 .75-.75v-15A.75.75 0 0 0 10.5 0z"
          opacity="1"
          data-original="#607d8b"
        ></path>
        <path
          fill="#90a4ae"
          d="M8 12.5c0 .414.336.75.75.75h2.5v-1.5h-2.5a.75.75 0 0 0-.75.75zM8 9.5c0 .414.336.75.75.75h2.5v-1.5h-2.5A.75.75 0 0 0 8 9.5zM8 6.5c0 .414.336.75.75.75h2.5v-1.5h-2.5A.75.75 0 0 0 8 6.5zM8 3.5c0 .414.336.75.75.75h2.5v-1.5h-2.5A.75.75 0 0 0 8 3.5zM19.032 15.222A.747.747 0 0 0 18.5 15H.75a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h17.718a.75.75 0 0 0 .75-.745l.032-4.5a.75.75 0 0 0-.218-.533z"
          opacity="1"
          data-original="#90a4ae"
        ></path>
        <path
          fill="#b4bcc0"
          d="M4.75 2.75h-2A.75.75 0 0 0 2 3.5V15h1.5v-1.75h1.25v-1.5H3.5v-1.5h1.25v-1.5H3.5v-1.5h1.25v-1.5H3.5v-1.5h1.25z"
          opacity="1"
          data-original="#b4bcc0"
        ></path>
        <path
          fill="#546d79"
          d="M8 0H5.5a.75.75 0 0 0-.75.75V15H8z"
          opacity="1"
          data-original="#546d79"
        ></path>
        <path
          fill="#7d8f97"
          d="M8 15H.75a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75H8z"
          opacity="1"
          data-original="#7d8f97"
        ></path>
        <path
          fill="#00bcd4"
          d="M17.5 11c-3.584 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5 6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5z"
          opacity="1"
          data-original="#00bcd4"
        ></path>
        <path
          fill="#ffffff"
          d="M20 18.5h-1.5V20a1 1 0 0 1-2 0v-1.5H15a1 1 0 0 1 0-2h1.5V15a1 1 0 0 1 2 0v1.5H20a1 1 0 0 1 0 2z"
          opacity="1"
          data-original="#ffffff"
        ></path>
        <path
          fill="#00a4b9"
          d="M17.5 11c-3.584 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5v-3a1 1 0 0 1-1-1v-1.5H15a1 1 0 0 1 0-2h1.5V15a1 1 0 0 1 1-1z"
          opacity="1"
          data-original="#00a4b9"
        ></path>
        <path
          fill="#dedede"
          d="M17.5 14a1 1 0 0 0-1 1v1.5H15a1 1 0 0 0 0 2h1.5V20a1 1 0 0 0 1 1z"
          opacity="1"
          data-original="#dedede"
        ></path>
      </g>
    </svg>
  );
};

export default AddCompanyIcon;
