import moment from "moment";
import { useMemo } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import lang from "../../translations/calendar/lang.json";

export const Calendar = (props) => {
  const localizer = momentLocalizer(moment);
  const { t, i18n } = useTranslation("global");

  const { messages } = useMemo(
    () => ({
      locale: moment.locale(i18n.language === "en" ? "en-in" : i18n.language),
      messages: lang[i18n.language],
    }),
    [i18n.language]
  );

  return <BigCalendar {...props} messages={messages} localizer={localizer} />;
};
