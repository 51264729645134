import { Tooltip } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import DownloadIcon from "../../components/icons/General/DownloadIcon";
import { alphabet } from "../HelpersData/AssetHelper";

const ExcelExport = ({
  color = "",
  value,
  tableOneColumn,
  name,
  title = "",
  colomnWidth,
  buggyColumnWidth = false,
}) => {
  const [t] = useTranslation("global");
  const prepareExcel = (oneCard, activePlan) => {
    if (oneCard.length > 0) {
      const date = new Date();
      const formatDate = moment(date).format("DD-MM HH-mm");
      const fullFormatDate = moment(date).format("DD-MM-YYYY HH:mm:ss");

      const mainTittle = `${name} ${fullFormatDate} ${t("general.report")} `;
      const excelFileName = `${name}-${formatDate}`;
      handleExport(oneCard, activePlan, mainTittle, excelFileName).then(
        (url) => {
          console.log(url);
          const downloadAnchorNode = document.createElement("a");
          downloadAnchorNode.setAttribute("href", url);
          downloadAnchorNode.setAttribute("download", `${excelFileName}.xlsx`);
          downloadAnchorNode.click();
          downloadAnchorNode.remove();
        }
      );
    }
  };

  const handleFetcher = async () => {
    prepareExcel(value);
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = (oneCard, activePlan, mainTittle, excelFileName) => {
    const title = [{ A: mainTittle }, {}];
    let table1 = tableOneColumn;

    let table2 = [
      {
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        F: "",
        G: "",
        H: "",
        I: "",
        J: "",
        K: "",
      },
    ];

    oneCard &&
      oneCard.forEach((row) => {
        table1.push({
          A: row.lastSeen || null,
          B: row.field1 || null,
          C: row.field2 || null,
          D: row.field3 || null,
          E: row.field4 || null,
          F: row.field5 || null,
          G: row.field6 || null,
          H: row.field7 || null,
          I: row.field8 || null,
          J: row.field9 || null,
          K: row.field10 || null,
          L: row.field11 || null,
          M: row.field12 || null,
          N: row.field13 || null,
          O: row.field14 || null,
          P: row.field15 || null,
          Q: row.field16 || null,
          R: row.field17 || null,
          S: row.field18 || null,
          T: row.field19 || null,
          U: row.field20 || null,
        });
      });

    table2.push({
      // E: activePlan.plan_name,
      // F: activePlan.period,
      // G: activePlan.run_time,
      // H: activePlan.flow_ms,
      // I: activePlan.dim,
    });

    table1 = [{ A: "" }]
      // .concat(table2)
      // .concat([''])
      .concat([{ A: "" }])
      .concat(table1);

    const finalData = [...title, ...table1];
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, `${excelFileName}`);

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "" || data["A"] === "" ? headerIndexes.push(index) : null
    );
    const whichLetter = alphabet[Object.keys(value[0]).length - 1];
    const totalRecords = oneCard.length;
    const dataInfo = {
      titleCell: "A2",
      titleRange: `A1:${whichLetter}2`,
      tbodyRange: `A3:${whichLetter}${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `E${headerIndexes[0] + 1}:I${headerIndexes[0] + 1}`
          : null,
      theadRange1:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 2}:${whichLetter}${headerIndexes[1] + 2}`
          : null,
      tFirstColumnRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:A${totalRecords + headerIndexes[0] + 1}`
          : null,
      tLastColumnRange:
        headerIndexes?.length >= 1
          ? `G${headerIndexes[0] + 1}:G${totalRecords + headerIndexes[0] + 1}`
          : null,

      tFirstColumnRange1:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[1] + 1}:A${totalRecords + headerIndexes[1] + 1}`
          : null,
      tLastColumnRange1:
        headerIndexes?.length >= 1
          ? `H${headerIndexes[0] + 1}:H${totalRecords + headerIndexes[1] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("A").width(35);
        sheet.column("B").width(35);
        sheet.column("C").width(35);
        sheet.column("D").width(35);
        sheet.column("E").width(35);
        sheet.column("F").width(35);
        sheet.column("G").width(35);
        sheet.column("H").width(35);
        // buggyColumnWidth && sheet.column("B").width(50);
        // buggyColumnWidth && sheet.column("C").width(50);
        // colomnWidth && sheet.column("D").width(50);
        // colomnWidth && sheet.column("E").width(50);
        // colomnWidth && !buggyColumnWidth && sheet.column("F").width(50);
        // buggyColumnWidth && sheet.column("F").width(50);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
          });
        }

        // sheet.range(dataInfo.theadRange).style({
        // 	fill: '808080',
        // 	bold: true,
        // 	horizontalAlignment: 'center',
        // 	fontColor: '000',
        // })

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "FFFD04",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "000",
          });
        }

        if (dataInfo.tFirstColumnRange) {
          sheet.range(dataInfo.tFirstColumnRange).style({
            bold: true,
          });
        }

        if (dataInfo.tLastColumnRange) {
          sheet.range(dataInfo.tLastColumnRange).style({
            bold: true,
          });
        }

        if (dataInfo.tFirstColumnRange1) {
          sheet.range(dataInfo.tFirstColumnRange1).style({
            bold: true,
          });
        }

        if (dataInfo.tLastColumnRange1) {
          sheet.range(dataInfo.tLastColumnRange1).style({
            bold: true,
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <div
      onClick={() => {
        if (value?.length > 0) {
          handleFetcher();
        } else {
          toast.error(t("general.no_record"), { autoClose: 2000 });
        }
      }}
    >
      {title ? (
        title
      ) : (
        <Tooltip title={t("general.download")} placement="topRight">
          <div className="d-flex excel-text align-items-center">
            <DownloadIcon color={color ? color : "#a0a4b6"} width={34} height={34}/>
            <p className="ms-2 mb-1">{t("general.excel_report")}</p>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ExcelExport;
