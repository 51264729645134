import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { Circle, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Link } from "react-router-dom";
import { CompanyBranchesIcon, CompanyMapIcon } from "../../../icons/General";
import CompanyDepartmentIcon from "../../../icons/General/CompanyDepartmentIcon";

const CompanyMap = ({ data, companyInfo, selectBranches, departmentInfo }) => {
  const [markerData, setMarkerData] = useState([]);

  const mapRef = useRef();
  useEffect(() => {
    const dataArr = data.concat(departmentInfo);
    setMarkerData(dataArr);
    const whichBranches = data.filter((item) =>
      selectBranches > -2
        ? selectBranches === item.id && item
        : item.main && item
    )[0];

    whichBranches &&
      mapRef.current?.flyTo(
        [whichBranches?.lat, whichBranches?.long],
        mapRef?.current?.getZoom(),
        {
          duration: 1.5,
          animate: true,
        }
      );
  }, [data, selectBranches]);

  const selectIcon = (no, main, branches = false) => {
    const mapIcon = L.divIcon({
      className: "lastPolygonIdMarker",
      html: renderToString(
        <div className=" relative">
          {no > 0 && (
            <div className="absolute -top-4 -right-2 bg-[#407BFF] p-1 rounded-full w-7 h-7 flex items-center justify-center">
              <p className="text-white font-medium">{no}</p>
            </div>
          )}
          {!branches ? (
            main ? (
              <CompanyMapIcon width={40} />
            ) : (
              <CompanyBranchesIcon width={40} />
            )
          ) : (
            <CompanyDepartmentIcon width={40} />
          )}
        </div>
      ),
    });
    return mapIcon;
  };

  return (
    <div className="w-full mobile:min-w-[95vw] laptop:min-w-0 h-full rounded-xl z-0 relative">
      <Link
        state={{ company: true }}
        to="/map"
        className="absolute p-[2px] cursor-pointer shadow-md top-0 left-0 z-[500] hover:bg-[#53626F] hover:scale-110 duration-300 rounded-lg text-white bg-[#858F91]"
      >
        <MdOutlineZoomOutMap size={22} />
      </Link>
      <MapContainer
        ref={mapRef}
        className="rounded-xl"
        center={[53.78213424391142, 20.440100480796453]}
        zoom={14}
        minZoom={2}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
      >
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        {data?.map((item, index) => {
          return (
            <div key={index}>
              {item?.radius > 0 &&
                item?.lat &&
                item?.long &&
                !item?.address && (
                  <Circle
                    center={[item?.lat, item?.long]}
                    pathOptions={{ fillColor: "red" }}
                    radius={item?.radius}
                  />
                )}
            </div>
          );
        })}
        {/* {companyInfo?.lat_long?.radius > 0 &&
          companyInfo?.lat_long?.lat &&
          companyInfo?.lat_long?.long && (
            <Circle
              center={[companyInfo?.lat_long?.lat, companyInfo?.lat_long?.long]}
              pathOptions={{ fillColor: "red" }}
              radius={companyInfo?.lat_long?.radius}
            />
          )} */}
        <MarkerClusterGroup
          maxClusterRadius={20}
          disableClusteringAtZoom={15}
          chunkedLoading
        >
          {/* {companyInfo?.lat_long?.lat && companyInfo?.lat_long?.long && (
            <Marker
              icon={selectIcon(companyInfo?.user_count, true)}
              position={[
                companyInfo?.lat_long?.lat,
                companyInfo?.lat_long?.long,
              ]}
            >
              {" "}
              <Popup> {companyInfo?.name} </Popup>{" "}
            </Marker>
          )} */}
          {data?.map((item, index) => {
            return (
              <div key={index}>
                {item?.lat && item?.long && !item?.address && (
                  <Marker
                    icon={selectIcon(
                      item?.total,
                      item.main,
                      item?.isDepartment
                    )}
                    position={[item?.lat, item?.long]}
                  >
                    {" "}
                    <Popup> {item?.title} </Popup>{" "}
                  </Marker>
                )}
              </div>
            );
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default CompanyMap;
