import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "../../context/UserContext";
import Companies from "../../pages/Companies/Companies";
import AddCompanies from "../Modules/Companies/AddCompanies";
import { LeaveCrud } from "../Modules/Companies/LeaveCrud";

const CompaniesRoutes = () => {
  const { profil } = useAuthContext();
  return (
    <Routes>
      <Route index path="/" element={<Companies />} />
      <Route index path="/leave" element={<LeaveCrud />} />
      {profil.userType === 0 && (
        <>
          <Route path="/add_company" element={<AddCompanies />} />
          <Route path="/edit_company/:id" element={<AddCompanies />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default CompaniesRoutes;
