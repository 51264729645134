import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import environment from '../../environment/environment';
import ModalDismissResultModel from '../../../src/components/Modules/modal-dismiss-result-model'


const CheckoutComponent = ({ verificationCode, showModal, handleClose }) => {
  const [paymentId, setPaymentId] = useState("");
  const [modalDismissResult, setModalDismissResult] = useState(new ModalDismissResultModel());

  useEffect(() => {
    if (verificationCode !== "") {
      setPaymentId(verificationCode);      
    }
  }, [verificationCode]);

  useEffect(() => {
    if (paymentId) {
      const interval = setInterval(() => {
        if (window.Dibs && window.Dibs.Checkout) {
          clearInterval(interval);

          const checkoutOptions = {
            checkoutKey: environment.checkoutKey,
            paymentId: paymentId,
            containerId: "checkout-container-div",
          };

          const checkout = new window.Dibs.Checkout(checkoutOptions);

          checkout.on('payment-completed', (response) => {
            const result = new ModalDismissResultModel();
            result.setProps({ isSucceeded: true, message: 'Register Success', actionData: response });

            setModalDismissResult(result);

            setTimeout(() => {
              handleClose(result);
            }, 3000);
          });
        }
      }, 100);
      // Temizlik fonksiyonu ile interval'i temizle
      return () => clearInterval(interval);
    }
  }, [paymentId, handleClose]);

  return (
    <Modal show={showModal} onHide={() => handleClose(modalDismissResult)}>
      <Modal.Header closeButton>
        <Modal.Title>Betalingssystem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="checkout-container-div"></div>
      </Modal.Body>
    </Modal>
  );
};

export default CheckoutComponent;