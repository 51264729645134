import React from "react";

const DepartmentIcon = ({ width, height }) => {
  return (
<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_112_125)">
<path d="M20.5714 1.71423H3.42852C2.48175 1.71423 1.71423 2.48175 1.71423 3.42852V20.5714C1.71423 21.5182 2.48175 22.2857 3.42852 22.2857H20.5714C21.5182 22.2857 22.2857 21.5182 22.2857 20.5714V3.42852C22.2857 2.48175 21.5182 1.71423 20.5714 1.71423Z" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.71423 7.71423H22.2857" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 7.71423V22.2857" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_112_125">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
  );
};

export default DepartmentIcon;
