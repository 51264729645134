import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { useNotificationContext } from "../../context/NotifacitionContext";
import Company from "../../pages/Company";

const CompanyRoutes = () => {
  const { setTitle } = useNotificationContext();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    setTitle([t("sidebar.dashboard")]);
  }, [i18n.language]);

  return (
    <Routes>
      <Route index path="/" element={<Company />} />
    </Routes>
  );
};

export default CompanyRoutes;
