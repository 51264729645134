import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsPerson } from "react-icons/bs";
import { TfiKey } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddSuperAdmin from "../components/Modules/Login/AddSuperAdmin";
import ForgotPassword from "../components/Modules/Login/ForgotPassword";
import { useNotificationContext } from "../context/NotifacitionContext";
import { useAuthContext } from "../context/UserContext";
import { login } from "../helpers/LoginApi/reguest";
import { getRequestHttps } from "../libs/AllGetRequest/HttpsHelper";

const Login = () => {
  const [t] = useTranslation("global");
  const { profil, setProfile } = useAuthContext();
  const { setSelectCompany, setIsEditMode } = useNotificationContext();

  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [openModal2, setOpenModal2] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (profil) navigate("/");
  }, []);

  const checkSuperAdmin = (addError) => {
    getRequestHttps("checkSuperAdmin")
      .then((res) => {
        if (res?.status === 204) {
          if (!toast.isActive("checkSuper")) {
            toast.warning(
              addError
                ? t("super_admin.add_later_check")
                : t("super_admin.check_error"),
              {
                toastId: "checkSuper",
              }
            );
            setOpenModal(true);
          }
        } else {
          setOpenModal(false);
        }
      })
      .catch((c) => {
        console.log("c", c);
      });
  };

  useEffect(() => {
    checkSuperAdmin();
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        console.log("err", err);
        if (err?.response?.status === 403) {
          toast.warning(t("response_status.403"), {
            autoClose: 2000,
          });
        }
      }
    );
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = await login(
        values.email?.toLowerCase()?.trim(),
        values.password?.trim()
      );
      const auth = data?.data;
      const profile = {
        name: "",
        email: "",
        userId: auth.user_id,
        userType: auth.userType,
        isSuperAdmin: auth?.companies?.length > 1 ? 0 : auth.userType,
        image: auth?.firmLogo,
        company_name: auth?.firmName,
        branch: auth?.branch_label,
        department: auth?.department_label,
        restrictedAccess: auth?.restrictedAccess,
      };
      profile.isSuperAdmin === 0 && setIsEditMode(false);
      setProfile(profile);
      profile.isSuperAdmin === 0
        ? setSelectCompany()
        : setSelectCompany({ ...profile, company_id: auth?.firmID });

      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;

      localStorage.setItem("auth_token", auth.token);

      localStorage.setItem(
        "user",
        JSON.stringify({ ...profile, company_id: auth?.firmID, restrictedAccess: auth.restrictedAccess })
      );

      navigate(
        profile?.isSuperAdmin === 0
          ? "/companies"
          : profile?.isSuperAdmin === 1
            ? auth.restrictedAccess
              ? "/subscription"
              : "/company"
            : `/staff`
      );

    } catch (error) {
      console.log("error", error);
      setProfile(undefined);
      setStatus(t("general.login_error"));
      setTimeout(() => {
        setStatus("");
      }, 2000);
      setLoading(false);
    }
  };

  const handleSignUp = () => {
    navigate("/register");
  };

  const onFinishTwo = async (values) => {
    console.log("values", values);
  };

  return (
    <div className="container-fluid" style={{ height: "100vh", overflow: "hidden" }}>
      <div className="row">
        <div className="col-5" style={{ backgroundColor: "#F0F0F0" }}>
          <div className="container login-form-container">
            <Form
              className="flex flex-col items-center"
              layout="vertical"
              name="basic"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: false,
              }}
              onFinish={onFinish}
            >
              <div className="row text-start w-100">
                <p className="login-text-1">{t("general.login_msg_1")}</p>
                <p className="login-text-2 mt-2">{t("general.login_msg_2")}</p>
              </div>
              <Form.Item
                name="email"
                label={<div className="label-text mb-3">{t("general.email_address")}</div>}
                rules={[{ required: true, message: t("general.field_error") }]}
                className="mt-5 w-100"
              >
                <Input
                  bordered={true}
                  style={{
                    background: "transparent",
                    height: "40px",
                    border: "1px solid #858F91",
                    borderRadius: "10px"
                  }}
                  className="text-sm sm:text-xl"
                />
              </Form.Item>

              <Form.Item
                label={<div className="label-text mb-3">{t("general.password")}</div>}
                name="password"
                rules={[{ required: true, message: t("general.field_error") }]}
                className="w-100 mt-2"
                style={{ background: "transparent" }}
              >
                <Input.Password
                  style={{
                    background: "transparent",
                    height: "40px",
                    border: "1px solid #858F91",
                    borderRadius: "10px"
                  }}
                  type="password"
                  className="text-sm sm:text-xl"
                  bordered={true}
                />
              </Form.Item>
              <div className="flex w-full justify-content-between">
                <div className="">
                {status && (
                <div className="error-message">
                  {status}
                </div>
              )}
                </div>
                <div
                  className="forgot-pass"
                >
                  {t("general.forgot_password")} <span className="reset-pass cursor-pointer" onClick={() => setOpenModal2(true)}>{t("general.reset")}</span>
                </div>
              </div>

              <Form.Item style={{ width: "100%", marginTop: "5rem" }}>
                <Button
                  // disabled={openModal}
                  size="large"
                  className="login-button flex flex-col items-center justify-center"
                  htmlType="submit"
                >
                  {!loading && (
                    <span className="indicator-label">
                      {" "}
                      {t("general.sign_in")}{" "}
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {t("general.login_loading")}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </Button>
              </Form.Item>
              <Form.Item style={{ width: "100%" }}>
                <div
                  size="large"
                  className="flex flex-col items-center justify-center"
                  style={{
                    padding: "24px 10px",
                    width: "100%",
                  }}
                >
                  <span className="forgot-pass">
                    {" "}
                    {t("general.have_account")}{" "}
                    <span className="register-label cursor-pointer" onClick={handleSignUp}>{t("general.sign_up")}</span>
                  </span>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-7 login-info-container">
          <div className="row text-start w-100">
            <p className="login-text-1">{t("general.welcome_msg_1")}</p>
            <p className="login-text-2 mt-2">{t("general.welcome_msg_2")}</p>
          </div>
          <div className="login-info-image">
          </div>
        </div>
      </div>
      <Modal
        style={{
          zIndex: 100,
        }}
        title=""
        cancelButtonProps={{ style: { display: "none" } }}
        centered
        closable={false}
        maskClosable={false}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        width={800}
      >
        <AddSuperAdmin checkSuperAdmin={checkSuperAdmin} />
      </Modal>
      <Modal
        style={{
          zIndex: 100,
        }}
        title=""
        centered
        open={openModal2}
        onCancel={() => setOpenModal2(false)}
        footer={null}
        width={600}
      >
        <ForgotPassword setOpenModal2={setOpenModal2} />
      </Modal>
    </div>
  );
};

export default Login;
