import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data }) => {
    const [t] = useTranslation("global");

    const createGradient = (ctx, area) => {
      const gradient1 = ctx.createLinearGradient(0, 0, 0, area.bottom);
      gradient1.addColorStop(0, 'rgba(137, 145, 237, 0.4)');
      gradient1.addColorStop(0.5, 'rgba(43, 51, 137, 1)');
      gradient1.addColorStop(0, 'rgba(137, 145, 237, 0.4)');
    
      const gradient2 = ctx.createLinearGradient(0, 0, 0, area.bottom);
      gradient2.addColorStop(0, 'rgba(255, 174, 128, 0.4)');
      gradient2.addColorStop(0.5, 'rgba(248, 141, 78, 1)');
      gradient2.addColorStop(0, 'rgba(255, 174, 128, 0.4)');
    
      return [gradient1, gradient2];
    };
    
    const chartData = {
      labels: [t("general.personnel"), t("general.login")],
      datasets: [
        {
          data: [data.total, data.login],
          backgroundColor: function(context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
    
            if (!chartArea) {
              return null;
            }
            const gradients = createGradient(ctx, chartArea);
            return gradients;
          },
          hoverBackgroundColor: ["rgba(150, 155, 210, 0.5)", "rgba(247, 184, 149, 0.5)"],
          borderColor: "transparent", 
          borderWidth: 0
        },
      ],
    };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: 14,
            family: "'Poppins', sans-serif",
            weight: 500,
          },
          color: "#858F91",
          padding: 20,
          boxWidth: 30,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`; // Tooltip içeriği
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return <Doughnut data={chartData} />;
};

export default DoughnutChart;