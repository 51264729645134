import React from "react";

const SubscriptionIcon = ({ width, height }) => {
    return (
<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_112_116)">
<path d="M14.5529 9.33346C14.4651 9.08504 14.3296 8.85915 14.1575 8.66679C13.7913 8.25763 13.2591 8.00012 12.6667 8.00012H11.1186C10.1327 8.00012 9.33337 8.79941 9.33337 9.7854C9.33337 10.6244 9.91755 11.3501 10.7371 11.5294L13.0941 12.045C14.0123 12.2459 14.6667 13.0595 14.6667 13.9994C14.6667 15.104 13.7713 16.0002 12.6667 16.0002H11.3334C10.4626 16.0002 9.72173 15.4436 9.44717 14.6668" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 8.00001V6" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 18V16" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.1429 12C23.1429 18.1541 18.1541 23.1429 12.0001 23.1429C7.36471 23.1429 3.39043 20.3126 1.71118 16.2857" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.857178 12C0.857178 5.84601 5.84601 0.857178 12 0.857178C16.6354 0.857178 20.6097 3.68755 22.2888 7.71432" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.1429 4.28577V7.71434H19.7144" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.857178 19.7143V16.2858H4.28575" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_112_116">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
    );
};

export default SubscriptionIcon;