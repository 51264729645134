import moment from "moment";
import React, { useCallback, useState } from "react";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import CustomCalendar from "../../../Calendar/CustomCalendar";
import EVENTS from "./events";

const PersonelCalendar = () => {
  const [events, setEvents] = useState(EVENTS);

  const onChangeEventTime = useCallback((start, end, appointmentId) => {
    setEvents((prevEvents) => {
      return [
        ...EVENTS.map((event) =>
          event?.data?.appointment?.id === appointmentId
            ? {
              ...event,
              start: moment(start)?.toDate(),
              end: moment(end)?.toDate(),
            }
            : event
        ),
      ];
    });
  }, []);
  // console.log("events", events);

  return (
    <div className="mt-5 mx-5 bg-[#ffffff] h-full grid grid-cols-10 ">
      <div className="col-span-10">
        {/* <Calendar
          localizer={localizer}
          events={myEventsList}
          culture={i18n.language}
          startAccessor="start"
          endAccessor="end"
          popup
          messages={messages}
        /> */}
        <CustomCalendar
          className="w-full"
          resizable
          onEventDrop={({ start, end, event }) => {
            onChangeEventTime(start, end, event?.data?.appointment?.id);
          }}
          onEventResize={({ start, end, event }) => {
            onChangeEventTime(start, end, event?.data?.appointment?.id);
          }}
          events={events}
        />
      </div>
    </div>
  );
};

export default PersonelCalendar;
