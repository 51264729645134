import axios from "axios";

// const API_URL = process.env.REACT_APP_API_BASE_URL;
// const baseUrl =
//   process.env.REACT_APP_ENV === "production"
//     ? process.env.REACT_APP_API_PROD_URL
//     : process.env.REACT_APP_API_DEV_URL;

const baseUrl = process.env.REACT_APP_API_PROD_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${baseUrl}/verify-token`;
export const LOGIN_URL = `${baseUrl}/login`;

export function login(email, password) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  });
}
