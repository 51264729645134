import axios from "axios";
import { toast } from "react-toastify";

// const baseUrl =
//   process.env.REACT_APP_ENV === "production"
//     ? process.env.REACT_APP_API_PROD_URL
//     : process.env.REACT_APP_API_DEV_URL;

const baseUrl = process.env.REACT_APP_API_PROD_URL;

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "auth_token"
)}`;

axios.defaults.timeout = 60000;

export const getRequestHttps = async (url, params, returnData = "record") => {
  try {
    const response = await axios({
      url: `${baseUrl}/${url}`,
      method: "GET",
      params: params,
    });

    return response?.status === 200
      ? url === "v1/Company-detail"
        ? {
          ...response.data.record,
          manager_ids: response.data?.manager_user_ids,
        }
        : response.data.records ||
        response.data[returnData] ||
        response.data ||
        []
      : response;
  } catch (error) {
    console.log("url", error);
    return error;
  }
};

export const postOrPutRequestHttps = async (url, method, form, params = "") => {
  const res = await axios({
    url: `${baseUrl}/${url}`,
    method: method,
    params: params,
    data: form,
    timeout: 10000,
  });
  return res.data; // Başarılı yanıt döndürülür. 
};

export const registerCompanyHttps = async (url, method, form, params = "") => {
  try {
    const res = await axios({
      url: `${baseUrl}/${url}`,
      method: method,
      params: params,
      data: form,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return res.data;

  } catch (error) {
    // Hataları yönetmek için console.error ekleyin
    console.error("API request error: ", error);
    throw error; // Hata fırlatın ki handleSubmit fonksiyonu bunu yakalayabilsin
  }
};

export const verifyCompanyCodeHttps = async (url, method, form, params = "") => {
  try {
    const res = await axios({
      url: `${baseUrl}/${url}`,
      method: method,
      params: params,
      data: form,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return res.data;

  } catch (error) {
    console.log(error);
    throw error;
  }
}


export const deleteRequestHttps = async (url, form, params = "") => {
  const res = await axios({
    url: `${baseUrl}/${url}`,
    method: "DELETE",
    params: params,
    data: form,
    timeout: 10000,
  });
  return res.data;
};

const axiosInstance = axios.create({
  baseURL: baseUrl, // base URL burada tanımlanır
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Response interceptor ekliyoruz
axiosInstance.interceptors.response.use(
  (response) => response, // Başarılı yanıt direkt döndürülür
  (error) => {
    // Hata durumunda, belirli bir hata mesajı varsa onu gösteriyoruz
    const errorMessage = error.response?.data?.message;
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 2000 });
    } else {
      toast.error("Beklenmeyen bir hata oluştu", { autoClose: 2000 });
    }
    return Promise.reject(error); // Hata üst seviyeye fırlatılır
  }
);

// postOrPutHttps fonksiyonuna interceptor’u dahil ediyoruz
export const postOrPutHttps = async (url, method, form, params = "") => {
  try {
    // axiosInstance ile istek yapıyoruz
    const res = await axiosInstance({
      url: `${baseUrl}/${url}`,
      method,
      params,
      data: form,
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res.data; // Başarılı yanıt döndürülür.
  } catch (error) {
    console.log("Hata oluştu:", error);
    throw error; // Hata üst seviyeye fırlatılır
  }
};
