import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useNotificationContext } from "../../../../../context/NotifacitionContext";
// import { flattenWorkCalendar } from "../../../../../helpers/Function/Function";
import { getRequestHttps } from "../../../../../libs/AllGetRequest/HttpsHelper";
import PersonelCalendarTwo from "../PersonelCalendarTwo";
import WorkForm from "./WorkForm";
import moment from "moment";
import Sider from "antd/es/layout/Sider";
import { useInternalMessage } from "antd/es/message/useMessage";

const CalendarWiev = () => {
  const [t, i18n] = useTranslation("global");
  const { selectCompany, setTitle } = useNotificationContext();
  const { id } = useParams();
  const location = useLocation();
  const locationName = location.pathname?.split("/")[1];
  const [appointment, setAppointment] = useState();
  const [personnelDetail, setPersonnelDetail] = useState([]);
  const [userName, setUserName] = useState("");

  const last5Days = moment().subtract(5, "d");
  const today = moment();
  const last5DaysFormat = last5Days.format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");
  const todayFormat = today.format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");

  const [disabledDates, setDisabledDates] = useState([last5Days, today]);
  const [date, setDate] = useState({
    start: last5DaysFormat,
    end: todayFormat,
  });
  const [calendarData, setCalendarData] = useState();

  useEffect(() => {
    setTitle([
      {
        title:
          locationName === "branches"
            ? t("staff.branch")
            : locationName === "department"
              ? t("sidebar.department")
              : t("sidebar.staff"),
        href: locationName,
      },
      locationName === "staff"
        ? t("general.work_days")
        : t("general.work_days"),
    ]);
  }, [i18n.language]);

  const getDetailData = async (serial) => {
    try {
      const data =
        locationName === "staff"
          ? await getRequestHttps(`v1/user-detail`, {
            company_id: selectCompany?.company_id,
            user_id: serial,
          })
          : locationName === "department"
            ? await getRequestHttps(`v1/department-work-plan`, {
              company_id: selectCompany?.company_id,
              department_id: serial,
            })
            // : locationName === "branches"
            //   ? await getRequestHttps(`v1/branch-work-plan`, {
            //     company_id: selectCompany?.company_id,
            //     branch_id: serial,
            //   })
            //   : locationName === "company"
            //     ? await getRequestHttps(`v1/company-work-plan`, {
            //       company_id: selectCompany.company_id,
            //     })
                : null;

      const calendar = data?.map((item) => ({
        id: item.id,
        start: item.start,
        end: item.end,
        break_start: item.break_start,
        break_end: item.break_end,
        type: item.type,
        color: item.color,
        description: item.description,
        department_name: item?.department_name,
        branch_name: item?.branch_name,
        company_name: locationName === "company" ? item?.company_name : null
      }));
      setCalendarData(calendar);

      console.log("calendar: ", calendar);
      console.log("getDetailData: ", data);
      console.log("calendarData: ", calendarData);

      const formValue = {
        name: data?.name_surname || data?.name,
        email: data?.email,
        position: data?.work_title,
        branch: data?.branch_name,
        department: data?.department_name,
        holiday: data?.holiday_credit,
      };
      setPersonnelDetail(formValue);
    } catch (error) {
      console.error("Error fetching detail data: ", error);
    }
  };

  const getPersonelCalendar = async (serial) => {
    try {
      const startDateISO = moment(date.start).format("YYYY-MM-DD");
      const endDateISO = moment(date.end).format("YYYY-MM-DD");

      console.log("Start Date:", startDateISO);
      console.log("End Date:", endDateISO);

      const data = await getRequestHttps(`v1/user-work-plan`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
        start_day: startDateISO,
        end_day: endDateISO,
      });

      console.log("API response: ", data);
      console.log("Select Company: ", selectCompany);

      const response = await getRequestHttps(`v1/user-detail`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
      });

      const formValue = {
        name: response?.name_surname || "Personnel",
        email: response?.email,
        position: response?.work_title,
        branch: response?.branch_name,
        department: response?.department_name,
        holiday: response?.holiday_credit,
      };
      setPersonnelDetail(formValue);

      const combinedWorkPlan = [
        ...data?.userWorkPlan.map((item) => ({
          id: item.id,
          event_id: item.event_id,
          start: item.start,
          end: item.end,
          break_start: item.break_start,
          break_end: item.break_end,
          type: item.type,
          color: item.color,
          description: item.description,
          company_name: item.company_name,
          company_id: item.company_id,
          branch_name: item?.branch_name,
          branch_id: item?.branch_id,
          department_name: item?.department_name,
          department_id: item?.department_id,
          source: "userWorkPlan",  // Verinin kaynağı olarak 'userWorkPlan' belirtiyoruz
        })),

        // ...data?.companyWorkPlan.map((item) => ({
        //   id: item.id,
        //   start: item.start,
        //   end: item.end,
        //   break_start: item.break_start,
        //   break_end: item.break_end,
        //   type: item.type,
        //   color: item.color,
        //   description: item.description,
        //   company_name: item.company_name,
        //   company_id: item.company_id,
        //   source: "companyWorkPlan",  // Verinin kaynağı olarak 'companyWorkPlan' belirtiyoruz
        // })),

        // ...data?.branchWorkPlan.map((item) => ({
        //   id: item.id,
        //   start: item.start,
        //   end: item.end,
        //   break_start: item.break_start,
        //   break_end: item.break_end,
        //   type: item.type,
        //   color: item.color,
        //   description: item.description,
        //   branch_name: item.branch_name,
        //   branch_id: item.branch_id,
        //   source: "branchWorkPlan",  // Verinin kaynağı olarak 'branchWorkPlan' belirtiyoruz
        // })),

        ...data?.departmentWorkPlan.map((item) => ({
          id: item.id,
          start: item.start,
          end: item.end,
          break_start: item.break_start,
          break_end: item.break_end,
          type: item.type,
          color: item.color,
          description: item.description,
          department_name: item.department_name,
          department_id: item.department_id,
          source: "departmentWorkPlan",  // Verinin kaynağı olarak 'departmentWorkPlan' belirtiyoruz
        }))
      ];
      setCalendarData(combinedWorkPlan);
      console.log("calendarData: ", calendarData);
      console.log("combinedWorkPlan: ", combinedWorkPlan);
    } catch (error) {
      console.error("Error fetching calendar data: ", error);
    }
  };

  useEffect(() => {
    locationName === "staff" ? getPersonelCalendar(id) : getDetailData(id);
  }, [id, date]);

  return (
    <div className="mt-5 mx-5 bg-[#ffffff] h-full grid grid-cols-10 gap-5">
      <div className="col-span-10 lg:col-span-8 h-full overflow-y-auto max-h-[800px] min-h-[500px]">
        <PersonelCalendarTwo
          calendarData={calendarData}
          onShowAppointmentView={(appointment) => setAppointment(appointment)}
        />
      </div>
      <div className="col-span-10 lg:col-span-2 px -3">
        <div className="h-auto">
          <WorkForm
            setCalendarData={setCalendarData}
            calendarData={calendarData}
            event={appointment}
            personnel={personnelDetail}
            company={selectCompany}
            getDetailData={
              locationName === "staff" ? getPersonelCalendar : getDetailData
            }
            locationName={locationName}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarWiev;
