import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Select, Space, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { deleteRequestHttps } from "../../../libs/AllGetRequest/HttpsHelper";
import TableComponents from "../../Table/TableComponents";
import { EditIcon, TrashIcon } from "../../icons/General";
import AddPersonelICon from "../../icons/Personel/AddPersonelICon";

const SuperAdminList = ({ getUsers, loading, data }) => {
  const [t, i18n] = useTranslation("global");
  const { isEditMode, selectCompany } = useNotificationContext();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [tableColumn, setTableColumn] = useState("");

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const getColumnSearchProps = (dataIndex, selectOptions) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        {selectOptions && (
          <Select
            ref={searchInput}
            placeholder={t("general.reset")}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          >
            {selectOptions.map((option, key) => {
              return (
                <Select.Option key={key} value={`${option.value}`}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {!selectOptions && (
          <Input
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            onInput={() => {
              setSearchedColumn(dataIndex);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}
        <Space>
          <Button
            type="link"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("general.search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 80,
            }}
          >
            {t("general.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (!value || !record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  });

  const handleDelete = (id) => {
    toast.promise(
      deleteRequestHttps(
        `v1/user`,
        {},
        {
          company_id: selectCompany?.company_id,
          id: id,
        }
      ),
      {
        pending: t("crud.general.deleting"),
        success: {
          onClose: () => {
            getUsers();
          },
          autoClose: 1000,
          render: t("crud.general.deleted"),
        },
        error: t("super_admin.add_error"),
      }
    );
  };

  const columns = [
    {
      title: t("crud.add_super_admin.name"),
      dataIndex: "field1",
      ...getColumnSearchProps("field1"),
      render: (_, { field1, serial }) => (
        <div className="tableRowText capitalize">{field1}</div>
      ),
    },
    {
      title: t("crud.add_super_admin.email"),
      dataIndex: "field2",
      ...getColumnSearchProps("field2"),
      render: (text) => (
        <div className="tableRowText normal-case">{text || ""}</div>
      ),
    },
    {
      title: t("general.actions"),
      dataIndex: "serial",
      align: "center",
      width: "7%",
      render: (_, { field1, serial }) => {
        return (
          <div className="flex text-[#858F91] gap-2 items-center">
            {/* {!logStatus ? ( */}

            <Tooltip title={`${t("general.edit")} ${field1}`}>
              <div>
                <Link
                  className="tableActionIcon"
                  to={`edit_super_admin/${serial}`}
                >
                  <EditIcon width={20} height={20} />
                </Link>
              </div>
            </Tooltip>
            <Tooltip title={`${t("general.delete")} ${field1}`}>
              <Popconfirm
                onConfirm={() => handleDelete(serial)}
                title={() => <p className="capitalize"> {field1} </p>}
                description={t("general.confirm_delete")}
                placement="topLeft"
                icon={
                  <FaExclamationCircle
                    color="red"
                    className="mr-2 mt-[2px]"
                    size={18}
                  />
                }
                okType="danger"
              >
                {/* <div className="tableActionIcon">
                  <TrashIcon />
                </div> */}
                <div className="tableActionIcon">
                  <TrashIcon width={20} height={20} />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
    {
      dataIndex: "",
      width: "4%",
      title: (
        <div className="flex gap-1 items-center">
          <Tooltip
            title={`${t("crud.add_super_admin.title")}`}
            placement="topRight"
          >
            <div className="text-[#eaeaea] bg-[#bdbdbd] p-1 rounded-lg hover:bg-[#cecccc] cursor-pointer ">
              <Link
                className="duration-300 hover:text-[#407BFF]"
                to="add_super_admin"
              >
                <AddPersonelICon width={23} height={23} />
              </Link>
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const newColumns = isEditMode ? columns : columns.slice(0, -2);
    setTableColumn(newColumns);
  }, [isEditMode, i18n.language, selectCompany?.company_id]);

  return (
    <div className="mt-5 pl-10 pr-5">
      <TableComponents
        scroll={data?.length > 10 && { y: 700 }}
        title={false}
        bordered={false}
        loading={loading}
        size="large"
        pagination={false}
        columns={tableColumn}
        data={data}
        // virtual
      />
    </div>
  );
};

export default SuperAdminList;
