import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tooltip, Table } from "antd";
import {
  getRequestHttps,
  postOrPutRequestHttps,
} from "../libs/AllGetRequest/HttpsHelper";
import { useNotificationContext } from "../context/NotifacitionContext";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import CheckoutComponent from "../components/checkout/checkout-component";
import { useTranslation, Trans } from "react-i18next";
import { CompanyPaymentStatusType } from "../helpers/enums/CompanyPaymentStatusType";
import environment from "../environment/environment";
import { CreditCardIcon } from "../components/icons/General";

const Container = styled.div`
  padding: 1rem;
`;

const SubscriptionSection = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const InfoBanner = styled.div`
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 16px;
  color: #858F91;
  text-align: start;
  margin-top: 36px;
  width: 100%;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

const InfoBanner2 = styled.div`
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 16px;
  color: #858F91;
  text-align: start;
  margin-top: 14px;
  margin-bottom: 35px;
`;

const InfoBanner3 = styled.div`
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 9%
`;

const SubscriptionOption = styled.div`
  padding: 0.5rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#3248F2" : "transparent")};
  border: 2px solid ${(props) => (props.selected ? "#3248F2" : "#A9A9A9B2")};
  width: 100%;
  margin-right: 64px;
  &:hover {
    background-color: ${(props) => (props.selected ? "#0056b3" : "#f0f0f0")};
  }

  .subscription-label {
    color: ${(props) => (props.selected ? "#F5F5F5" : "#1A1A1A")};
    font-weight: 700;
    margin-top: 0.5rem;
    margin-left:0.25rem;
  }
  .subscription-price {
    color: ${(props) => (props.selected ? "#F5F5F5" : "#3248F2")};
    margin-left:0.25rem;
  }
  .subscription-info {
    color: ${(props) => (props.selected ? "#F5F5F5" : "#858F91")};
    margin-left:0.25rem;
  }

    .select-text {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => (props.selected ? "#3248F2" : "#F5F5F5")};
`;

const SelectButton = styled.div`
  background-color: ${(props) => (props.selected ? "#F5F5F5" : "#3248F2")};
  border-radius: 15px;
  height: 30px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  width:100%;
  }
`;

const Subscription = () => {
  const { selectCompany } = useNotificationContext();
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [companySubscriptionPlanId, setCompanySubscriptionPlanId] =
    useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [daysLeft, setDaysLeft] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const { isEditMode, setTitle } = useNotificationContext();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    setTitle([t("sidebar.subscription")]);
  }, [i18n.language]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = environment.netsDibspayment;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const fetchCompanyDetail = async () => {
    if (!selectCompany?.company_id) {
      toast.error("Company ID not found.");
      return;
    }

    try {
      const data = await getRequestHttps(`v1/company-detail`, {
        company_id: selectCompany.company_id,
      });
      setSelectedSubscription(data?.subscription_plan_id);
      setCompanySubscriptionPlanId(data?.subscription_plan_id);
    } catch (error) {
      console.error("Error fetching company details:", error);
      toast.error("Error fetching company details");
    }
  };

  const fetchPaymentDetails = async () => {
    if (!selectCompany?.company_id) {
      toast.error("Company ID not found.");
      return;
    }

    try {
      const data = await getRequestHttps(`v1/companyPaymentDetail`, {
        companyId: selectCompany.company_id,
      });
      setPaymentDetails(data);
    } catch (error) {
      console.error("Error fetching payment details:", error);
      toast.error("Error fetching payment details");
    }
  };

  const fetchPaymentInformation = async () => {
    if (!selectCompany?.company_id) {
      toast.error("Company ID not found.");
      return;
    }

    try {
      const data = await getRequestHttps(`v1/companyPaymentInformation`, {
        companyId: selectCompany.company_id,
      });
      if (data && data.receivePaymentDate) {
        const receivePaymentDate = dayjs(data.receivePaymentDate);
        const today = dayjs();
        const daysLeft = receivePaymentDate.diff(today, "day");
        setDaysLeft(daysLeft);
        setSubscriptionId(data.subscriptionId);
        setStatusId(data.statusId);
      }
    } catch (error) {
      console.error("Error fetching payment information:", error);
      toast.error("Error fetching payment information");
    }
  };

  const fetchData = async () => {
    await fetchCompanyDetail();
    await fetchPaymentDetails();
    await fetchPaymentInformation();
  };

  useEffect(() => {
    fetchData();
  }, [selectCompany]);

  const PaymentEnum = {
    Success: 1,
    Failed: 2,
  };

  const subscriptionOptions = [
    {
      value: 1,
      minCount: 1,
      maxCount: 1,
      label: t("subscription.beginner"),
      price_label: "18,75 DKK",
      info_label: "Max 1 bruger",
    },
    {
      value: 2,
      minCount: 2,
      maxCount: 5,
      label: t("subscription.standard"),
      price_label: "62,5 DKK",
      info_label: "Max 5 bruger",
    },
    {
      value: 3,
      minCount: 6,
      maxCount: 15,
      label: t("subscription.advanced"),
      price_label: "112,5 DKK",
      info_label: "Max 15 bruger"
    },
    {
      value: 4,
      minCount: 16,
      maxCount: null,
      label: t("subscription.pro"),
      price_label: "187,5 DKK",
      info_label: "16+ bruger"
    },
  ];

  const getSubscriptionLabel = (paymentTypeId) => {
    const option = subscriptionOptions.find(
      (opt) => opt.value === paymentTypeId
    );
    return option ? option.label : "";
  };

  const getPaymentStatus = (paymentStatusId) => {
    switch (paymentStatusId) {
      case PaymentEnum.Success:
        return t("general.success");
      case PaymentEnum.Failed:
        return t("general.failed");
      default:
        return "";
    }
  };

  const formatCurrency = (amount) => {
    // Assuming amount is 6250.00, we need to convert it to 62.5
    const correctedAmount = amount / 100;
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(correctedAmount);
  };

  const changeSubscription = (value) => {
    setSelectedSubscription(value);
  };

  const handleSave = async () => {
    if (!selectCompany?.company_id) {
      toast.error("Company ID not found.");
      return;
    }

    try {
      const personelList = await getRequestHttps(`v1/user`, {
        company_id: selectCompany.company_id,
      });
      const personCount = personelList?.count;

      const selectedPlan = subscriptionOptions.find(
        (plan) => plan.value === selectedSubscription
      );
      if (selectedSubscription < companySubscriptionPlanId) {
        if (
          selectedPlan &&
          selectedPlan.maxCount !== null &&
          personCount > selectedPlan.maxCount
        ) {
          toast.error(
            `You need to reduce your personnel count to ${selectedPlan.maxCount} or less to downgrade to the ${selectedPlan.label} plan.`
          );
          return;
        }
      }

      const updateData = {
        id: selectCompany.company_id,
        subscription_plan_id: selectedSubscription,
        max_stuff_count: selectedPlan.maxCount,
      };

      await postOrPutRequestHttps(`v1/company-edit`, "PUT", updateData, {
        company_id: selectCompany?.company_id,
      });
      toast.success("Subscription plan updated successfully");
      await fetchData(); // Sayfadaki bilgileri güncelle
    } catch (error) {
      console.error("Error updating subscription plan:", error);
      toast.error("Error updating subscription plan");
    }
  };

  const subscriptionActive = async () => {
    try {
      const subscriptionCancelRequest = {
        companyId: selectCompany.company_id,
        statusId: CompanyPaymentStatusType.Active,
      };
      const res = await toast.promise(
        postOrPutRequestHttps(
          "v1/SubscriptionCancel",
          "POST",
          subscriptionCancelRequest
        ),
        {
          pending: "Loading...",
          success: "Subscription activated successfully",
          error: "Error activating subscription",
        }
      );
      if (res) {
        await fetchData(); // Sayfadaki bilgileri güncelle
      }
    } catch (error) {
      console.error("Error activating subscription:", error);
      toast.error("Error activating subscription");
    }
  };

  const subscriptionCancel = async () => {
    try {
      const subscriptionCancelRequest = {
        companyId: selectCompany.company_id,
        statusId: CompanyPaymentStatusType.Canceled,
      };
      const res = await toast.promise(
        postOrPutRequestHttps(
          "v1/SubscriptionCancel",
          "POST",
          subscriptionCancelRequest
        ),
        {
          pending: "Loading...",
          success: "Subscription deactivated successfully",
          error: "Error deactivating subscription",
        }
      );
      if (res) {
        await fetchData(); // Sayfadaki bilgileri güncelle
      }
    } catch (error) {
      console.error("Error deactivating subscription:", error);
      toast.error("Error deactivating subscription");
    }
  };

  const updateCard = async (e) => {
    e.preventDefault();
    try {
      const subscriptionData = {
        PaymentPlanId: companySubscriptionPlanId,
        IsUpdateCard: subscriptionId == null ? false : true,
        SubscriptionId: subscriptionId,
        OriginName: window.location.origin,
      };

      const subscriptionResponse = await toast.promise(
        postOrPutRequestHttps("subscription-create", "POST", subscriptionData),
        {
          pending: "Loading...",
          success: "Subscription routing successfully",
          error: "Error routing to subscription",
        }
      );

      if (subscriptionResponse["paymentId"]) {
        setVerificationCode(subscriptionResponse["paymentId"]);
        setShowModal(true);
      } else {
        throw new Error("Subscription routing failed");
      }
    } catch (error) {
      console.error("Error updating card:", error);
      toast.error("Error updating card: " + error.message);
    }
  };

  const handleModelClose = async (result) => {
    setShowModal(false);
    if (result.isSucceeded) {
      try {
        const subscription = {
          PaymentTypeId: companySubscriptionPlanId,
          PaymentId: result.actionData.paymentId,
          SubscriptionId: subscriptionId,
          CompanyId: selectCompany.company_id,
        };

        const netsResponse = await postOrPutRequestHttps(
          "subscription-get",
          "POST",
          subscription
        );
        if (netsResponse) {
          await fetchData(); // Sayfadaki bilgileri güncelle
        }
      } catch (error) {
        console.error("Error completing the process", error);
        toast.error("Error completing the process");
      }
    }
  };

  const columns = [
    {
      title: t("subscription.paymentStatus"),
      dataIndex: "paymentStatusId",
      key: "paymentStatusId",
      render: (text) => getPaymentStatus(text),
    },
    {
      title: t("department.created_at"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) =>
        text == null ? "" : new Date(text).toLocaleDateString(),
    },
    {
      title: t("subscription.paidDate"),
      dataIndex: "paidDate",
      key: "paidDate",
      render: (text) =>
        text == null ? "" : new Date(text).toLocaleDateString(),
    },
    {
      title: t("subscription.nextPaymentDate"),
      dataIndex: "nextPaymentDate",
      key: "nextPaymentDate",
      render: (text) =>
        text == null ? "" : new Date(text).toLocaleDateString(),
    },
    {
      title: t("subscription.paidAmount"),
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (amount) => (amount == null ? "" : formatCurrency(amount)),
    },
    {
      title: t("subscription.paidNetAmount"),
      dataIndex: "paidNetAmount",
      key: "paidNetAmount",
      render: (amount) => (amount == null ? "" : formatCurrency(amount)),
    },
    {
      title: t("subscription.subscriptionPlan"),
      dataIndex: "paymentTypeId",
      key: "paymentTypeId",
      render: (paymentTypeId) => getSubscriptionLabel(paymentTypeId),
    },
    {
      title: t("subscription.message"),
      dataIndex: "message",
      key: "message",
    },
  ];

  return (
    <Container>
      <div className={statusId === CompanyPaymentStatusType.Test ? "d-none" : ""}>
        <div className="custom-border-b"></div>
        <div className="row align-items-center">
          <div
            className={`col ${statusId === CompanyPaymentStatusType.Deactive ||
              (statusId === CompanyPaymentStatusType.Canceled && daysLeft < 0)
              ? "d-none"
              : "col-6"
              }`}
          >
            <InfoBanner
              className={
                statusId === CompanyPaymentStatusType.Deactive ||
                  (statusId === CompanyPaymentStatusType.Canceled && daysLeft < 0)
                  ? "d-none"
                  : "col-6"
              }
            >
              <div className="d-flex align-items-center">
                {statusId === CompanyPaymentStatusType.Active ||
                  (statusId === CompanyPaymentStatusType.Canceled && daysLeft > 0)
                  ? t("subscription.remainderSubscription") +
                  ` ${daysLeft} `
                  : t("subscription.changeCard")}
                {statusId === CompanyPaymentStatusType.UpdateCard ? (
                  <div
                    className="icon-filter ms-2 mb-1 cursor-pointer"
                    onClick={updateCard}
                  >
                    <CreditCardIcon width={22} />
                  </div>
                ) : (
                  <div className="icon-filter ms-2 mb-1"></div>
                )}

              </div>
            </InfoBanner>
          </div>

          <div className="col">

          </div>
          <InfoBanner2>
            {statusId === CompanyPaymentStatusType.Deactive ||
              statusId === CompanyPaymentStatusType.Canceled
              ? t("subscription.statusCancelled")
              : t("subscription.statusActive")}
          </InfoBanner2>
        </div>

        <div>
          {/* <label className="formTitle mb-2 text-start w-100">
            {t("subscription.changeSubscriptionPlan")}
          </label> */}
          <div className="d-flex justify-content-start align-items-center">
            {subscriptionOptions.map((option) => (
              <Tooltip key={option.value} title={option.price_label}>
                <SubscriptionOption
                  selected={selectedSubscription === option.value}
                  onClick={() => changeSubscription(option.value)}
                >
                  <div className="flex-row text-left w-100">
                    <p className="subscription-label">
                      {option.label}
                    </p>
                    <p className="subscription-price">
                      {option.price_label}
                    </p>
                    <p className="subscription-info">
                      {option.info_label}
                    </p>
                    <SelectButton selected={selectedSubscription === option.value}>
                      <p className="select-text justify-content-center">
                        Select
                      </p>
                    </SelectButton>
                  </div>
                </SubscriptionOption>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-5">
        {/* <h3>{t("subscription.paymentDetails")}</h3> */}
        <Table columns={columns} dataSource={paymentDetails} rowKey="id" />
      </div>

      {showModal && (
        <CheckoutComponent
          verificationCode={verificationCode}
          showModal={showModal}
          handleClose={handleModelClose}
        />
      )}
      <div className="row">
        <div className="col d-flex justify-content-end">
          <Tooltip
            title={
              statusId === CompanyPaymentStatusType.Active
                ? t("subscription.subscriptionCancel") // Abonelik aktifse bu mesaj gösterilecek
                : t("subscription.subscriptionActive") // Abonelik deaktifse bu mesaj gösterilecek
            }
          >
            <InfoBanner3
              className={
                statusId === CompanyPaymentStatusType.Active
                  ? "btn btn-danger"
                  : "btn btn-success"
              }
              onClick={
                statusId === CompanyPaymentStatusType.Deactive ||
                  statusId === CompanyPaymentStatusType.Canceled
                  ? subscriptionActive
                  : subscriptionCancel
              }
            >
              {statusId === CompanyPaymentStatusType.Deactive ||
                statusId === CompanyPaymentStatusType.Canceled
                ? t("general.active")
                : t("general.cancel")}
            </InfoBanner3>
          </Tooltip>
          <InfoBanner3
            onClick={handleSave}
            className="bg-blue-500 text-white ms-3"
          >
            {t("general.save")}
          </InfoBanner3>
        </div>
      </div>
    </Container>
  );
};

export default Subscription;
