import React from "react";
import { Route, Routes } from "react-router-dom";
import Users from "../../pages/Users";
import AddUser from "../Modules/User/AddUser/AddUser";

const UsersRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Users />} />
      <Route index path="/add_super_admin" element={<AddUser />} />
      <Route index path="/edit_super_admin/:id" element={<AddUser />} />

      <Route index path="/add_manager" element={<AddUser />} />
      <Route index path="/edit_manager/:id" element={<AddUser />} />
    </Routes>
  );
};

export default UsersRoutes;
