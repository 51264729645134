import React from "react";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/NotifacitionContext";

const ManagerCompanyList = ({ selectCompanyName, value }) => {
  const { setSelectCompany } = useNotificationContext();
  const navigate = useNavigate();

  return (
    <div>
      {value?.length > 0
        ? value.map((item, index) => {
            const valueName = item?.company_name
              ?.toUpperCase()
              .includes(selectCompanyName?.toUpperCase());

            return (
              <div
                key={index}
                onClick={() => {
                  navigate("/company");
                  localStorage.setItem(
                    "selectCompany",
                    JSON.stringify({
                      branch: item?.branc_label,
                      department: item?.department_label,
                      image: item?.logo,
                      company_name: item?.company_name,
                      company_id: item?.company_id,
                    })
                  );
                  setSelectCompany({
                    branch: item?.branc_label,
                    department: item?.department_label,
                    image: item?.logo,
                    company_name: item?.company_name,
                    company_id: item?.company_id,
                  });
                }}
                className="tableRowText capitalize text-[#407BFF]  hover:text-[#009BDA] duration-300"
              >
                {selectCompanyName
                  ? valueName
                    ? item?.company_name
                    : ""
                  : item?.company_name}
                {/* {value.length > 1 && index !== value?.length - 1 && ","} */}
              </div>
            );
          })
        : "-"}
    </div>
  );
};

export default ManagerCompanyList;
