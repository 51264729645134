import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { useNotificationContext } from "../../context/NotifacitionContext";
import Branches from "../../pages/Branches";
import AddBranch from "../Modules/Branches/AddBranch";
import CalendarWiev from "../Modules/Personel/PersonelWorkCalendar/PersonelWorkPlan/CalendarWiev";

const BranchRoutes = () => {
  const { setTitle, selectCompany } = useNotificationContext();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    // setTitle([selectCompany?.branch]);
    setTitle([t("sidebar.branches")]);
  }, [i18n.language]);

  return (
    <Routes>
      <Route index path="/" element={<Branches />} />
      <Route path="/add_branch" element={<AddBranch />} />
      <Route path="/edit_branch/:id" element={<AddBranch />} />
      <Route path="/work_plan/:id" element={<CalendarWiev />} />
    </Routes>
  );
};

export default BranchRoutes;
