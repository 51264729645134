import { Image, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useNotificationContext } from "../../context/NotifacitionContext";
import { useAuthContext } from "../../context/UserContext";

const SidebarHeader = ({ collapsed }) => {
  const { profil } = useAuthContext();
  const { selectCompany } = useNotificationContext();

  const [refreshLogo, setRefreshLogo] = useState(false);

  useEffect(() => {
    setRefreshLogo(true);
    setTimeout(() => {
      setRefreshLogo(false);
    }, 1000);
  }, [selectCompany?.company_id]);

  return (
    <div className="relative">
      <div
        className={`flex ${collapsed ? "justify-center" : ""} relative ${
          refreshLogo && "sideBarLogo"
        }`}
      >
        {collapsed ? (
          selectCompany ? (
            selectCompany?.image ? (
              <Image
                loading="lazy"
                preview={false}
                className="object-cover rounded-full"
                style={{ width: "35px", height: "35px" }}
                src={selectCompany?.image}
              />
            ) : (
              <div className="tableRowText h-[35px] w-[35px] flex items-center justify-center border-2 rounded-full  p-1">
                {selectCompany?.company_name?.substring(0, 2)}
              </div>
            )
          ) : (
            <Image
              loading="lazy"
              className="object-cover rounded-full"
              preview={false}
              style={{
                width: "35px",
                height: "35px",
              }}
              src="/media/sidebar/isank_logo.png"
            />
          )
        ) : (
          <div className="flex w-full items-center ml-1 gap-1 justify-center">
            {/* {selectCompany ? (
              selectCompany?.image ? (
                <Image
                  loading="lazy"
                  className="object-cover rounded-full"
                  preview={false}
                  style={{
                    width: "35px",
                    height: "35px",
                  }}
                  src={selectCompany?.image}
                />
              ) : (
                <div className="tableRowText h-[35px] w-[35px] flex items-center justify-center border-2 rounded-full  p-1">
                  {selectCompany?.company_name?.substring(0, 2)}
                </div>
              )
            ) : (
              <Image
                loading="lazy"
                className="object-cover rounded-full"
                preview={false}
                style={{
                  width: "190px",
                  height: "80px",
                }}
                src={"/media/login/tjekdag.svg"}
              />
            )} */}

            <Image
                loading="lazy"
                className="object-cover rounded-full"
                preview={false}
                style={{
                  width: "190px",
                  height: "80px",
                }}
                src={"/media/login/tjekdag.svg"}
              />

            {/* {selectCompany?.company_name && (
              <p className="text-[#858F91]">
                {selectCompany?.company_name?.substring(0, 18)}
                {selectCompany?.company_name?.length > 18 && "..."}
              </p>
            )} */}
          </div>
        )}
      </div>
      <Tag
        color={
          profil?.userType === 0
            ? "red"
            : profil?.userType === 1
            ? "blue"
            : "yellow"
        }
        style={{
          marginTop: "40px"
        }}
        className="absolute top-12 left-[10%]"
      >
        {profil?.userType === 0
          ? !collapsed
            ? "Super Admin"
            : "SA"
          : profil?.userType === 1
          ? !collapsed
            ? "Manager"
            : "M"
          : !collapsed
          ? "Staff"
          : "S"}
      </Tag>
    </div>
  );
};

export default SidebarHeader;
