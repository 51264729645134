import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Tooltip, Upload } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { useAuthContext } from "../../../context/UserContext";
import { addCompanySchema } from "../../../helpers/FormSchemas/FormErrorMessage";
import { beforeUpload, ChangeTier2Status, getBase64 } from "../../../helpers/Function/Function";
import {
  getRequestHttps,
  postOrPutRequestHttps,
} from "../../../libs/AllGetRequest/HttpsHelper";
import BranchMap from "../Branches/BranchMap";
import CitySearchList from "../Branches/CitySearchList";
import { ChangeLatlong, SwitchField, } from "../../../helpers/Function/Function";
import { RxCross1 } from 'react-icons/rx';
import axios from "axios";

const CompanySettings = ({ setStation }) => {
  const { profil } = useAuthContext();
  const { isEditMode, selectCompany, setSelectCompany } = useNotificationContext();
  const [t] = useTranslation("global");

  const citySearch = "https://nominatim.openstreetmap.org/search?";

  const [loading, setLoading] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [companyDetail, setCompanyDetail] = useState({
    id: "",
    company_name: "",
    description: "",
    logo: "",

    branch: false,
    branch_label: "",
    department: false,
    department_label: "",
    location_tracking: "",
    company_address: "",
    lat_long: {
      lat: '',
      long: '',
      radius: '',
    }
  });

  const fetcher = async (search) => {
    setLoading(true);
    const params = {
      q: search,
      format: "json",
      addressdetails: 1,
      polygon_geojson: 0,
    };
    const queryString = new URLSearchParams(params).toString();

    try {
      const data = await axios(`${citySearch}${queryString}`);
      setCityList(data?.data);
    } finally {
      setLoading(false);
    }
  };

  const getDetailData = async () => {
    try {
      const data = await getRequestHttps(`v1/company-detail`, {
        company_id: selectCompany?.company_id,
      });
      const formValue = {
        id: data?.id,
        company_name: data?.name,
        description: data?.description,
        logo: data?.logo,
        branch: data?.branch_label ? true : false,
        branch_label: data?.branch_label || t("sidebar.branches"),
        department: data?.department_label ? true : false,
        department_label: data?.department_label || "Department",
        location_tracking: data?.location_tracking ? true : false,
        company_address: data?.address,
        tax_number: data?.tax_number,
        lat: data?.lat_long?.lat,
        long: data?.lat_long?.long,
        radius: data?.lat_long?.radius,
      };
      setCompanyDetail(formValue);
    } catch (error) { }
  };

  useEffect(() => {
    getDetailData();
  }, []);

  const [cityList, setCityList] = useState([]);
  const [cityPosition, setCityPosition] = useState([]);
  const [openList, setOpenList] = useState();
  const [errors, setErrors] = useState([]);

  const sumbitFunction = (e, setSubmitting, reset) => {
    console.log("e", e);

    const updateForm = {
      id: companyDetail?.id,
      name: e?.company_name,
      description: e?.description,
      address: e?.company_address,
      logo: e?.logo,
      branch_label: e?.branch ? e?.branch_label : null,
      department_label: e?.department ? e?.department_label : null,
      location_tracking: e?.location_tracking,
      lat_long: {
        lat: e?.lat,
        long: e?.long,
        radius: e?.radius,
      },
    };

    toast.promise(
      postOrPutRequestHttps(`v1/company-edit`, "PUT", updateForm, {
        company_id: selectCompany?.company_id,
      }),
      {
        pending: t("crud.general.updating"),
        success: {
          onClose: () => {
            setSelectCompany((prev) => {
              return {
                ...prev,
                company_name: e?.company_name,
                image: e?.logo,
              };
            });
            setStation(0);
          },
          autoClose: 1000,
          render: t("crud.general.updated"),
        },
        error: t("super_admin.add_error"),
      }
    );
    setSubmitting(false);
  };

  const handleChange = (info, setFieldValue) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "removed") {
      setFieldValue("logo", "");
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setFieldValue("logo", url);
      });
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
      </div>
    </button>
  );

  return (
    <div className="flex flex-col items-center ">
      <Formik
        validationSchema={addCompanySchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={companyDetail}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="container-fluid p-4 w-100 h-100" style={{ fontFamily: "Poppins" }} >
              <ChangeLatlong lat={values?.lat} long={values?.long} setCityPosition={setCityPosition} />
              <ChangeTier2Status field={"department"} value={values?.branch} setValue={values?.department} setFieldValue={setFieldValue} />
              <div className="row" style={{ height: "7rem" }}>
                <div className="col-2">
                  <Field name="logo" className="formInput">
                    {() => {
                      return (
                        <>
                          <Upload
                            disabled={!isEditMode}
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            accept=".png, .jpeg, .jpg, .svg"
                            customRequest={({ file, onSuccess }) => {
                              setTimeout(() => {
                                onSuccess("ok");
                              }, 0);
                            }}
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            onPreview={() => setPreviewOpen(true)}
                            onChange={(e) => handleChange(e, setFieldValue)}
                          >
                            {/* todo göksel logo ve div width ayarla */}
                            {values.logo ? (
                              <img
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                src={values.logo}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                          <Modal
                            open={previewOpen}
                            footer={null}
                            onCancel={() => setPreviewOpen(false)}
                          >
                            <img
                              alt="example"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={values.logo}
                            />
                          </Modal>
                        </>
                      );
                    }}
                  </Field>
                </div>
                {/* <div className="col-3 ">
                  <div className="row">
                    <div className="col text-end fw-bold">
                      <label htmlFor="branch" className="formTitle mt-0">
                        {t("general.tier1")}
                      </label>
                    </div>
                    <div className="col">
                      <Field name="branch" className="custom-switch" checked={values?.branch} checkedChildren={t("general.open_edit")} unCheckedChildren={t("general.close_edit")} component={SwitchField} />
                    </div>
                    <div className="col">
                      <div className="text-red-600">
                        <ErrorMessage name="branch" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="row">
                    <div className="col text-end fw-bold">
                      <label htmlFor="department" className="formTitle mt-0">
                        {t("general.tier2")}
                      </label>
                    </div>
                    <div className="col">
                      <Field name="department" className="custom-switch" disabled={!values?.branch} checked={values?.department} checkedChildren={t("general.open_edit")} unCheckedChildren={t("general.close_edit")} component={SwitchField} />
                    </div>
                    <div className="col">
                      <div className="text-red-600">
                        <ErrorMessage name="department" />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-4 d-flex justify-content-end">
                  <div className="row w-100">
                    <div className="col d-flex align-items-center justify-content-end">
                      <label htmlFor="location_tracking" className="formTitle mt-0 me-3 text-end">{t("general.location_tracking")}</label>
                      <Tooltip title={t("general.location_tracking_info")}>
                        <Field name="location_tracking" className="custom-switch" checked={values?.location_tracking} checkedChildren={t("general.open_edit")} unCheckedChildren={t("general.close_edit")} component={SwitchField} />
                      </Tooltip>
                    </div>
                  </div>
                </div> */}
                <div className="col-10 d-flex justify-content-end align-items-center">
                  <label htmlFor="location_tracking" className="formTitle mt-0 w-auto me-3">{t("general.location_tracking")}</label>
                  <Tooltip title={t("general.location_tracking_info")}>
                    <Field name="location_tracking" className="custom-switch" checked={values?.location_tracking} checkedChildren={t("general.open_edit")} unCheckedChildren={t("general.close_edit")} component={SwitchField} />
                  </Tooltip>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="inputOutlined">
                    <label htmlFor="company_name" className="formTitle required"> {t("crud.company.company_name")}</label>
                    <Field disabled={!isEditMode} name="company_name" className="inputOutlinedInput form-control form-control-sm disabled:bg-[#c4c1c1]"></Field>
                    <div className="text-red-600">
                      <ErrorMessage name="company_name" />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="inputOutlined">
                    <label htmlFor="description" className="formTitle">{t("general.description")}</label>
                    <Field disabled={!isEditMode} name="description" className="inputOutlinedInput form-control form-control-sm disabled:bg-[#c4c1c1]" />
                    <div className="text-red-600"> <ErrorMessage name="description" /></div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="inputOutlined">
                    <label htmlFor="tax_number" className="formTitle"> {t("crud.company.tax_number")} </label>
                    <Field disabled name="tax_number" className="inputOutlinedInput form-control form-control-sm disabled:bg-[#c4c1c1]" />
                    <div className="text-red-600"> <ErrorMessage name="tax_number" /></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="inputOutlined">
                    <label htmlFor="company_address" className="formTitle">{t("general.address")}</label>
                    <div className="relative">
                      <Field name="company_address" className="inputOutlinedInputSearch form-control form-control-sm" />
                      <button type="button" disabled={values?.address?.length < 3} onClick={() => { setOpenList(true); fetcher(values.company_address); }} className="searchBtn absolute rounded-r-lg  items-center flex justify-center h-12 bg-gray-300 disabled:bg-gray-400 cursor-pointer">
                        {t("general.search")}
                      </button>
                      <div className="text-red-600">
                        <ErrorMessage name="company_address" />
                      </div>
                    </div>
                    <div className={`w-full bg-[#f3f2f2] absolute z-10 rounded-xl shadow-md duration-300 overflow-y-auto ${openList ? "h-40" : "h-0"}`}>
                      <div className="absolute right-3 top-3">
                        <button className="duration-300 text-[#858F91] cursor-pointer hover:text-[#407BFF]" type="button" onClick={() => setOpenList(false)}>
                          <RxCross1 />
                        </button>
                      </div>
                      <CitySearchList setFieldValue={setFieldValue} cityList={cityList} setCityList={setCityList} setCityPosition={setCityPosition} setOpenList={setOpenList} loading={loading} />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="inputOutlined">
                    <label htmlFor="lat" className="formTitle required"> {t("general.lat")}</label>
                    <Field name="lat" className="inputOutlinedInput form-control form-control-sm" type="number" />
                    <div className="text-red-600">
                      <ErrorMessage name="lat" />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="inputOutlined">
                    <label htmlFor="long" className="formTitle required">{t("general.long")}</label>
                    <Field name="long" className="inputOutlinedInput form-control form-control-sm" type="number" />
                    <div className="text-red-600"><ErrorMessage name="long" /></div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="inputOutlined">
                    <label htmlFor="radius" className="formTitle">{t("general.radius")}</label>
                    <Field name="radius" className="inputOutlinedInput form-control form-control-sm" type="number" />
                    <div className="text-red-600">
                      <ErrorMessage name="radius" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-96 border-round">
                <BranchMap
                  radius={values?.radius}
                  setCityPosition={setCityPosition}
                  cityPosition={cityPosition}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-end mt-3">
                  <button disabled={profil?.userType === 0 && !isEditMode} type="submit" className="xl:px-20 px-5 py-3 duration-300 relative flex submitButton">
                    {t("general.save")}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div >
  );
};

export default CompanySettings;
