import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../../context/NotifacitionContext";
import { getRequestHttps } from "../../../../libs/AllGetRequest/HttpsHelper";

const CompanyDetail = () => {
  const [t] = useTranslation("global");
  const { selectCompany, setSelectData } = useNotificationContext();
  const [breakUserCountData, setBreakUserCountData] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLoginCountByDepartment = (department_id, loggedData) => {
    const data = loggedData?.find((item) => item?.department_id === department_id);
    return data ? data?.total_logged_user : 0;
  };

  const getBranchName = (branch_id, branchData) => {
    const branch = branchData?.find((item) => item.id === branch_id);
    return branch ? branch.name : "N/A";
  };

  const getDepartments = async () => {
    setLoading(true);
    try {

      let branchData = await getRequestHttps("v1/branch", {
        company_id: selectCompany?.company_id,
      });



      const departmentData = await getRequestHttps("v1/department", {
        company_id: selectCompany?.company_id,
      });

      const departmentUserLoggedData = await getRequestHttps(
        "v1/departments-user-logged-status",
        {
          company_id: selectCompany?.company_id,
        }
      );

      const departmentIds = departmentData?.map((department) => department.id) || [];
      // const today = new Date().toISOString().split('T')[0];
      const today = new Date().toISOString();

      const queryParams = new URLSearchParams({
        company_id: selectCompany?.company_id,
        limit: 100,
        department_ids: departmentIds.join(','), // Tüm departman id'lerini virgülle ayırarak gönderiyoruz
        specificDate: today
      });

      // API yanıtını işleyin
      const breakUserCountData = await getRequestHttps(`v1/work-break-user-count?${queryParams.toString()}`);
      console.log(breakUserCountData)

      const departmentsWithBranch = departmentData?.map((department) => {
        const breakData = breakUserCountData.find(breakInfo => 
          breakInfo.departmentId.includes(department.id) // departmentId dizisi içinde departman id'sini kontrol ediyoruz
        );
        const breakUserCount = breakData ? breakData.count : 0;
        return {
          id: department?.id,
          name: department?.name,
          total: department?.user_count,
          branch_name: getBranchName(department?.branch_id, branchData), // Şube ismini ekle
          total_logged_user: getLoginCountByDepartment(department?.id, departmentUserLoggedData),
          break_user_count: breakUserCount, // İzinli kullanıcı sayısını ekle
          description: department?.description || "-",
        };
      });

      setDepartmentList(departmentsWithBranch);
      setLoading(false);
    } catch (e) {
      console.error("Error fetching data: ", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectCompany?.company_id) {
      getDepartments();
    }
  }, [selectCompany?.company_id]);

  return (
    <div className="flex flex-col h-full">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="w-full branch-odd-row">
          <thead className="text-[#858F91] text-base xxl:text-lg font-semibold text-left">
            <tr className="sticky table-top top-0 z-10 h-10 custom-border-b capitalize totalTexts">
              <th className="px-2">{t("general.branches")}</th>
              <th className="px-2">{t("sidebar.department")}</th>
              <th className="px-2">{t("general.personnel")}</th>
              <th className="px-2">{t("general.login")}</th>
              <th className="px-2">{t("general.leaves")}</th>
            </tr>
            <div className="mt-2"></div>
          </thead>
          <tbody>
            {departmentList?.map((department) => (
              <tr key={department.id} className="text-left text-[#858F91] h-10 cursor-pointer">
                <td className="py-1 px-2">{department.branch_name}</td>
                <td className="py-1 px-2">{department.name}</td>
                <td className="py-1 px-3">
                  <span className="w-2 h-2 rounded-full inline-block mr-2" style={{ backgroundColor: '#3248F2' }}></span>
                  <span>{department.total || 0}</span>
                </td>
                <td className="py-1 px-3">
                  <span className="w-2 h-2 rounded-full inline-block mr-2" style={{ backgroundColor: '#52C41A' }}></span>
                  <span>{department.total_logged_user || 0}</span>
                </td>
                <td className="py-1 px-3">
                  <span className="w-2 h-2 rounded-full inline-block mr-2" style={{ backgroundColor: '#52C41A' }}></span>
                  {/* Burada doğrudan break_user_count'u kullanabilirsiniz */}
                  <span>{department.break_user_count || 0}</span> {/* break_user_count kullanın */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CompanyDetail;
