import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import {
  Circle,
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useLocation } from "react-router-dom";
import { useNotificationContext } from "../../context/NotifacitionContext";
import { getRequestHttps } from "../../libs/AllGetRequest/HttpsHelper";
import ArrowIcon from "../icons/ArrowIcon";
import { CompanyBranchesIcon, CompanyMapIcon } from "../icons/General";
import CompanyDepartmentIcon from "../icons/General/CompanyDepartmentIcon";
import MapLeftSideBar from "./MapLeftSideBar";

const Map = ({
  data,
  setSelectBranches,
  selectBranches,
  selectData,
  selectDepartment,
  setSelectDepartment,
}) => {
  const { selectCompany } = useNotificationContext();
  const { state } = useLocation();
  const mapRef = useRef();

  const [personnelListArray, setPersonnelListArray] = useState([]);

  const [isRightMenuOpen, setIsRightMenuOpen] = useState(true);

  const getPersonel = async () => {
    let data;

    try {
      data = await getRequestHttps(
        "v1/user",
        {
          company_id: selectCompany?.company_id,
          branch_id: selectBranches,
          department_id: selectDepartment,
        },
        "result"
      );

      const personnelList = data
        ?.filter((e) => e?.type_id === 2)
        .map((item) => {
          return {
            date: item?.updated_at || item?.created_at,
            name: item?.name_surname,
            status: item?.is_record_login,
            type: item?.type_id,
          };
        });

      setPersonnelListArray(personnelList);
    } catch (e) {}
  };

  useEffect(() => {
    getPersonel();
  }, [selectBranches, selectDepartment]);

  useEffect(() => {
    const whichBranches = data.filter((item) =>
      selectBranches > 0 || selectDepartment > 0
        ? (selectBranches === item.id || selectDepartment === item.id) && item
        : item.type === "company" && item
    )[0];

    mapRef.current?.flyTo(
      [whichBranches?.lat, whichBranches?.long],
      mapRef?.current?.getZoom(),
      {
        duration: 1.5,
        animate: true,
      }
    );
  }, [data, selectBranches, selectDepartment]);

  const selectIcon = (total, id, main) => {
    const mapIcon = L.divIcon({
      className: "lastPolygonIdMarker",
      html: renderToString(
        <div className=" relative">
          {total ? (
            <div className="absolute -top-4 -right-2 bg-[#407BFF] p-1 rounded-full w-7 h-7 flex items-center justify-center">
              <p className="text-white font-medium">{total}</p>
            </div>
          ) : (
            ""
          )}
          {main === "company" ? (
            <CompanyMapIcon width={40} />
          ) : main === "branch" ? (
            <CompanyBranchesIcon width={40} />
          ) : (
            <CompanyDepartmentIcon width={40} />
          )}
        </div>
      ),
    });
    return mapIcon;
  };

  return (
    <div className="relative w-full h-full ">
      <div className={state?.company ? "mapScreen" : ""} />
      <div
        className={`absolute top-0 left-0 z-[600] duration-300  flex flex-col h-full glass gap-1 items-end ${
          isRightMenuOpen ? "w-[340px]" : "w-0"
        }`}
        style={{borderRadius:"30px"}}
      >
        <div className="absolute bottom-5">
          <div
            className={`shadow-md absolute bottom-5 z-50 bg-[#53626F] rounded-lg text-white cursor-pointer hover:bg-[#858F91] duration-200 ${
              isRightMenuOpen ? "rotate-180 -left-2" : "-left-5"
            }`}
            onClick={() => setIsRightMenuOpen((prev) => !prev)}
          >
            <ArrowIcon width={30} height={30} />
          </div>
        </div>
        {isRightMenuOpen ? (
          <MapLeftSideBar
            data={data}
            setSelectBranches={setSelectBranches}
            selectBranches={selectBranches}
            personnelList={personnelListArray}
            selectDepartment={selectDepartment}
            setSelectDepartment={setSelectDepartment}
          />
        ) : (
          <div className="flex items-center justify-center bg-red-400 w-full"></div>
        )}
      </div>
      <MapContainer
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        minZoom={3}
        ref={mapRef}
        center={[55.72398450950338, 9.6684421188379]}
        zoom={7}
        style={{borderRadius:"30px"}}
      >
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        {data.map(
          (item, index) =>
            item?.lat &&
            item?.long &&
            item?.radius &&
            !item.address && (
              <Circle
                key={index}
                center={[item?.lat, item?.long]}
                pathOptions={{ fillColor: "red" }}
                radius={item?.radius}
              />
            )
        )}
        <MarkerClusterGroup
          maxClusterRadius={20}
          disableClusteringAtZoom={15}
          chunkedLoading
        >
          {data?.map((item, index) => {
            return (
              <div key={index}>
                {item?.lat && item?.long && !item.address && (
                  <Marker
                    eventHandlers={{
                      click: (e) => {
                        if (
                          item.id === selectBranches ||
                          item.id === selectDepartment ||
                          item?.type === "company"
                        ) {
                          setSelectDepartment();
                          setSelectBranches();
                        } else {
                          item?.type === "department" &&
                            setSelectDepartment(item.id);
                          item?.type === "branch" && setSelectBranches(item.id);
                        }
                      },
                    }}
                    icon={selectIcon(item?.total, item?.id, item?.type)}
                    position={[item.lat, item.long]}
                  >
                    {" "}
                    <Tooltip direction="top">{item?.title}</Tooltip>
                  </Marker>
                )}
              </div>
            );
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default Map;
