import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Image,
  Input,
  Popconfirm,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { toast } from "react-toastify";
import TableComponents from "../../components/Table/TableComponents";
import {
  AddCompanyIcon,
  EditIcon,
  TrashIcon,
} from "../../components/icons/General";
import { useNotificationContext } from "../../context/NotifacitionContext";
import { useAuthContext } from "../../context/UserContext";
import {
  deleteRequestHttps,
  getRequestHttps,
} from "../../libs/AllGetRequest/HttpsHelper";

const Companies = () => {
  const { profil } = useAuthContext();
  const { setTitle, isEditMode, setSelectCompany } = useNotificationContext();
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const searchInput = useRef(null);

  const containerRef = useRef(null);

  const getCompanies = async () => {
    setLoading(true);
    let data;
    try {
      data = await getRequestHttps("v1/company");
      const companiesList = data?.map((e, i) => {
        return {
          image: e?.logo,
          last_seen: e?.created_at,
          field1: e?.name,
          field2: e?.manager_count,
          field3: e?.branches_count || "",
          field4: e?.user_count || "",
          field5: e?.tax_number || "",
          is_active: e?.is_active ? true : false,
          deviceId: e?.id,
          branch: e?.branch_label,
          department: e?.department_label,
          licence: e?.max_stuff_count > 0 ? e?.max_stuff_count : "",
          serial: "",
        };
      });
      setCompaniesList(companiesList);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const handleDelete = (id) => {
    toast.promise(
      deleteRequestHttps(`v1/company-delete?company_id=${id}`, {}),
      {
        pending: t("crud.general.deleting"),
        success: {
          onClose: () => {
            getCompanies();
          },
          autoClose: 1000,
          render: t("crud.general.deleted"),
        },
        error: t("super_admin.add_error"),
      }
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const getColumnSearchProps = (dataIndex, selectOptions) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        {selectOptions && (
          <Select
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          >
            {selectOptions.map((option, key) => {
              return (
                <Select.Option key={key} value={`${option.value}`}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {!selectOptions && (
          <Input
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: selectedKeys},
              // 	}
              // })
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            onInput={() => {
              setSearchedColumn(dataIndex);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}
        <Space>
          <Button
            type="link"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: selectedKeys},
              // 	}
              // })
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("general.search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: ''},
              // 	}
              // })
            }}
            size="small"
            style={{
              width: 80,
            }}
          >
            {t("general.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (!value || !record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    // defaultFilteredValue: storageData[dataIndex].filter,
  });

  const columns = [
    {
      title: "",
      dataIndex: "image",
      align: "left",
      width: "5%",
      render: (_, { image, field1 }, index) => {
        return (
          <div
            className={`tableRowText border-2 rounded-full w-9 h-9 flex items-center justify-center  ${
              index % 2 === 1 ? "border-[#ffffff]" : "border-[#e9e9e9]"
            }`}
          >
            {" "}
            {image ? (
              <Image
                alt={field1}
                src={image}
                className="object-cover rounded-full"
                style={{ width: "35px", height: "35px" }}
              />
            ) : (
              <p> {field1?.substring(0, 2)?.toUpperCase()} </p>
            )}
          </div>
        );
      },
    },
    {
      title: t("companies.created_at"),
      dataIndex: "last_seen",

      sorter: (a, b) => Date.parse(a.last_seen) - Date.parse(b.last_seen),
      render: (last_seen) => {
        if (!last_seen) return <p className="tableRowText">Bulunamadı</p>;
        const dateNew = new Date(last_seen).getTime();
        return (
          <>
            <ReactTimeAgo
              className="tableRowText"
              date={dateNew}
              timeStyle="twitter"
              //   locales={["en-US", "tr-TR"]}
              locale={
                i18n.language === "tr"
                  ? "tr-TR"
                  : i18n.language === "da"
                  ? "da-DA"
                  : "en-US"
              }
            />
          </>
        );
      },
    },
    {
      title: t("general.isActive"),
      dataIndex: "is_active",
      ...getColumnSearchProps("is_active", [
        { value: true, label: t("general.active") },
        { value: false, label: t("general.passive") },
      ]),
      render: (text) => (
        <div className="tableRowText normal-case">
          {" "}
          <Tag color={text == true ? "#b2db9e" : "#cf132f"}>
            {text == true ? t("general.active") : t("general.passive")}
          </Tag>
        </div>
      ),
    },
    {
      title: t("companies.company_name"),
      dataIndex: "field1",

      ...getColumnSearchProps("field1"),
      render: (
        _,
        { deviceId, field1, image, branch, department, is_active }
      ) => (
        <div
          onClick={() => {
            if (profil?.userType == 0 || is_active === true) {
              navigate("/company");
              localStorage.setItem(
                "selectCompany",
                JSON.stringify({
                  branch: branch,
                  department: department,
                  image: image,
                  company_name: field1,
                  company_id: deviceId,
                })
              );
              setSelectCompany({
                branch: branch,
                department: department,
                image: image,
                company_name: field1,
                company_id: deviceId,
              });
            }
          }}
          className={`tableRowText capitalize ${
            (profil?.userType == 0 || is_active === true) &&
            "text-[#407BFF] cursor-pointer hover:text-[#009BDA] duration-300"
          }`}
        >
          {field1}
        </div>
      ),
    },
    // {
    //   title: t("companies.manager"),
    //   dataIndex: "field2",

    //   ...getColumnSearchProps("field2"),
    //   render: (text) => <div className="tableRowText">{text || ""}</div>,
    // },
    {
      title: t("crud.company.tax_number"),
      dataIndex: "field5",

      ...getColumnSearchProps("field5"),
      render: (text) => <div className="tableRowText">{text || ""}</div>,
    },
    {
      title: t("sidebar.branches"),
      dataIndex: "field3",

      render: (_, { branch, field3 }) => {
        return <div className="tableRowText">{branch ? field3 : "0"}</div>;
      },
      sorter: (a, b) => a.field3 - b.field3,
    },
    {
      title: t("general.staff_number"),
      dataIndex: "field4",

      render: (text) => (
        <div className="tableRowText uppercase">{text || 0}</div>
      ),
      sorter: (a, b) => a.field4 - b.field4,
    },

    {
      title: t("companies.licence"),
      dataIndex: "licence",
      render: (_, { licence, field4 }) => (
        <div className="tableRowText">
          {licence && (
            <div>
              {" "}
              <Tag
                color={
                  licence == 1
                    ? "green"
                    : licence == 5
                    ? "blue"
                    : licence == 15
                    ? "purple"
                    : "red"
                }
              >
                {licence == 1
                  ? t("license.beginner_text")
                  : licence == 5
                  ? t("license.standart_text")
                  : licence == 15
                  ? t("license.advanced_text")
                  : t("license.pro_text")}
              </Tag>
              <p>
                {field4 || 0}/{licence}
              </p>
            </div>
          )}
        </div>
      ),
      sorter: (a, b) => a.licence - b.licence,
    },
    {
      title: profil?.userType == 1 ? "" : t("general.actions"),
      dataIndex: "serial",
      width: "7%",
      render: (_, { field1, field2, deviceId, serial }) => {
        return (
          <div className="flex text-[#858F91] gap-3 items-center">
            <Tooltip title={t("companies.edit_company")}>
              <Link to={`edit_company/${deviceId}`} className="tableActionIcon">
                <EditIcon width={20} height={20} />
              </Link>
            </Tooltip>
            <Tooltip title={t("companies.delete_company")}>
              <Popconfirm
                title={() => <p className="capitalize"> {field1} </p>}
                description={t("general.confirm_delete")}
                onConfirm={() => handleDelete(deviceId)}
                placement="topLeft"
                icon={
                  <FaExclamationCircle
                    color="red"
                    className="mr-2 mt-[2px]"
                    size={18}
                  />
                }
                // okText="Sil"
                okType="danger"
              >
                <div className="tableActionIcon">
                  <TrashIcon />
                </div>
              </Popconfirm>
              {/* <div
                onClick={() => handleDelete(serial)}
                className="tableActionIcon"
              >
                <TrashIcon width={20} height={20} />
              </div> */}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title:
        profil?.userType == 1 ? (
          ""
        ) : (
          <div className="flex gap-1">
            <Tooltip title={t("companies.add_company")} placement="topLeft">
              <div className="bg-[#bdbdbd] p-1 rounded-lg hover:bg-[#cecccc] cursor-pointer">
                <Link to="add_company">
                  {/* <WifiIcon width={20} height={20} /> */}
                  <AddCompanyIcon width={23} height={23} />
                </Link>
              </div>
            </Tooltip>
          </div>
        ),
      dataIndex: "",
      width: "4%",
      // ...getColumnSearchProps("field2"),
      // render: () => <div className="text-base font-medium"></div>,
    },
  ];

  useEffect(() => {
    const data = columns.filter((item) => {
      return item?.title || item.dataIndex === "image";
    });
    const newColumns =
      profil.userType != 1 ? (isEditMode ? data : data.slice(0, -2)) : data;
    setTableData(newColumns);
    setTitle([t("sidebar.companies")]);
  }, [isEditMode, i18n.language]);

  return (
    <div ref={containerRef} className=" mt-5 pl-4 pr-5 h-full">
      <TableComponents
        title={false}
        bordered={false}
        loading={loading}
        size="large"
        pagination={false}
        columns={tableData}
        data={companiesList}
        // virtual
      />
    </div>
  );
};

export default Companies;
