import React from "react";

const TrashIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M22.109 4h-4.101a5.01 5.01 0 0 0-4.899-4h-2A5.009 5.009 0 0 0 6.21 4H2.109a1 1 0 1 0 0 2h.86l1.296 13.479A4.98 4.98 0 0 0 9.243 24h5.727a4.98 4.98 0 0 0 4.978-4.528L21.224 6h.886a1 1 0 1 0 0-2Zm-11-2h2c1.304 0 2.415.836 2.828 2H8.281a3.004 3.004 0 0 1 2.828-2Zm6.848 17.283A2.988 2.988 0 0 1 14.97 22H9.243a2.99 2.99 0 0 1-2.987-2.713L4.979 6h14.237l-1.259 13.283Zm-2.25-6.802a.999.999 0 1 1-1.414 1.414L13 12.602V17a1 1 0 1 1-2 0v-4.398l-1.293 1.293a.999.999 0 1 1-1.414-1.414l1.613-1.614a2.966 2.966 0 0 1 4.188 0l1.613 1.614Z"
          fill="currentColor"
          opacity="1"
          data-original="currentColor"
        ></path>
      </g>
    </svg>
  );
};

export default TrashIcon;
