import { Spin } from "antd";
import React from "react";

const CitySearchList = ({
  setFieldValue,
  cityList,
  setCityList,
  setCityPosition,
  setOpenList,
  loading,
}) => {
  const selectCity = (e) => {
    setOpenList(false);
    setCityList([]);
    setCityPosition([e.lat, e.lon]);
    setFieldValue("lat", e.lat);
    setFieldValue("long", e.lon);
  };

  return (
    <div className="p-3">
      {!loading ? (
        <>
          {cityList?.length > 0 ? (
            cityList?.map((e, index) => (
              <div
                key={index}
                onClick={() => selectCity(e)}
                className="flex gap-1 py-1 cursor-pointer"
              >
                {" "}
                <p> {e?.name} </p> - <p> {e?.display_name} </p>{" "}
              </div>
            ))
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="flex w-full justify-center mt-10 ">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default CitySearchList;
