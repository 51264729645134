import React from 'react';

const Agreement = () => {
  return (
    <div>
      <iframe src="/agreement.html" title="Agreement" style={{ width: '100%', height: '100vh', border: 'none' }} />
    </div>
  );
};

export default Agreement;
