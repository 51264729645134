import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { manuelLoginLogoutSchema } from "../../../helpers/FormSchemas/FormErrorMessage";
import { inits } from "../../../helpers/FormSchemas/FormSchemas";
import {
  DatePickerField,
  MultiSelectField,
} from "../../../helpers/Function/Function";
import { postOrPutRequestHttps } from "../../../libs/AllGetRequest/HttpsHelper";

const ManuelLoginForm = ({
  department,
  branch,
  setOpenModal,
  getPersonel,
  userId,
}) => {
  const { t } = useTranslation("global");
  const { selectCompany } = useNotificationContext();

  const sumbitFunction = (e, setSubmitting, reset) => {

    const formData = {
      user_id: userId,
      branch_id: e?.branch_id || null,
      department_id: e?.department_id || null,
      description: e?.description.trim("")
        ? e?.description.trim("")
        : e?.is_record_login
        ? "101"
        : "102",
      login_datetime: e?.is_record_login
        ? moment(e?.record_datetime).toISOString()
        : null,
      logout_datetime: e?.is_record_login
        ? null
        : moment(e?.record_datetime).toISOString(),
    };

    toast.promise(
      postOrPutRequestHttps("v1/work", "POST", formData, {
        company_id: selectCompany?.company_id,
      }),
      {
        pending: t("general.sending"),
        success: {
          onClose: () => {
            setOpenModal(false);
            getPersonel();
          },
          autoClose: 1000,
          render: t("general.send"),
        },
        error: t("super_admin.add_error"),
      }
    );

    setSubmitting(false);
  };

  const loginLogoutOptions = [
    { value: false, label: t("general.logout") },
    { value: true, label: t("general.login") },
  ];

  return (
    <div className=" bg-[#ffffff] flex flex-col items-center">
      <p className="font-semibold text-lg tracking-wider mb-5">
        {t("general.manuel_login")}
      </p>
      <Formik
        validationSchema={manuelLoginLogoutSchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={inits.manuelLogin}
      >
        
        {({ values, setFieldValue }) => {
          return (
            <Form
              className="flex flex-col h-full w-full justify-between"
              style={{ fontFamily: "Poppins" }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 xl:gap-x-30 gap-x-5">
                <div className="">
                  <label
                    htmlFor="is_record_login"
                    className="titleForm required"
                  >
                    {t("staff.status")}
                  </label>
                  <div>
                    <Field
                      name="is_record_login"
                      className="inputForm mt-2 px-2"
                      options={loginLogoutOptions}
                      component={MultiSelectField}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="is_record_login" />
                    </div>
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="record_datetime"
                    className="titleForm required"
                  >
                    {t("general.time")}
                  </label>
                  <div>
                    <Field
                      name="record_datetime"
                      className="inputForm mt-2 px-2"
                      options={loginLogoutOptions}
                      component={DatePickerField}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="record_datetime" />
                    </div>
                  </div>
                </div>
                {selectCompany?.branch && (
                  <div
                    className={
                      selectCompany?.department ? "col-span-1" : "col-span-2"
                    }
                  >
                    <label htmlFor="branch_id" className="titleForm">
                      {/* {selectCompany?.branch} */}
                      {t("staff.branch")}
                    </label>
                    <div>
                      <Field
                        name="branch_id"
                        className="inputForm mt-2 px-2"
                        optionFilterProp="label"
                        clear
                        options={branch}
                        component={MultiSelectField}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="branch_id" />
                      </div>
                    </div>
                  </div>
                )}

                {selectCompany?.department && (
                  <div>
                    <label htmlFor="department_id" className="titleForm">
                      {/* {selectCompany?.department} */}
                      {t("sidebar.department")}
                    </label>
                    <div>
                      <Field
                        name="department_id"
                        className="inputForm mt-2 px-2"
                        optionFilterProp="label"
                        disabled={values?.branch?.length < 1}
                        clear
                        options={department}
                        component={MultiSelectField}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="department_id" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="">
                  <label htmlFor="description" className="titleForm">
                    {t("general.description")}
                  </label>
                  <div>
                    <Field name="description" className="inputForm mt-2 px-2" />
                    <div className="text-red-600">
                      <ErrorMessage name="description" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-5">
                <button type="submit" className="xl:px-20 px-5 py-3 duration-300 relative flex submitButton">
                    {t("general.save")}
                  </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ManuelLoginForm;
