import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { LuLogOut } from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";
import { useNotificationContext } from "../../context/NotifacitionContext";
import { useAuthContext } from "../../context/UserContext";
import {
  BranchesIcon,
  CompanyIcon,
  DepartmentIcon,
  HomeIcon,
  MapIcon,
  MessageIconWithCount,
  PersonelIcon,
} from "../icons/Sidebar";
import SidebarHeader from "./SidebarHeader";
import SubscriptionIcon from "../icons/Sidebar/SubscriptionIcon";
import { ExcelReportIcon } from "../icons/General";
import { getRequestHttps, postOrPutRequestHttps } from "../../libs/AllGetRequest/HttpsHelper";

const SideBar = () => {
  const [t, i18n] = useTranslation("global");
  const { profil, logOut } = useAuthContext();
  const { selectCompany } = useNotificationContext();

  const [width, setWidth] = useState(window.innerWidth);
  const [link, setLink] = useState([]);
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];
  const [unreadMessage, setUnreadMessage] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  const getSize = () => {
    setWidth(window.innerWidth);
  };

  const fetchUnreadMessage = async () => {
    try {
        const response = await getRequestHttps(`v1/unreadcount`);
        console.log("Message unread: ", response);
        setUnreadMessage(response);
    } catch (error) {
        console.error('Error fetching unread messages count', error);
    }
};

  const markRead = async () => {
    try {
      await postOrPutRequestHttps(`v1/markread`);
      setUnreadMessage(0); //Mesajlar okundu, unReadMessage'ı sıfırla.
    } catch (error) {
      console.error('Error marking messages as read', error)
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getSize);
    if (width < 1200) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    return () => {
      window.removeEventListener("resize", getSize);
    };
  }, [window.innerWidth]);

  const menuItemStyles = {
    root: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#858F91",
    },
    icon: ({ active }) => ({
      filter: active ? "brightness(0) saturate(100%) invert(24%) sepia(88%) saturate(3180%) hue-rotate(231deg) brightness(93%) contrast(105%);" : "#858f91", // Seçili ikona özel renk
    }),
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    button: ({ active }) => ({
      width: "240px",
      padding: "8px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      borderRadius: "5px",
      fontWeight: active ? 700 : 500,
      backgroundColor: active ? "#A5ACF74D" : "transparent",
      borderLeft: active ? "6px solid #3248F2" : "none",
      boxShadow: active ? "0px 4px 4px 0px #00000040" : "none",
      "&:hover": {
        backgroundColor: "#A5ACF74D",
        borderLeft: "6px solid #3248F2",
        boxShadow: "0px 4px 4px 0px #00000040",
      },
    }),
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const sideBarElement = [
    {
      href: "companies",
      title: t("sidebar.companies"),
      image: <HomeIcon width={22} />,
      selected: false,
      tenant: true,
      personnel: false,
      show: true,
    },
    {
      href: "users",
      title: t("sidebar.users"),
      image: <PersonelIcon width={22} />,
      selected: false,
      tenant: true,
      personnel: false,
      show: profil.userType === 0 ? true : false,
    },
    {
      href: "company",
      title:
        profil?.isSuperAdmin === 0
          ? t("sidebar.company")
          : t("sidebar.dashboard"),
      image: <CompanyIcon width={22} />,
      selected: true,
      personnel: false,
      show: true,
    },
    {
      href: "branches",
      title: t("sidebar.branches"),
      // title: selectCompany?.branch,
      image: <BranchesIcon width={22} />,
      selected: true,
      personnel: false,
      show: selectCompany?.branch ? true : false,
    },
    {
      href: "department",
      title: t("sidebar.department"),
      // title: selectCompany?.department,
      image: <DepartmentIcon width={22} />,
      selected: true,
      personnel: false,
      show: selectCompany?.department ? true : false,
    },
    {
      href: "staff",
      title: t("sidebar.staff"),
      image: <PersonelIcon width={22} />,
      selected: true,
      personnel: true,
      show: true,
    },
    {
      href: "report",
      title: t("sidebar.report"),
      image: <ExcelReportIcon width={18} />,
      selected: true,
      personnel: false,
      show: true,
    },
    {
      href: "map",
      title: t("sidebar.map"),
      image: <MapIcon width={22} />,
      selected: true,
      personnel: false,
      show: true,
    },
    {
      href: "subscription",
      title: t("sidebar.subscription"),
      image: <SubscriptionIcon width={22} />,
      selected: true,
      personnel: false,
      show: true,
    },
    {
      href: "message",
      title: t("sidebar.message"),
      image: <MessageIconWithCount user_id={profil?.userId} width={22} />,
      // selected: true,
      personnel: true,
      show: profil.userType === 2 || profil.userType === 1,
      onClick: {markRead},
    }
  ];

  useEffect(() => {

    const user = JSON.parse(localStorage.getItem("user"));
    const RestrictedAccess = user?.restrictedAccess; // Backend'deki restrictedAccess (kısıtlı hesap) sınıfını frontend'e taşıdık.

    const superAdmin = sideBarElement.filter((e) => {

      return RestrictedAccess
        ? e.href === "subscription"
        : profil?.isSuperAdmin === 0
          ? selectCompany?.company_name
            ? e.selected
            : !e.selected
          : profil?.isSuperAdmin === 1
            ? RestrictedAccess
              ? e.href === "subscription"
              : !e.tenant
            : e.personnel;
    });

    const data = superAdmin?.filter((item) => {
      return item.show;
    });

    setLink(data);
  }, [i18n.language, selectCompany?.company_name]);

  return (
    <div className="flex h-screen sticky top-0 z-[99]">
      <div
        onClick={() => setCollapsed((prev) => !prev)}
        className={`flex justify-center items-center bg-[#FFFEFE] invisible  lg:visible border-[1px] p-2 absolute -right-3 z-50 rounded-md cursor-pointer hover:bg-gray-200 duration-200 ${collapsed ? " -right-1 top-[74px]" : "-right-3 top-[84px]"
          }`}
      >
        <IoIosArrowBack
          className={collapsed ? "rotate-180 duration-500 " : ""}
        />
      </div>
      <Sidebar
        backgroundColor="#f0f0f0"
        rootStyles={{ border: "none", width: collapsed ? "80px" : "320px"}}
        collapsed={collapsed}
        transitionDuration={500}
      >
        <div className="flex flex-col justify-between py-5 h-full">
          <div>
            {" "}
            <SidebarHeader collapsed={collapsed} />
            <div className="p-3 m-4" />
            <div className="flex flex-col">
              <div style={{ flex: 1, marginBottom: "32px" }}>
                <Menu menuItemStyles={menuItemStyles}>
                  {link?.map((item, key) => (
                    <MenuItem
                      rootStyles={{
                        color: pathName === item.href ? "#3248F2" : "",
                      }}
                      active={pathName === item.href}
                      key={key}
                      className={`${collapsed ? "capitalize" : "px-5 py-1 capitalize"
                        }`}
                      component={<Link to={item.href} />}
                      icon={
                        <Popover
                          placement="right"
                          content={
                            collapsed ? (
                              <p className="capitalize">{item.title}</p>
                            ) : (
                              ""
                            )
                          }
                        >
                          <div
                            className={
                              pathName === item.href
                                ? "text-[#407BFF]"
                                : "text-[#858f91]"
                            }
                          >
                            {item.image}
                          </div>
                        </Popover>
                      }
                    // icon={
                    //   <img
                    //     loading="lazy"
                    //     src={`/media/sidebar/menuLogo/${item.image}.png`}
                    //   />
                    // }
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </div>
          <div className={`flex ${collapsed ? "justify-center" : ""
            } relative pl-3 logout-icon cursor-pointer`}
            onClick={() => logOut()}>
          <Popover placement="bottomLeft" content={t("general.sign_out")}>
            <LuLogOut
              color="#acb3b5"
              className="w-5 h-5 hover:scale-105 duration-200"
            />
          </Popover>
          <div className="sidebar-text">Log out</div>
          </div>



          {/* {selectCompany && (
            <div
              className={`flex ${collapsed ? "justify-center" : ""
                } relative pl-3`}
            >
              {collapsed ? (
                <img
                  width={30}
                  loading="lazy"
                  src="/media/sidebar/isank_logo.png"
                />
              ) : (
                <div className="flex w-full items-center ml-10 gap-5">
                  <Link target="_blank" to={"http://tjekdag.dk/"}>
                    <img
                      width={130}
                      loading="lazy"
                      src="/media/login/tjekdag.svg"
                    />
                  </Link>
                </div>
              )}
            </div>
          )} */}
        </div>
      </Sidebar>
    </div>
  );
};

export default SideBar;
