import React from "react";

const PencilIcon = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 18.3333H19.25" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.125 3.20822C15.4897 2.84354 15.9843 2.63867 16.5 2.63867C16.7554 2.63867 17.0082 2.68897 17.2441 2.78669C17.4801 2.88441 17.6944 3.02765 17.875 3.20822C18.0556 3.38878 18.1988 3.60315 18.2965 3.83907C18.3942 4.07499 18.4445 4.32785 18.4445 4.58322C18.4445 4.83858 18.3942 5.09144 18.2965 5.32736C18.1988 5.56328 18.0556 5.77765 17.875 5.95822L6.41667 17.4165L2.75 18.3332L3.66667 14.6665L15.125 3.20822Z" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    

  );
};

export default PencilIcon;
