import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_da from "./translations/da/global.json";
import global_en from "./translations/en/global.json";
import global_tr from "./translations/tr/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lng") || "da",
  resources: {
    en: { global: global_en },
    tr: { global: global_tr },
    da: { global: global_da },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
