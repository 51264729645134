import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { getRequestHttps, postOrPutRequestHttps, registerCompanyHttps } from "../libs/AllGetRequest/HttpsHelper";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Tooltip, Image } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import CheckoutComponent from '../components/checkout/checkout-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import environment from '../environment/environment';
import BranchMap from '../components/Modules/Branches/BranchMap';
import CitySearchList from '../components/Modules/Branches/CitySearchList';
import { RxCross1 } from 'react-icons/rx';
import axios from 'axios';
import { Modal } from "react-bootstrap";
import { MailVerification } from './MailVerification.js'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  padding: 2rem;
  background: #F0F0F0;
`;

const Header = styled.h2`
  text-align: left;
  margin-bottom: 1rem;
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: 600;
  justify-content: between;
  display: flex;
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #A9A9A9B2;
  margin-bottom: 0;
`;

const FormWrapper = styled.div`
  background: rgba(255, 255, 255, 1);
  padding: 2rem;
  border-radius: 30px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

`;

const FormRow = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

const FormGroup = styled.div`
  flex: 1;
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
`;

const Label = styled.label`
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #1A1A1A;

    @media (max-width: 768px) {
  font-size:12px;
  }
    @media (max-width: 480px) {
  font-size:10px;
  }
`;

const Input = styled.input`
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #A9A9A9;
  height: 30px;
  box-shadow: 0px 2px 3px 0px #A9A9A980;
  width: 100%;
  outline: 0;
  margin-top: 8px;
`;

const CheckboxInput = styled.input`
  margin-right: 0.5rem;
`;

const Button = styled.button`
  width: 200px;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background-color: #0056b3;
  }

    @media (max-width: 480px) {
  align-self: center;
  }
`;

const SubscriptionSection = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const SubscriptionOption = styled.div`
  padding: 0.5rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#3248F2" : "transparent")};
  border: 2px solid ${(props) => (props.selected ? "#3248F2" : "#A9A9A9B2")};
  width: 100%;
  margin-right: 64px;
  &:hover {
    background-color: ${(props) => (props.selected ? "#0056b3" : "#f0f0f0")};
  }

  .subscription-label {
    color: ${(props) => (props.selected ? "#F5F5F5" : "#1A1A1A")};
    font-weight: 700;
    margin-top: 0.5rem;
    margin-left:0.25rem;
  }
  .subscription-price {
    color: ${(props) => (props.selected ? "#F5F5F5" : "#3248F2")};
    margin-left:0.25rem;
  }
  .subscription-info {
    color: ${(props) => (props.selected ? "#F5F5F5" : "#858F91")};
    margin-left:0.25rem;
  }

    .select-text {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => (props.selected ? "#3248F2" : "#F5F5F5")};
`;

const SelectButton = styled.div`
  background-color: ${(props) => (props.selected ? "#F5F5F5" : "#3248F2")};
  border-radius: 15px;
  height: 30px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  width:100%;
  }
`;

const PolicyText = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
`;

const Link = styled.a`
  color: blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;



const Register = () => {
  const [formData, setFormData] = useState({
    company_logo: '',
    company_name: '',
    company_description: '',
    company_address: '',
    department_label: '',
    branch_label: '',
    is_active: true,
    location_tracking: false,
    tax_number: '',
    max_stuff_count: '',
    manager_user_id: [],
    manager_name: '',
    manager_email: '',
    manager_password: '',
    manager_confirm_password: '',
    manager_phone: '',
    subscription_plan_id: '',
    lat_long: {
      lat: '',
      long: '',
      radius: 250,
    },
    origin_name: ''
  });

  const [station, setStation] = useState(0);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [cityPosition, setCityPosition] = useState([]);
  const [errors, setErrors] = useState({});
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMailVerification, setShowMailVerification] = useState(false);


  const handleShowMailVerification = () => setShowMailVerification(true);

  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = environment.netsDibspayment;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const fetcher = async (search) => {
    setLoading(true);
    const params = {
      q: search,
      format: "json",
      addressdetails: 1,
      polygon_geojson: 0,
    };
    const queryString = new URLSearchParams(params).toString();

    try {
      const data = await axios(`https://nominatim.openstreetmap.org/search?${queryString}`);
      setCityList(data?.data);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'lat' || name === 'long' || name === 'radius') {
      const updatedLatLong = {
        ...formData.lat_long,
        [name]: value, // Metin olarak sakla
      };

      setFormData(prevFormData => ({
        ...prevFormData,
        lat_long: updatedLatLong,
      }));

      // Lat veya Long değiştiğinde harita konumunu güncelle
      if (name === 'lat' || name === 'long') {
        const lat = parseFloat(updatedLatLong.lat.toString().replace(',', '.'));
        const long = parseFloat(updatedLatLong.long.toString().replace(',', '.'));
        if (!isNaN(lat) && !isNaN(long)) {
          setCityPosition([lat, long]);
        }
      }
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }

    if (name === 'subscription_plan') {
      setSelectedSubscription(value);
      let maxStuffCount;
      switch (parseInt(value)) {
        case 1:
          maxStuffCount = 1;
          break;
        case 2:
          maxStuffCount = 5;
          break;
        case 3:
          maxStuffCount = 15;
          break;
        case 4:
          maxStuffCount = 1000;
          break;
        default:
          maxStuffCount = '';
          break;
      }
      setFormData({ ...formData, subscription_plan_id: parseInt(value), max_stuff_count: maxStuffCount });
    }
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    setLoading(true); // Form gönderme işlemi başlıyor, loading durumu true olarak ayarlanıyor
    if (!agreementChecked) {
      toast.error("Du skal acceptere vilkårene og databehandleraftalen.");
      setLoading(false);  // Hata durumunda loading false olarak geri ayarlanıyor
      return;
    }

    const validationRules = [
      { field: 'manager_password', value: formData.manager_password === formData.manager_confirm_password ? formData.manager_confirm_password : '', message: 'Adgangskoder stemmer ikke overens' },
      { field: 'manager_password', value: formData.manager_password.length >= 6, message: 'Adgangskoden skal være på mindst 6 tegn' },
      { field: 'company_name', value: formData.company_name, message: 'Firmanavn er påkrævet' },
      { field: 'tax_number', value: formData.tax_number, message: 'Skattenummer er påkrævet' },
      { field: 'company_description', value: formData.company_description, message: 'Virksomhedsbeskrivelse er påkrævet' },
      { field: 'company_address', value: formData.company_address, message: 'Firmaadresse er påkrævet' },
      { field: 'lat', value: formData.lat_long.lat, message: 'Lat er påkrævet' },
      { field: 'long', value: formData.lat_long.long, message: 'Lang er påkrævet' },
      { field: 'radius', value: formData.lat_long.radius >= 50 && formData.lat_long.radius <= 250, message: 'Radius skal være mellem 50 og 250' },
      { field: 'manager_name', value: formData.manager_name, message: 'Managernavn er påkrævet' },
      { field: 'manager_email', value: formData.manager_email, message: 'Manager-e-mail er påkrævet' },
      { field: 'manager_password', value: formData.manager_password, message: 'Manageradgangskode er påkrævet' },
      { field: 'manager_confirm_password', value: formData.manager_confirm_password, message: 'Lederen bekræfter, at adgangskoden er påkrævet' },
      { field: 'subscription_plan_id', value: formData.subscription_plan_id, message: 'Abonnementsplan er påkrævet' },
    ];

    let validationErrors = {};

    for (const rule of validationRules) {
      if (!rule.value) {
        validationErrors[rule.field] = rule.message;
      }
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setLoading(false);
      return;
    }

    const companyControlData = {
      tax_number: formData.tax_number,
      manager_email: formData.manager_email,
    }

    try {
      const controlResponse = await postOrPutRequestHttps("v1/company-control", "POST", companyControlData);
      if (controlResponse.status === 400) {
        toast.error("CVR-nummer eller managermail findes allerede.");
        setLoading(false);
        return;
      } else {
        const registerCompany = await registerCompanyHttps("v1/register", "POST", formData);
        if (registerCompany) {
          setShowMailVerification(true);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("CVR-nummer eller managermail findes allerede.")
    } finally {
      setLoading(false);  // İşlem tamamlandıktan sonra loading false olarak geri ayarlanıyor
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleModelClose = async (result) => {
    setShowModal(false);
    if (result.isSucceeded) {
      const formDataToSubmit = {
        company_name: formData.company_name,
        company_description: formData.company_description,
        company_address: formData.company_address,
        tax_number: formData.tax_number,
        lat_long: formData.lat_long,
        is_active: formData.is_active,
        location_tracking: formData.location_tracking,
        subscription_plan_id: formData.subscription_plan_id,
        max_stuff_count: formData.max_stuff_count,
        manager_name: (station === 0 && formData.manager_name) || null,
        manager_email: (station === 0 && formData.manager_email) || null,
        manager_password: (station === 0 && formData.manager_password) || null,
        manager_phone: (station === 0 && formData.manager_phone) || null,
      };

      try {
        // Şirket oluşturma isteği
        const companyResponse = await postOrPutRequestHttps("v1/company-generate", "POST", formDataToSubmit);

        if (companyResponse && companyResponse.firm_id) {
          const companyId = companyResponse.firm_id;
          setCompanyId(companyId);

          // Şirket ID geçerli ise aboneliği oluştur
          const subscription = {
            PaymentTypeId: formData.subscription_plan_id,
            PaymentId: result.actionData.paymentId,
            SubscriptionId: uuidv4(),
            CompanyId: companyId,
            Mail: formDataToSubmit.manager_email,
            TaxNumber: formDataToSubmit.tax_number,
            CustomerName: formDataToSubmit.manager_name
          };

          const netsResponse = await postOrPutRequestHttps("subscription-get", "POST", subscription);
          if (netsResponse) {
            toast.success("Virksomheden blev tilføjet")
            navigate("/login");
          }
        } else {
          throw new Error("Ugyldigt firma-id");
        }
      } catch (error) {
        console.error("Fejl ved fuldførelse af processen", error);
        toast.error("Fejl ved fuldførelse af processen");
      }
    }
  };

  const handleCloseMailVerification = async (result) => {
    if (result) {
      setShowMailVerification(false); // Close modal only on successful verification

      const subscriptionData = {
        PaymentPlanId: formData.subscription_plan_id,
        IsUpdateCard: false,
        SubscriptionId: uuidv4(),
        OriginName: window.location.origin,
      };

      try {
        const subscriptionResponse = await toast.promise(
          postOrPutRequestHttps("subscription-create", "POST", subscriptionData),
          {
            pending: "Omdirigerer til abonnement...",
            success: "Omdirigering af abonnement lykkedes",
            error: "Fejl under omdirigering til abonnement",
          }
        );

        if (subscriptionResponse["paymentId"] !== "") {
          setVerificationCode(subscriptionResponse["paymentId"]);
          setShowModal(true);
        } else {
          throw new Error("Omdirigering af abonnement mislykkedes");
        }
      } catch (error) {
        console.error("Abonnementsdirigeringsfejl:", error);
        toast.error("Omdirigering af abonnement mislykkedes.");
      }
    } else {
      // Only show the error message, do not close the modal or redirect
      toast.error('E-mailbekræftelse mislykkedes. Prøv venligst igen.');
    }
  };

  const setFieldValue = (field, value) => {
    if (field === 'lat' || field === 'long' || field === 'radius') {
      setFormData(prevFormData => ({
        ...prevFormData,
        lat_long: {
          ...prevFormData.lat_long,
          [field]: value.toString(),
        }
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [field]: value,
      }));
    }
  };

  const subscriptionOptions = [
    {
      value: 1,
      minCount: 1,
      maxCount: 1,
      label: "Begynder",
      price_label: "18,75 DKK",
      info_label: "Max 1 bruger",
    },
    {
      value: 2,
      minCount: 2,
      maxCount: 5,
      label: "Standard",
      price_label: "62,5 DKK",
      info_label: "Max 5 bruger",
    },
    {
      value: 3,
      minCount: 6,
      maxCount: 15,
      label: "Advanceret",
      price_label: "112,5 DKK",
      info_label: "Max 15 bruger"
    },
    {
      value: 4,
      minCount: 16,
      maxCount: null,
      label: "Pro",
      price_label: "187,5 DKK",
      info_label: "16+ bruger"
    },
  ];

  const getCompanyByCoregNo = async (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      try {
        const data = await postOrPutRequestHttps("v1/GetCvrByCoregno", "GET", null, { coregno: e.target.value });
        if (data) {
          let companyData = JSON.parse(data.content);
          console.log(companyData);
          setFormData({
            ...formData,
            company_name: companyData.name || "",
            company_address: companyData.address || "",
            company_description: companyData.industrydesc || "",
            manager_email: companyData.email || "",
            manager_phone: companyData.phone || "",
            manager_name: companyData.owners[0].name || "",
          })
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (

    <Container>
      <FormWrapper className='registerInput' style={{ backgroundColor: "#000 !important", borderRadius: "20px" }}>
        <div className='row mb-2'>
          <div className='d-flex col justify-content-start align-items-center register-header'>
            <h2>
              Opret konto
            </h2>
          </div>
          <div className='d-flex col justify-content-end align-items-center'>
            <Image
              loading="lazy"
              className="object-cover rounded-full"
              preview={false}
              style={{
                width: "190px",
                height: "80px",
              }}
              src={"/media/login/tjekdag.svg"}
            />
          </div>
        </div>
        <Divider />
        <div className='row mt-3'>
          <div className='col-4'>
            <label className='titleForm'>CVR Nummer</label>
            <input className='inputForm px-2 mt-2' type="text" name="tax_number" value={formData.tax_number} onChange={handleChange} onKeyDown={getCompanyByCoregNo} />
            {errors.tax_number && <div className="text-red-600">{errors.tax_number}</div>}
          </div>
          <div className='col-4'>
            <label className='titleForm'>Virksomhedens Navn</label>
            <input className='inputForm px-2 mt-2' type="text" name="company_name" value={formData.company_name} onChange={handleChange} />
            {errors.company_name && <div className="text-red-600 text-sm">{errors.company_name}</div>}
          </div>
          <div className='col-4'>
            <label className='titleForm'>Branche</label>
            <input className='inputForm px-2 mt-2' type="text" name="company_description" value={formData.company_description} onChange={handleChange} />
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-4'>
            <label className='titleForm'>Adresse</label>
            <div className="relative">
              <input type="text" name="company_address" value={formData.company_address} onChange={handleChange} className="pr-20 inputForm px-2 mt-2" />
              <button
                type="button"
                disabled={formData.company_address.length < 3}
                onClick={() => {
                  setOpenList(true);
                  fetcher(formData.company_address);
                }}
                className="absolute top-2 rounded-r-lg right-0 w-20 items-center flex justify-center h-10 bg-gray-300 disabled:bg-gray-400 cursor-pointer"
              >
                Søg
              </button>
              <div className={`w-full bg-[#f3f2f2] absolute z-10 rounded-xl shadow-md duration-300 overflow-y-auto ${openList ? "h-40" : "h-0"}`}>
                <div className="absolute right-3 top-3">
                  <button
                    className="duration-300 text-[#858F91] cursor-pointer hover:text-[#407BFF]"
                    type="button"
                    onClick={() => setOpenList(false)}
                  >
                    <RxCross1 />
                  </button>
                </div>
                <CitySearchList
                  setFieldValue={setFieldValue}
                  cityList={cityList}
                  setCityList={setCityList}
                  setCityPosition={setCityPosition}
                  setOpenList={setOpenList}
                  loading={loading}
                />
              </div>
            </div>
          </div>
          <div className='col-2'>
            <label className='titleForm'>Breddegrad</label>
            <input className='inputForm px-2 mt-2' type="text" name="lat" value={formData.lat_long.lat} onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'lat' } })} />
          </div>
          <div className='col-2'>
            <label className='titleForm'>Længdegrad</label>
            <input className='inputForm px-2 mt-2' type="text" name="long" value={formData.lat_long.long} onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'long' } })} />
          </div>
          <div className='col-4'>
            <label className='titleForm'>Radius (meter)</label>
            <input className='inputForm px-2 mt-2' type="text" name="radius" value={formData.lat_long.radius} onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'radius' } })} />
            {errors.radius && <div className="text-red-600">{errors.radius}</div>}
          </div>
          <div className="w-full h-96">
            <BranchMap
              radius={formData.lat_long.radius}
              setCityPosition={setCityPosition}
              cityPosition={cityPosition}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-4'>
            <label className='titleForm'>Manager Navn</label>
            <input className='inputForm px-2 mt-2' type="text" name="manager_name" value={formData.manager_name} onChange={handleChange} />
          </div>
          <div className='col-4'>
            <label className='titleForm'>Manager Email</label>
            <input className='inputForm px-2 mt-2' type="email" name="manager_email" value={formData.manager_email} onChange={handleChange} />
          </div>
          <div className='col-4'>
            <label className='titleForm'>Telefonnummer</label>
            <input className='inputForm px-2 mt-2' type="text" name="manager_phone" value={formData.manager_phone} onChange={handleChange} />
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-4'>
            <label className='titleForm'>Adgangskode</label>
            <input className='inputForm px-2 mt-2' type="password" name="manager_password" value={formData.manager_password} onChange={handleChange} />
            {errors.manager_password && <div className="text-red-600">{errors.manager_password}</div>}
          </div>
          <div className='col-4'>
            <label className='titleForm'>Bekræft adgangskode</label>
            <input className='inputForm px-2 mt-2' type="password" name="manager_confirm_password" value={formData.manager_confirm_password} onChange={handleChange} />
            {errors.manager_confirm_password && <div className="text-red-600">{errors.manager_confirm_password}</div>}
          </div>

        </div>
        <div className='row mt-4'>
          <div className='col'>
            <label className='titleForm'>Pakkeløsninger</label>

            <div className="d-flex justify-content-start align-items-center mt-4">
              {subscriptionOptions.map((option) => (
                <Tooltip key={option.value} title={option.price_label}>
                  <SubscriptionOption
                    selected={selectedSubscription === option.value}
                    onClick={() => {
                      handleChange({
                        target: { name: 'subscription_plan', value: option.value }
                      });
                    }}
                  >
                    <CheckboxInput
                      type="radio"
                      name="subscription_plan"
                      value={option.value}
                      checked={formData.subscription_plan_id === option.value}
                      onChange={handleChange}
                      style={{ display: 'none' }}
                    />
                    <div className="flex-row text-left w-100">
                      <p className="subscription-label">
                        {option.label}
                      </p>
                      <p className="subscription-price">
                        {option.price_label}
                      </p>
                      <p className="subscription-info">
                        {option.info_label}
                      </p>
                      <SelectButton selected={selectedSubscription === option.value}>
                        <p className="select-text justify-content-center">
                          Select
                        </p>
                      </SelectButton>
                    </div>
                  </SubscriptionOption>
                </Tooltip>
              ))}
            </div>


          </div>
        </div>
        <div className='row mt-5'>
          <div className='col policy-text'>
            Vi vil behandle dine oplysninger i overensstemmelse med vores <Link style={{ color: "#52C41A" }} href="https://www.tjekdag.dk/privatlivspolitik/" target="_blank">privatlivspolitik.</Link>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-8 flex justify-content-start'>
            <CheckboxContainer>
              <CheckboxInput
                type="checkbox"
                name="agreement"
                checked={agreementChecked}
                onChange={() => setAgreementChecked(!agreementChecked)}
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "5px",
                  border: "1.5px solid #0B2232E5"
                }}
              />
              <span className='policy-text' style={{ fontSize: "16px" }}>
                Jeg har læst og accepterer <Link style={{ color: "#52C41A" }} href="/terms-of-use/" target="_blank">vilkårene</Link> og <Link style={{ color: "#52C41A" }} href="/agreement/" target="_blank">databehandleraftalen</Link>.
              </span>
            </CheckboxContainer>
          </div>
          <div className='col-4 flex justify-content-end'>
            <button className='xl:px-20 px-5 py-3 duration-300 relative flex submitButton-register' type="submit" onClick={handleSubmit} disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
          </div>
        </div>
        <CheckoutComponent
          verificationCode={verificationCode}
          showModal={showModal}
          handleClose={handleModelClose}
        />
        <Modal show={showMailVerification} onHide={() => toast.error('Email verification failed. Please try again.')}>
          <Modal.Header closeButton>
            <Modal.Title>Bekræft Mail</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <MailVerification email={formData.manager_email} onVerified={handleCloseMailVerification} />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => toast.error('Email verification failed. Please try again.')}> Close </Button>
          </Modal.Footer>
        </Modal>
      </FormWrapper>
    </Container>
  );
};

export default Register;