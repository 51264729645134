import ReactECharts from "echarts-for-react";
import React from "react";

const BarCharts = ({ title, className, yAxis, xAxis, height }) => {
  const options = {
    title: {
      text: title,
      left: "1%",
      textStyle: {
        overflow: "break",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Poppins",
        color: "#858F91"
      },
    },
    lang: {},
    xAxis: {
      type: "category",
      data: xAxis,
    },
    yAxis: {
      type: "value",
    },
    tooltip: {
      trigger: "axis",
    },
    dataZoom: [
      // {
      //   startValue: xAxis && xAxis[0],
      //   filterMode: "filter",
      // },
      {
        type: "inside",
      },
    ],
    visualMap: {
      top: 0,
      right: 5,
      pieces: [
        {
          gt: 0,
          lte: 7,
          color: "#9dd2e7",
        },
        {
          gt: 7,
          lte: 9,
          color: "#b2db9e",
        },

        {
          gt: 9,
          color: "#f39393",
        },
      ],
      outOfRange: {
        color: "#999",
      },
    },
    series: [
      {
        data: yAxis,
        type: "bar",
        itemStyle: {
          color: function (params) {
            return params?.value >= 7 && params?.value <= 8
              ? "#b2db9e"
              : params?.value >= 8
              ? "#f39393"
              : "#9dd2e7";
          },
          borderRadius: 3,
        },
      },
    ],
  };

  return (
    <div className={`relative shadow-none ${className} rounded-xl`}>
      <ReactECharts
        style={{ height: height }}
        // onEvents={onEvents}
        // ref={eChartsRef}
        lazyUpdate
        notMerge={true}
        option={options}
      />
    </div>
  );
};

export default BarCharts;
