import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CompanyHeader from "../components/Modules/Company/CompanyHeader";
import ManagerList from "../components/Modules/User/ManagerList";
import SuperAdminList from "../components/Modules/User/SuperAdminList";
import { useNotificationContext } from "../context/NotifacitionContext";
import { useAuthContext } from "../context/UserContext";
import { groupById } from "../helpers/Function/Function";
import { getRequestHttps } from "../libs/AllGetRequest/HttpsHelper";

const Users = () => {
  const { profil } = useAuthContext();
  const { setTitle, selectCompany } = useNotificationContext();
  const [t, i18n] = useTranslation("global");

  const [station, setStation] = useState(1);

  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    setTitle([t("sidebar.users")]);
  }, [i18n.language]);

  const tabsArray = [
    {
      id: 0,
      title: t("super_admin.super_admin"),
    },
    { id: 1, title: t("manager.company_manager") },
  ];

  const getCompanyList = useMemo(
    () => async () => {
      setLoading(true);
      let data;
      try {
        data = await getRequestHttps("v1/company");
        const companiesList = data?.map((e, i) => {
          return {
            name: e?.name,
            id: e?.id,
          };
        });
        setCompanyList(companiesList);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const getUsers = async () => {
    setLoading(true);
    let data;

    try {
      data = station
        ? await getRequestHttps("v1/user-managers", {}, "result")
        : await getRequestHttps(
            "v1/user",
            {
              is_list_sa: true,
            },
            "result"
          );

      const userList = data?.map((e, i) => {
        return {
          field1: e?.name_surname,
          field2: e?.email,
          company: e?.company_id,
          serial: e?.id,
          id: e?.id,
        };
      });

      const groupedData = station && groupById(data);

      station ? setUserList(groupedData) : setUserList(userList);
    } catch (e) {
      setUserList([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  const selectComponent = (data) => {
    switch (data) {
      case 0:
        return (
          <SuperAdminList
            getUsers={getUsers}
            loading={loading}
            data={userList}
          />
        );
      case 1:
        return (
          <ManagerList getUsers={getUsers} loading={loading} data={userList} />
        );
      default:
        return (
          <SuperAdminList
            getUsers={getUsers}
            loading={loading}
            data={userList}
          />
        );
    }
  };

  useEffect(() => {
    getUsers();
  }, [station]);

  return (
    <div className="flex flex-col h-full w-full items-center">
      <div className="flex sticky w-full">
        <CompanyHeader
          tabsArray={tabsArray}
          setStation={setStation}
          station={station}
        />
      </div>
      <div className="changePageAnimation z-0 w-full h-full">
        {/* <UserList
          loading={loading}
          data={userList}
          station={station}
          companyList={companyList}
          getUsers={getUsers}
        /> */}
        {selectComponent(station)}
      </div>
    </div>
  );
};

export default Users;
