import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {  postOrPutRequestHttps } from "../libs/AllGetRequest/HttpsHelper";
import '../styles/ResetPassword.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get("token");

  useEffect(() => {
    // Burada token'in geçerli olup olmadığını kontrol etmeniz gerekebilir
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
        const ResetPasswordRequest = {Token: token, Password: password};
        const response = await postOrPutRequestHttps("v1/reset-password","POST", ResetPasswordRequest);
        setSuccess("Password has been reset successfully.");
        navigate("/login"); // Başarılı şifre sıfırlamadan sonra yönlendirme
    } catch (err) {
      setError("Failed to reset password.");
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-box">
        <h2>Nulstil adgangskode</h2>
        <form onSubmit={handleSubmit}>
          <div className="password-input-container">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Ny adgangskode"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span 
              className="password-toggle-icon"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="password-input-container">
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="Bekræft adgangskode"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <span 
              className="password-toggle-icon"
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
            >
              {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <button type="submit">Nulstil adgangskode</button>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
