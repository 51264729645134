import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../../context/NotifacitionContext";
import { userLogType } from "../../../../helpers/Function/Function";
import TableComponents from "../../../Table/TableComponents";

const PersonelLogTable = ({ data }) => {
  const [t, i18n] = useTranslation("global");
  const { selectCompany } = useNotificationContext();

  const [tableColumn, setTableColumn] = useState("");

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const getColumnSearchProps = (dataIndex, selectOptions) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        {selectOptions && (
          <Select
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          >
            {selectOptions.map((option, key) => {
              return (
                <Select.Option key={key} value={`${option.value}`}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {!selectOptions && (
          <Input
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: selectedKeys},
              // 	}
              // })
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            onInput={() => {
              setSearchedColumn(dataIndex);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}
        <Space>
          <Button
            type="link"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: selectedKeys},
              // 	}
              // })
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("general.search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: ''},
              // 	}
              // })
            }}
            size="small"
            style={{
              width: 80,
            }}
          >
            {t("general.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (!value || !record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    // defaultFilteredValue: storageData[dataIndex].filter,
  });

  const columns = [
    {
      title: t("general.date"),
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      render: (last_seen) => {
        if (!last_seen) return <p className="tableRowText">Bulunamadı</p>;
        return (
          <div className="tableRowText">
            {" "}
            {moment(last_seen).format("DD-MM-YYYY HH:mm:ss")}
          </div>
        );
      },
    },
    {
      title: t("staff.event"),
      dataIndex: "event",
      ...getColumnSearchProps("event", [
        { value: 100, label: t("staff_log.user_create") },
        { value: 101, label: t("staff_log.password_changed") },
        { value: 102, label: t("staff_log.permission_request_sent") },
        { value: 103, label: t("staff_log.permission_denied") },
        { value: 104, label: t("staff_log.permission_accepted") },
        { value: 105, label: t("staff_log.wrong_area") },
        { value: 106, label: t("staff_log.branch_holiday") },
        { value: 107, label: t("staff_log.department_holiday") },
        { value: 108, label: t("staff_log.personel_holiday") },
        { value: 109, label: t("staff_log.personel_work_holiday") },
      ]),
      render: (text) => (
        <div className="tableRowText">{userLogType(text, t)}</div>
      ),
    },
  ];

  useEffect(() => {
    const data = columns.filter((item) => {
      return item?.title;
    });
    setTableColumn(data);
  }, [i18n.language]);

  return (
    <div className="overflow-y-auto h-full rounded-lg min-h-[300px]">
      <TableComponents
      title={() => (
        <div className="flex justify-between text-[#727f8b] font-medium">
          <p className="capitalize text-[#53626f] font-semibold text-base md:text-lg">
            {t("staff_detail.event")}
          </p>
          <p> {t("staff_detail.last_100")}</p>
        </div>
      )}
        bordered={false}
        scroll={
          data?.length > 4
            ? {
                y: 350,
              }
            : {}
        }
        //   loading={loading}
        size="large"
        pagination={false}
        columns={tableColumn}
        data={data}
        // virtual
      />
    </div>
  );
};

export default PersonelLogTable;
