import { createContext, useContext, useState } from "react";

const MainContext = createContext();

const AuthProvider = ({ children }) => {
  const [profil, setProfile] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [title, setTitle] = useState();

  const logOut = () => {
    localStorage.clear();
    setProfile();
  };

  const values = {
    profil,
    setProfile,
    logOut,
    title,
    setTitle,
  };

  return <MainContext.Provider value={values}>{children}</MainContext.Provider>;
};
const useAuthContext = () => useContext(MainContext);
export { AuthProvider, useAuthContext };
