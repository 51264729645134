import { Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PersonelHeader = ({ usedLeave, data }) => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="flex text-[#53626F] w-100">
      <div className="flex w-100">
        {" "}
        <div className="row w-100 personal-header">
          <div className="col-2">
            {data?.personnel_name && <p>{data?.personnel_name} </p>}
          </div>
          <div className="col-2">
            {data?.email && <p className="normal-case">{data?.email} </p>}
          </div>
          <div className="col-2">
            {data?.position && <p>{data?.position} </p>}
          </div>
          <div className="col-3">
            {data?.holiday ? (
              <div className="flex items-center gap-5">
                <p>
                  {" "}
                  {t("general.holiday_credit")} :{" "}
                  <span>{data?.holiday || "-"}</span>{" "}
                </p>{" "}
                {usedLeave >= 0 && (
                  <div className="flex gap-5">
                    {/* <p>
                      {t("general.used")} : <span> {usedLeave} </span>
                    </p> */}
                    <p>
                      {t("general.current")} :{" "}
                      <span> {data?.holiday - usedLeave} </span>
                    </p>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-1">
            {data?.branch && <p>{data?.branch} </p>}
          </div>
          <div className="col-1">
            {data?.department && <p>{data?.department} </p>}
          </div>
          <div className="col-1">
            <Tag color={data?.status ? "green" : "red"}>
              {data?.status ? "Login" : "LogOut"}
            </Tag>
          </div>
        </div>

      </div>
      {/* <div className="flex gap-5">
        {" "}
        <p>Total Work: 150h </p>
        <p>Total Break: 10h </p>
      </div> */}
    </div>
  );
};

export default PersonelHeader;
