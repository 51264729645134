import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ImportPersonel = () => {
  const [t] = useTranslation("global");

  const [excelFile, setExcelFile] = useState(null);

  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        toast.warning(t("general.excel_error"));
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  const handleFileSubmit = (e) => {
    console.log("e", e);
    e.preventDefault();
  };

  return (
    <div className="mt-5 ml-10 mr-5 p-5 bg-[#ffffff] flex flex-col">
      <form className="flex items-center" onSubmit={handleFileSubmit}>
        <input type="file" required onChange={handleFile} />
        <div className="flex justify-end flex-col">
          <button
            type="submit"
            className="bg-[#407BFF] font-semibold  text-white xl:px-10 px-5 py-1 rounded-xl hover:bg-[#3db5e4] duration-300 relative flex"
          >
            {t("general.upload")}
            {/* {loading ? (
                <AiOutlineLoading3Quarters className="ml-4 animate-spin absolute right-8 top-4" />
              ) : (
                ""
              )} */}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ImportPersonel;
