import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { DatePicker } from "../../../helpers/AntDesignPicker";
import ExcelExport from "../../../helpers/ExcelExport/excelExport";
import {
  calculateBreakHoursOverTime,
  calculateHoursOverTime,
  calculateTotalTime,
  formatTime,
  groupDataByDate,
  processLoginLogoutData,
  rangePresetsDevice,
} from "../../../helpers/Function/Function";
import { getRequestHttps } from "../../../libs/AllGetRequest/HttpsHelper";
import TableComponents from "../../Table/TableComponents";
import PersonelHeader from "./PersonelDetail/PersonelHeader";
import PersonelTimeHelper from "./PersonelReportHelper/PersonelTimeHelper";

const ExcelData = ({ data, language, company, userName, station }) => {
  const [t, i18n] = useTranslation("global");
  const [dataArr, setDataArr] = useState();

  const checkValues = (row, which) => {
    const normalWork = calculateTotalTime(row, which === 1 ? true : false);
    const overWork = calculateHoursOverTime(row);
    const breakOver = calculateBreakHoursOverTime(row, false);

    if (which === 1) {
      const values = normalWork;
      const breaksOver = breakOver;
      const overTime = overWork;
      const total = values - overTime - breaksOver;
      const result = total > 0 ? formatTime(total, t) : formatTime(0, t);

      return result;
    } else if (which === 2) {
      const values = overWork;
      const breaks = breakOver;
      const result =
        values > 0 ? formatTime(values - breaks, t) : formatTime(0, t);

      return result;
    } else if (which === 3) {
      const values = normalWork;
      const result = formatTime(values, t);
      return result;
    }
  };

  useEffect(() => {
    // const whichValue = await handleFetchData()
    const dataArr = data?.map((item) => {
      return station
        ? {
          lastSeen: moment(item.date).format("DD-MM-YYYY HH:mm"),
          field1: moment(item.login_time).format("HH:mm:ss"),
          field2: moment(item.logout_time).format("HH:mm:ss"),
          field3:
            item.status === "pending" ? language("staff_detail.break") : "",
          field4: item.limit_lower_ppm,
          field5: item.limit_upper_ppm,
        }
        : {
          lastSeen: moment(item[0]?.date).format("DD-MM-YYYY"),
          field1: checkValues(item, 1),
          field2: checkValues(item, 2),
          field3: checkValues(item, 3),
        };
    });

    setDataArr(dataArr);
  }, [data]);

  const staffReportColumnName = [
    {
      A: language("general.date"),
      B: language("staff_detail.login_time"),
      C: language("staff_detail.logout_time"),
      D: language("staff.status"),
      E: company?.branch,
      F: company?.department,
      // G: '',
      // H: '',
      // I: '',
      // J: '',
      // K: '',
    },
  ];

  const workingColumnName = [
    {
      A: language("general.date"),
      B: language("staff_detail.normal_work"),
      C: language("general.overtime"),
      D: language("staff_detail.break"),
    },
  ];

  return (
    <div className="border-0 d-flex justify-content-between align-items-center">
      <ExcelExport
        buggyColumnWidth={true}
        value={dataArr}
        tableOneColumn={station ? staffReportColumnName : workingColumnName}
        name={userName}
      />
    </div>
  );
};

const PersonelReport = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation("global");
  const { selectCompany } = useNotificationContext();

  const last5Days = moment().subtract(5, "d");
  const today = moment();
  const last5DaysFormat = last5Days.format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");
  const todayFormat = today.format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");

  const [disabledDates, setDisabledDates] = useState([last5Days, today]);
  const [date, setDate] = useState({
    start: last5DaysFormat,
    end: todayFormat,
  });

  const [excelData, setExcelData] = useState();
  const [personnelDetail, setPersonnelDetail] = useState();
  const [personnelData, setPersonnelData] = useState([]);

  const { RangePicker } = DatePicker;

  const onRangeChange = (dates, dateStrings) => {
    setDisabledDates(dates);
    if (dates) {
      setDate({
        end: moment(dateStrings[1]).format("YYYY-MM-DD HH:mm:ss.SSSSSS Z"),
        start: moment(dateStrings[0]).format("YYYY-MM-DD HH:mm:ss.SSSSSS Z"),
      });
    } else {
      setDate({ start: last5DaysFormat, end: todayFormat });
    }
  };

  const getDetailData = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/user-detail`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
      });

      const formValue = {
        personnel_name: data?.name_surname,
        email: data?.email,
        position: data?.work_title,
        branch: data?.branch_name,
        department: data?.department_name,
        phone: data?.phone,
        status: data?.is_record_login,
        hours: data?.hours,
        days: data?.days || [],
        holiday: data?.holiday_credit,
      };
      setPersonnelDetail(formValue);
    } catch (error) { }
  };

  const getWorkReport = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/work-report-userid`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
        limit: 1000,
        start_date: date.start,
        end_date: date.end,
      });

      data?.sort((a, b) => {
        return Date.parse(a.login_datetime) > Date.parse(b.login_datetime)
          ? -1
          : 1;
      });

      const values = processLoginLogoutData(data);
      // const groupData = groupByDate(data);
      const groupData = groupDataByDate(values);

      const dataArr = groupData
        ?.sort((a, b) => {
          return Date.parse(a.login_datetime) > Date.parse(b.login_datetime)
            ? -1
            : 1;
        })
        .map((item) => {
          const a = item.value.map((itemValue) => {
            return {
              created_at: item.date,
              date: itemValue?.login_datetime
                ? new Date(itemValue?.login_datetime)
                : null,
              login_time: itemValue?.login_datetime
                ? new Date(itemValue?.login_datetime).setSeconds(0)
                : "",
              logout_time: itemValue?.logout_datetime
                ? new Date(itemValue?.logout_datetime).setSeconds(0)
                : "",
              branch: itemValue?.branch_name,
              department: itemValue?.department_name,
              status_type: !itemValue?.logout_datetime ? false : true,
              shift: Math.floor(Math.random() * (14 - 1)) + 1,
              description: itemValue?.description,
              status: itemValue?.is_logout_break ? "pending" : true,
            };
          });
          return a;
        });

      // console.log("dataArr", dataArr);
      setPersonnelData(dataArr);

      const excelValue = data?.map((item) => {
        return {
          date: item?.login_datetime ? new Date(item?.login_datetime) : null,
          login_time: item?.login_datetime,
          logout_time: item?.logout_datetime,
          branch: item?.branch_name,
          department: item?.department_name,
          status_type: !item?.logout_datetime ? false : true,
          shift: Math.floor(Math.random() * (14 - 1)) + 1,
          description: item?.description,
          status: item?.is_logout_break ? "pending" : true,
        };
      });
      setExcelData(excelValue);
    } catch (error) {
      setPersonnelData([]);
      setExcelData([]);
      console.log("error", error);
    }
  };
  useEffect(() => {
    getDetailData(id);
    getWorkReport(id);
    // const x = setInterval(() => {
    //   getDetailData(id);
    //   getWorkReport(id);
    // }, 5000);
    // return () => clearInterval(x);
  }, [id, i18n.language, date]);

  const columns = [
    {
      title: t("general.date"),
      dataIndex: "date",
      width: "15%",
      render: (value, row, index) => {
        if (!row.length < 1 && !row[0]?.created_at)
          return <p className="tableRowText">Bulunamadı</p>;
        return (
          <div className="tableRowText">
            {moment(row[0]?.created_at).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      title: t("staff_detail.login_time"),
      dataIndex: "value",
      render: (value, row, index) => {
        return (
          <div className="tableRowText">
            <PersonelTimeHelper field={"login_time"} data={row} />
          </div>
        );
      },
    },
    {
      title: t("staff_detail.logout_time"),
      dataIndex: "value",
      render: (value, row, index) => {
        return (
          <div className="tableRowText">
            <PersonelTimeHelper field={"logout_time"} data={row} />
          </div>
        );
      },
    },
    {
      // title: selectCompany?.branch,
      title: selectCompany?.branch ? t("staff.branch") : null,
      dataIndex: "value",
      render: (value, row, index) => {
        return (
          <div className="tableRowText">
            <PersonelTimeHelper text field={"branch"} data={row} />
          </div>
        );
      },
    },
    {
      // title: selectCompany?.department,
      title: selectCompany?.department ? t("sidebar.department") : null,
      dataIndex: "value",
      render: (value, row, index) => {
        return (
          <div className="tableRowText">
            <PersonelTimeHelper text field={"department"} data={row} />
          </div>
        );
      },
    },
  ];

  const columnsWorking = [
    {
      title: t("general.date"),
      dataIndex: "date",
      width: "15%",
      render: (value, row, index) => {
        if (!row.length < 1 && !row[0]?.created_at)
          return <p className="tableRowText">Bulunamadı</p>;
        return (
          <div className="tableRowText">
            {moment(row[0]?.created_at).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      title: t("staff_detail.normal_work"),
      dataIndex: "",
      render: (value, row, index) => {
        const values = calculateTotalTime(row, true);
        const breaksOver = calculateBreakHoursOverTime(row, false);
        const overTime = calculateHoursOverTime(row);
        const total = values - overTime;

        const result = total > 0 ? formatTime(total, t) : formatTime(0, t);
        return <div className="tableRowText">{result} </div>;
      },
    },
    {
      title: t("general.overtime"),
      dataIndex: "value",
      render: (value, row, index) => {
        const values = calculateHoursOverTime(row);
        const breaks = calculateBreakHoursOverTime(row, false);
        const result =
          values > 0 ? formatTime(values - breaks, t) : formatTime(0, t);
        // console.log("row", row);
        return <div className="tableRowText">{result} </div>;
      },
      //   sorter: (a, b) => a.branch - b.branch,
    },
    {
      title: t("staff_detail.break"),
      dataIndex: "value",
      render: (value, row, index) => {
        const values = calculateTotalTime(row, false);
        const result = formatTime(values, t);
        return <div className="tableRowText">{result} </div>;
      },
      //   sorter: (a, b) => a.branch - b.branch,
    },
  ];

  const [station, setStation] = useState(0);

  const tabsArray = [
    { id: 0, title: t("staff_detail.working_detail") },
    {
      id: 1,
      title: t("staff_detail.personel"),
    },
  ];

  return (
    <div className="mt-5 pl-4 mr-5 flex flex-col">
      <div className="flex flex-col w-full">
        <div className="flex justify-between custom-border-b">
          <div className="flex text-sm md:text-base">
            {tabsArray?.map(({ id, title, image }, index) => (
              <div
                key={index}
                onClick={() => setStation(id)}
                className="flex flex-col items-center group mr-16"
              >
                <div className="flex items-center">
                  <p
                    className={`font-semibold group-hover:text-[#858f91b3] capitalize cursor-pointer ease-linear duration-300 ${id === station ? "text-[#858f91b3]" : "text-[#858f91b3]"
                      }`}
                    key={index}
                  >
                    {title}
                  </p>
                </div>

                <div
                  style={{ marginBottom: "-2px" }}
                  className={`w-[100%] ease-linear duration-300 group-hover:custom-border-b group-hover:border-[#009BDA] ${id === station ? "custom-border-a border-[#4B59EF] " : ""
                    }`}
                />
              </div>

            ))}
          </div>
        </div>
      </div>

      <div className="flex sticky capitalize top-0 z-50 w-full flex-col mt-5 ms-3">
        <PersonelHeader data={personnelDetail} />
      </div>
      <div className="row mt-5 mb-3">
      <div className="col-6 custom-select">
        <div className="flex items-center gap-3">
          <RangePicker
            showTime
            presets={rangePresetsDevice}
            defaultValue={[last5Days, today]}
            size="middle"
            format="YYYY-MM-DD HH:mm"
            onChange={onRangeChange}
          />
        </div>
      </div>
      <div className="d-flex col-6 justify-content-end">
        <div className="text-[#009BDA]">
          <ExcelData
            data={station ? excelData : personnelData}
            language={t}
            company={selectCompany}
            userName={personnelDetail?.personnel_name?.slice(0, 10)}
            station={station}
          />
        </div>
      </div>
      </div>




      {station == 1 && (
        <div className="bg-[#ffffff] w-full">
          <TableComponents
            // title={() => (
            //   <div className="flex justify-between items-center">
            //     <p className=" capitalize text-[#53626f] font-semibold text-base md:text-lg">
            //       {t("staff_detail.personel")}
            //     </p>
            //     <Tooltip title={t("general.download")} placement="topRight">
            //       <div className="tableActionIcon">
            //         <ExcelReportIcon width={20} />
            //       </div>
            //     </Tooltip>
            //   </div>
            // )}
            bordered
            scroll={
              personnelData?.length > 2
                ? {
                  y: 550,
                }
                : {}
            }
            size="large"
            pagination={false}
            columns={columns.filter((item) => {
              return item?.title;
            })}
            data={personnelData}
          // virtual
          />
        </div>
      )}
      {station == 0 && (
        <div className="bg-[#ffffff] w-full">
          <TableComponents
            bordered={false}
            scroll={
              personnelData?.length > 10
                ? {
                  y: 550,
                }
                : {}
            }
            size="large"
            pagination={false}
            columns={columnsWorking.filter((item) => {
              return item?.title;
            })}
            data={personnelData}
          // virtual
          />
        </div>
      )}
    </div>
  );
};

export default PersonelReport;
