import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNotificationContext } from "../../../../context/NotifacitionContext";
import { getRequestHttps } from "../../../../libs/AllGetRequest/HttpsHelper";
import { calculateDaysBetweenDates } from "../../../../helpers/Function/Function";
import { RxCheck, RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";

// LeaveInfo bileşeni
const LeaveInfo = () => {
  const [t] = useTranslation("global");
  const [companyLeaveList, setCompanyLeaveList] = useState([]);
  const { selectCompany } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  // İzin taleplerini getiren fonksiyon
  const getCompanyLeaveList = async () => {
    try {
      const data = await getRequestHttps(`v1/work-break`, {
        company_id: selectCompany?.company_id, // selectCompany'den gelen company_id'yi kullanıyoruz
        limit: 100,
        filterByCompanyOnly: true,
      });

      const dataArr = data?.map((item, i) => {
        return {
          ids: i,
          start_date: item?.start_date ? new Date(item?.start_date) : null,
          end_date: item?.end_date
            ? item?.end_date === item?.start_date
              ? new Date(item?.end_date)
              : moment(item?.end_date).subtract(1, "day")
            : null,
          day: calculateDaysBetweenDates(item?.start_date, item?.end_date),
          status: item?.status,
          name: item?.name_surname,
          description: item?.description,
          id: item?.id,
          user_id: item?.user_id,
          label_en: item?.label_en,
          label_tr: item?.label_tr,
          label_x: item?.label_x,
          break_label_id: item?.break_label_id,
        };
      });

      setCompanyLeaveList(dataArr);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching company leave list:", error);
      setLoading(false);
    }
  };

  const onApprove = (status, leaveId, userId) => {
    const formData = {
      id: +userId,
      status: status,
      company_id: selectCompany?.company_id,
    };

    console.log("formData", formData);

    toast.promise(
      getRequestHttps("v1/confirm-break", {
        company_id: selectCompany?.company_id,
        user_id: +userId,
        status: status,
        id: +leaveId,
        leave_type_id: null,
      }),
      {
        pending: t("general.sending"),
        success: {
          onClose: () => {
            getCompanyLeaveList();
          },
          autoClose: 1000,
          render: t("general.send"),
          // onClose: () => navigate(`/`),
        },
        error: t("super_admin.add_error"),
      }
    );
  };

  useEffect(() => {
    if (selectCompany) {
      getCompanyLeaveList();
    }
  }, [selectCompany]);

  return (
    <div className="flex flex-col h-full">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="w-full branch-odd-row">
          <thead className="text-[#858F91] text-base xxl:text-lg font-semibold text-left">
            <tr className="sticky table-top top-0 z-10 h-10 custom-border-b capitalize totalTexts">
              <th className="px-2">{t("general.personnel")}</th>
              <th className="px-2">{t("general.description")}</th>
              <th className="px-2">{t("general.start_date")}</th>
              <th className="px-2">{t("general.end_date")}</th>
              <th className="px-2">{t("staff.status")}</th>
            </tr>
            <div className="mt-2"></div>
          </thead>
          <tbody>
            {companyLeaveList && companyLeaveList.length > 0 ? (
              companyLeaveList.map((leave) => (
                <tr key={leave.id} className="text-left text-[#858F91] h-10 cursor-pointer">
                  <td className="py-1 px-2">{leave.name || "N/A"}</td>
                  <td className="py-1 px-2">{leave.description}</td>
                  <td className="py-1 px-2">
                    {leave.start_date ? moment(leave.start_date).format("DD-MM-YYYY") : "N/A"}
                  </td>
                  <td className="py-1 px-2">
                    {leave.end_date ? moment(leave.end_date).format("DD-MM-YYYY") : "N/A"}
                  </td>
                  <td className="py-1 px-2">
                    <div className="flex gap-3 tableRowText">
                      <RxCheck
                        onClick={() => onApprove(true, leave.id, leave.user_id)}
                        className="cursor-pointer hover:scale-125 duration-300"
                        color="green"
                        size={24}
                      />
                      <RxCross2
                        onClick={() => onApprove(false, leave.id, leave.user_id)}
                        className="cursor-pointer hover:scale-110 duration-300"
                        color="red"
                        size={24}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center py-4 text-lg text-gray-500">
                {t("general.leave_request")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
    // <div className="overflow-y-auto h-full rounded-lg min-h-[300px]">
    //   <Table
    //     dataSource={companyLeaveList}
    //     columns={columns}
    //     rowKey="id"
    //     pagination={false}
    //     scroll={{ y: 350 }}
    //   />
    // </div>
  );
};

export default LeaveInfo;
