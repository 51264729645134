import React from "react";
import { Route, Routes } from "react-router-dom";
import Personel from "../../pages/Personel";
import { AddPersonel, PersonelDetail } from "../Modules/Personel";
import ImportPersonel from "../Modules/Personel/ImportPersonel/ImportPersonel";
import PersonelReport from "../Modules/Personel/PersonelReport";
import CalendarWiev from "../Modules/Personel/PersonelWorkCalendar/PersonelWorkPlan/CalendarWiev";

const PersonelRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Personel />} />
      <Route path="/add_personel" element={<AddPersonel />} />
      <Route path="/edit_personel/:id" element={<AddPersonel />} />
      <Route path="/import_personel_list" element={<ImportPersonel />} />
      <Route path="/personel_detail/:id" element={<PersonelDetail />} />
      <Route path="/personel_report/:id" element={<PersonelReport />} />
      <Route path="/personel_work_plan/:id" element={<CalendarWiev />} />
    </Routes>
  );
};

export default PersonelRoutes;
