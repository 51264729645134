import React, { useEffect, useState } from 'react';
import { getRequestHttps } from '../../../libs/AllGetRequest/HttpsHelper';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import moment from 'moment'; // Moment.js kütüphanesiyle tarihleri formatlamak için

const SentMessages = ({ show, onCancel }) => {
    const [messages, setMessages] = useState([]);
    const [t, i18n] = useTranslation("global");
    const [visibleMessages, setVisibleMessages] = useState(5); // İlk etapta görüntülenecek mesaj sayısı

    const fetchMessages = async () => {
        try {
            const response = await getRequestHttps(`v1/sent-messages`);
            setMessages(response);
            console.log("Gönderilen mesaj: ", response);
            console.log("Mesajlar: ", messages)
        } catch (error) {
            console.error('Gönderilen mesajlar alınırken bir hata oluştu:', error);
        }
    }

    const handleShowMore = () => {
        setVisibleMessages(prevVisible => prevVisible + 5); // 5 mesaj daha göster
    }

    return (
        <Modal show={show} onHide={onCancel} onShow={fetchMessages}>
            <Modal.Header closeButton>
                <Modal.Title>{t("general.sent_messages")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {messages && messages.length > 0 ? (
                    <>
                        <ul className="list-group">
                            {messages.slice(0, visibleMessages).map(msg => (
                                <li key={msg.id} className="list-group-item">
                                    <small className="text-muted">
                                        {moment(msg.sent_at).format('YYYY-MM-DD HH:mm:ss')} {/* Tarih formatlama */}
                                    </small>
                                    <br />
                                    <strong>{`${msg.receiver_name}`}</strong>: {msg.message}
                                </li>
                            ))}
                        </ul>
                        {visibleMessages < messages.length && ( // Daha fazla mesaj varsa butonu göster
                            <Button variant="link" onClick={handleShowMore}>
                                {t("general.show_more")}
                            </Button>
                        )}
                    </>
                ) : (
                    <p>{t("general.sent_messages_result")}</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button type="secondary" onClick={onCancel}>{t("general.close")}</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default SentMessages;