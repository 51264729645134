import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Select, Space, Tag, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import TableComponents from "../../Table/TableComponents";
import {
  PlusIcon,
  CalendarIcon,
  EditIcon,
  TrashIcon,
} from "../../icons/General";

const DepartmentHome = ({ data, loading, handleDelete }) => {
  const [t, i18n] = useTranslation("global");
  const { isEditMode, selectCompany } = useNotificationContext();

  const [tableColumn, setTableColumn] = useState("");

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const getColumnSearchProps = (dataIndex, selectOptions) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        {selectOptions && (
          <Select
            ref={searchInput}
            placeholder={t("general.reset")}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          >
            {selectOptions.map((option, key) => {
              return (
                <Select.Option key={key} value={`${option.value}`}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {!selectOptions && (
          <Input
            ref={searchInput}
            placeholder={t("general.reset")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: selectedKeys},
              // 	}
              // })
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            onInput={() => {
              setSearchedColumn(dataIndex);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}
        <Space>
          <Button
            type="link"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: selectedKeys},
              // 	}
              // })
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("general.search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
              // setStorageData((prev) => {
              // 	return {
              // 		...prev,
              // 		[dataIndex]: {...prev[dataIndex], filter: ''},
              // 	}
              // })
            }}
            size="small"
            style={{
              width: 80,
            }}
          >
            {t("general.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (!value || !record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    // defaultFilteredValue: storageData[dataIndex].filter,
  });

  const columns = [
    {
      // title: selectCompany?.department,
      title: selectCompany?.department ? t("sidebar.department") : null,
      dataIndex: "title",
      ...getColumnSearchProps("title"),
      render: (_, { id, title }) => (
        <div className="py-3 tableRowText">{title}</div>
      ),
    },
    {
      title: t("general.description"),
      dataIndex: "description",
      render: (text) => <div className="py-3 tableRowText">{text}</div>,
    },
    {
      title: t("staff.personnel_total"),
      dataIndex: "total",
      render: (text) => {
        return (
          <div className="tableRowText">
            <span className="w-2 h-2 rounded-full inline-block mr-2" style={{backgroundColor:'#3248F2', marginBottom:"2px"}}></span>
            {text >= 0 ? (
              <span>
                {text}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: t("general.login"),
      dataIndex: "login",
      render: (login) => {
        return (
          <div className="tableRowText">
            <span className="w-2 h-2 rounded-full inline-block mr-2" style={{backgroundColor:'#52C41A', marginBottom:"2px"}}></span>
            {login >= 0 ? (
              <span>
                {login}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
      sorter: (a, b) => a.login - b.login,
    },
    {
      // title: selectCompany?.branch,
      title: selectCompany?.branch ? t("sidebar.branches") : null,
      dataIndex: "branch",
      ...getColumnSearchProps("branch"),
      render: (branch) => {
        return <div className="tableRowText">{branch}</div>;
      },
    },
    // {
    //   title: t("companies.manager"),
    //   dataIndex: "manager",
    //   width: "30%",
    //   ...getColumnSearchProps("manager"),
    //   render: (manager) => (
    //     <div className="tableRowText capitalize flex">
    //       {manager?.map((m, i) => (
    //         <p key={i}>
    //           {m}
    //           <span className={manager.length - 1 === i ? "hidden" : ""}>
    //             ,
    //           </span>
    //         </p>
    //       ))}
    //     </div>
    //   ),
    // },
    {
      title: t("general.actions"),
      dataIndex: "id",
      width: "7%",
      render: (_, { title, field1, manager, id, branch }) => {
        return (
          <div className="flex text-[#858F91] gap-3 items-center">
            <Tooltip title={`${t("general.calendar")}`}>
              <div>
                <Link className="tableActionIcon" to={`work_plan/${id}`}>
                  <CalendarIcon width={20} height={20} />
                </Link>
              </div>
            </Tooltip>
            <Tooltip title={`${t("general.edit")} ${title}`}>
              <Link
                to={`edit_department/${id}`}
                state={{ department: title, branch: branch, manager: manager }}
                className="tableActionIcon"
              >
                <EditIcon width={20} height={20} />
              </Link>
            </Tooltip>
            <Tooltip title={`${t("general.delete")} ${title}`}>
              <Popconfirm
                title={() => <p className="capitalize"> {title} </p>}
                description={t("general.confirm_delete")}
                onConfirm={() => handleDelete(id)}
                placement="topLeft"
                icon={
                  <FaExclamationCircle
                    color="red"
                    className="mr-2 mt-[2px]"
                    size={18}
                  />
                }
                // okText="Sil"
                okType="danger"
              >
                <div className="tableActionIcon">
                  <TrashIcon />
                </div>
              </Popconfirm>
              {/* <div
                onClick={() => handleDelete(serial)}
                className="tableActionIcon"
              >
                <TrashIcon width={20} height={20} />
              </div> */}
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   dataIndex: "id",
    //   width: "2%",
    //   title: (
    //     <Tooltip
    //       // title={`${t("general.add")} ${selectCompany?.department}`}
    //       title={t("department.add_department")}
    //       placement="topLeft"
    //     >
    //       <Link to="add_department">
    //         <div className="text-[#eaeaea] bg-[#bdbdbd] p-1 rounded-lg hover:bg-[#cecccc] cursor-pointer hover:text-[#f7f4f4]">
    //           <AddCompanyIcon width={23} height={23} />
    //         </div>
    //       </Link>
    //     </Tooltip>
    //   ),
    //   dataIndex: "",
    //   width: "1%",
    //   // ...getColumnSearchProps("field2"),
    //   // render: () => <div className="text-base font-medium"></div>,
    // },
  ];

  useEffect(() => {
    const data = columns.filter((item) => {
      return item?.title;
    });
    const newColumns = isEditMode ? data : data.slice(0, -2);
    setTableColumn(newColumns);
  }, [isEditMode, i18n.language]);

  return (
    <div className="mt-5 pr-5">
      {" "}
      <Tooltip title={t("department.add_department")} placement="topLeft">
        <div
          role="button"
          className="addButton bg-[#3248F2] row p-1 rounded-lg cursor-pointer"
        >
          <Link to="add_department" className="linkref">
          <label className="buttonLabel cursor-pointer">{t("department.add_department")} </label>
          <div className="addIcon cursor-pointer">
            <PlusIcon width={12} height={12} />
          </div>
          </Link>
        </div>
      </Tooltip>
      <TableComponents
        title={false}
        bordered={false}
        // scroll={{
        //   x: 1260,
        // }}
        loading={loading}
        size="large"
        pagination={false}
        columns={tableColumn}
        data={data}
        // virtual
      />
    </div>
  );
};

export default DepartmentHome;
