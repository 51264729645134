import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const PersonelTimeHelper = ({ text = false, data, field }) => {
  const { t } = useTranslation("global");

  const addBreakWords = (index) => {
    const value =
      (index > 0 &&
        field === "login_time" &&
        data[index - 1].status === "pending") ||
      (field === "logout_time" && data[index].status === "pending");
    return value;
  };

  return (
    <div className="flex flex-col gap-1">
      {data.map((item, index) => {
        return text ? (
          <div key={item.login_time}>
            {" "}
            {/* {item[field] || t("general.no_record")}{" "} */}
            {item[field] || "-"}{" "}
          </div>
        ) : (
          <div key={item.login_time}>
            {item[field] ? moment(item[field]).format("HH:mm") : "-"}
            {addBreakWords(index) && ` - ${t("staff_detail.break")}`}
            {/* {item.status === "pending" ? ` - ${t("staff_detail.break")}` : ""} */}
          </div>
        );
      })}
    </div>
  );
};

export default PersonelTimeHelper;
