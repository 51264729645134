import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { getRequestHttps } from '../../../libs/AllGetRequest/HttpsHelper';

const MessageIconWithCount = ({ user_id }) => {
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        const fetchUnreadCount = async () => {
            try {
                const response = await getRequestHttps(`v1/unreadcount?user_id=${user_id}`)
                console.log("Icon unread: ", response);
                setUnreadCount(response);
            } catch (error) {
                console.error('Error fetching unread messages count', error);
            }
        };

        fetchUnreadCount();
    }, [user_id]);

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <FontAwesomeIcon icon={faEnvelope} size="lg" />
            {unreadCount > 0 && (
                <span style={{
                    position: 'absolute',
                    top: '-10px', // Yukarıda biraz daha düzgün görünmesi için ayarlandı
                    right: '-10px', // Sağda hizalanması için ayarlandı
                    background: 'red',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '0 5px', // Padding küçültüldü, yuvarlak rozetin boyutunu ayarlamak için
                    fontSize: '10px', // Yazı boyutu küçültüldü
                    minWidth: '20px', // Genişlik sayıya göre otomatik ayarlanır
                    height: '20px', // Yükseklik sabitlendi
                    display: 'flex', // İçerik ortalanması için
                    alignItems: 'center', // Yatayda ortalanması için
                    justifyContent: 'center', // Dikeyde ortalanması için
                }}>
                    {unreadCount}
                </span>
            )}
        </div>
    );
};

export default MessageIconWithCount;