import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { addLeavesSchema } from "../../../../helpers/FormSchemas/FormErrorMessage";
import { inits } from "../../../../helpers/FormSchemas/FormSchemas";
import { postOrPutRequestHttps } from "../../../../libs/AllGetRequest/HttpsHelper";

const CompanyLeaveForm = ({ getLeaveList, selectCompany, setOpenModal }) => {
  const { t } = useTranslation("global");

  const sumbitFunction = (e, setSubmitting, reset) => {
    const formData = {
      ...e,
      label_tr: e?.label_tr,
      label_x: e?.label_x
    };

    // console.log("formData", formData);
    // setManuelCheck(false);
    toast.promise(
      postOrPutRequestHttps("v1/break-labels", "POST", formData, {
        company_id: selectCompany?.company_id,
      }),
      {
        pending: t("crud.general.adding"),
        success: {
          onClose: () => {
            getLeaveList();
            setOpenModal(false);
            reset();
          },
          autoClose: 1000,
          render: t("crud.general.added"),
          // onClose: () => navigate(`/`),
        },
        error: t("super_admin.add_error"),
      }
    );

    setSubmitting(false);
  };

  return (
    <div className=" bg-[#ffffff] flex flex-col items-center">
      <p className="font-semibold text-lg tracking-wider mb-5">
        {t("general.manuel_login")}
      </p>
      <Formik
        validationSchema={addLeavesSchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={inits.addLeaves}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form
              className="flex flex-col h-full w-full justify-between"
              style={{ fontFamily: "Poppins" }}
            >
              <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 xl:gap-x-30 gap-x-5">
                <div className="">
                  <label htmlFor="label_en" className="formTitle">
                    {t("language.en")}
                  </label>
                  <div>
                    <Field name="label_en" className="formInput" />
                    <div className="text-red-600">
                      <ErrorMessage name="label_en" />
                    </div>
                  </div>
                </div>
                <div className="">
                  <label htmlFor="label_x" className="formTitle">
                    {t("language.da")}
                  </label>
                  <div>
                    <Field name="label_x" className="formInput" />
                    <div className="text-red-600">
                      <ErrorMessage name="label_x" />
                    </div>
                  </div>
                </div>
                <div className="">
                  <label htmlFor="label_tr" className="formTitle">
                    {t("language.tr")}
                  </label>
                  <div>
                    <Field name="label_tr" className="formInput" />
                    <div className="text-red-600">
                      <ErrorMessage name="label_tr" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-5">
                <button
                  type="submit"
                  className="bg-[#407BFF] font-semibold text-base xl:text-lg text-white xl:px-20 px-5 py-3 rounded-xl hover:bg-[#3db5e4] duration-300 relative flex"
                >
                  {t("general.save")}
                  {/* {loading ? (
              <AiOutlineLoading3Quarters className="ml-4 animate-spin absolute right-8 top-4" />
            ) : (
              ""
            )} */}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CompanyLeaveForm;
