import React from "react";
import { useNotificationContext } from "../../../../context/NotifacitionContext";

const AppointmentEvent = ({ value, onDoubleClick }) => {
  const { selectCompany } = useNotificationContext();

  return (
    <div
      // style={{ backgroundColor: value?.data?.color }}
      // className={`px-2 h-full my-1 `}
      onDoubleClick={() => onDoubleClick(value)}
    >
      {value?.company_name && (
        <div>
          <p style={{ backgroundColor: "gray" }}
            className={`px-2 h-full my-1 `}>{value?.company_name}</p>
        </div>
      )}
      {selectCompany?.branch && (
        <div>
          <p style={{ backgroundColor: "DodgerBlue" }}
            className={`px-2 h-full my-1 `}>{value?.branch_name}</p>
        </div>
      )}
      {selectCompany?.department && (
        <div>
          <p style={{ backgroundColor: "MediumSeaGreen" }}
            className={`px-2 h-full my-1 `}>{value?.department_name}</p>
        </div>
      )}
      <div>
        <p style={{ backgroundColor: "orange" }} className={`px-2 h-full my-1 `}>{value?.description}</p>
      </div>
    </div>
  );
};

export default AppointmentEvent;
