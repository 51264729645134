import { Button, Input, Tooltip, Select, Space, Tag, Modal } from "antd";
import { DatePicker } from "../../../../helpers/AntDesignPicker";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../../context/NotifacitionContext";
import TableComponents from "../../../Table/TableComponents";
import { SearchOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { deleteRequestHttps, postOrPutRequestHttps } from "../../../../libs/AllGetRequest/HttpsHelper";
import { useParams } from "react-router-dom";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';

const PersonelDetailTable = ({ data, getPersonel, userId }) => {

  const [t, i18n] = useTranslation("global");
  const { selectCompany } = useNotificationContext();

  const [tableColumn, setTableColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [personnelDetail, setPersonnelDetail] = useState(null);
  const { id } = useParams();
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const getColumnSearchProps = (dataIndex, selectOptions) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {selectOptions && (
          <Select
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{ marginBottom: 8, display: "block" }}
          >
            {selectOptions.map((option, key) => {
              return (
                <Select.Option key={key} value={`${option.value}`}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {!selectOptions && (
          <Input
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            onInput={() => {
              setSearchedColumn(dataIndex);
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button
            type="link"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("general.search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 80 }}
          >
            {t("general.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!value || !record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  });

  const changeLanguageDescription = (value) => {
    const expressionsArray = value.split(" ");

    const checkValues = {
      101: t("general.login"),
      102: t("general.logout"),
      103: t("staff_detail.break"),
    };

    let output = [];

    expressionsArray.forEach((value) => {
      if (checkValues.hasOwnProperty(value)) {
        output.push(checkValues[value]);
      } else {
        output.push(value);
      }
    });

    return output.join(" - ");
  };

  const handleRowClick = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleDateChange = (date, dateString, type) => {
    setSelectedRecord((prev) => ({
      ...prev,
      [type]: dateString ? moment(dateString).toISOString() : null
    }));
  };

  const handleOk = (e) => {
    console.log("Selected Record:", selectedRecord);
    console.log("login_date: ", selectedRecord?.date);
    console.log("logout_date: ", selectedRecord?.logout_time)

    const updateForm = {
      id: selectedRecord?.id,
      user_id: +id,
      login_datetime: selectedRecord?.date,
      logout_datetime: selectedRecord?.logout_time,
      updated_at: e?.updated_at,
      updater_id: e?.updater_id,
    };
    console.log("updateForm: ", updateForm)

    // Güncelleme isteğini gönder
    toast.promise(
      postOrPutRequestHttps("v1/work-update", "PUT", updateForm, {
        company_id: selectCompany?.company_id,
      }),
      {
        pending: t("crud.general.updating"),
        success: {
          onClose: () => {
            setIsModalVisible(false); // Modalı kapat
            // getPersonel();
          },
          autoClose: 1000,
          render: t("crud.general.updated"),
        },
        error: t("super_admin.add_error"),
      }
    );
    setIsModalVisible(false);
  };

  const handleCancel = async () => {
    setIsModalVisible(false)
  }

  const handleDelete = () => {
    if (selectedRecord?.id) {
      toast.promise(
        deleteRequestHttps(
          `v1/work-delete`,
          {},
          {
            company_id: selectCompany?.company_id,
            id: selectedRecord.id,
          }
        ),
        {
          pending: t("crud.general.deleting"),
          success: {
            onClose: () => {
              setIsModalVisible(false);
            },
            autoClose: 1000,
            render: t("crud.general.deleted"),
          },
          error: t("super_admin.add_error"),
        }
      );
    } else {
      toast.error(t("general.error_invalid_id"));
    }
  };

  const columns = [
    {
      title: t("general.login_date"),
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      render: (_, { date, break_login }) => {
        if (!date) return <p className="tableRowText">Bulunamadı</p>;
        return (
          <div className="tableRowText">
            {break_login ? (
              <p>
                {" "}
                <Tag color="yellow">{t("staff_detail.break")}</Tag>
              </p>
            ) : (
              ""
            )}
            {moment(date).format("DD-MM-YYYY HH:mm:ss")}
          </div>
        );
      },
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
        style: { cursor: "pointer" }, // Tıklanabilirlik imleci
      }),
    },
    {
      title: selectCompany?.branch ? t("staff.branch") : null,
      dataIndex: "branch",
      ...getColumnSearchProps("branch"),
      render: (text) => <div className="tableRowText">{text}</div>,
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
        style: { cursor: "pointer" },
      }),
    },
    {
      title: selectCompany?.department ? t("sidebar.department") : null,
      dataIndex: "department",
      ...getColumnSearchProps("department"),
      render: (text) => <div className="tableRowText">{text}</div>,
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
        style: { cursor: "pointer" },
      }),
    },
    {
      title: t("general.description"),
      dataIndex: "description",
      render: (text) => {
        const data = changeLanguageDescription(text);
        return <div className="tableRowText">{data}</div>;
      },
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
        style: { cursor: "pointer" },
      }),
    },
    {
      title: t("staff.status"),
      dataIndex: "status",
      render: (_, { status, logout_time }) => (
        <div className="tableRowText">
          <Tag
            color={
              status == true ? "green" : status == "pending" ? "yellow" : "red"
            }
          >
            {status == true
              ? t("general.login")
              : status == "pending"
                ? t("staff_detail.break")
                : t("general.logout")}
          </Tag>
          {logout_time && (
            <p className="text-sm">
              {" "}
              {moment(logout_time).format("DD-MM-YYYY HH:mm:ss")}
            </p>
          )}
        </div>
      ),
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
        style: { cursor: "pointer" },
      }),
    },
  ];

  useEffect(() => {
    const data = columns.filter((item) => {
      return item?.title;
    });
    setTableColumn(data);
  }, [i18n.language]);

  return (
    <div className="overflow-y-auto h-full min-h-[300px] rounded-lg">
      <TableComponents
        title={() => (
          <p className="capitalize text-[#53626f] font-semibold text-base md:text-lg">
            {t("staff_detail.session")}
          </p>
        )}
        bordered={false}
        scroll={
          data?.length > 4
            ? {
              y: 350,
            }
            : {}
        }
        size="large"
        pagination={false}
        columns={tableColumn}
        data={data}
      />

      <Modal
        title={t("general.edit_dates")}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={
          <>
            <Button
              key="delete"
              type="primary"
              icon={<DeleteOutlined />}
              onClick={handleDelete}
              style={{ backgroundColor: "red", borderColor: "red", color: "white" }} // Kırmızı buton
            >
              {t("general.delete")}
            </Button>
            <Button
              key="submit"
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleOk}
              style={{ backgroundColor: "blue", borderColor: "blue", color: "white" }} // Mavi buton
            >
              {t("general.save")}
            </Button>
          </>
        }
      >
        <div>
          <p>{t("general.login")}</p>
          <DatePicker
            showTime
            value={selectedRecord?.date ? moment(selectedRecord.date) : null}
            onChange={(date, dateString) =>
              handleDateChange(date, dateString, "date")
            }
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <p>{t("general.logout")}</p>
          <DatePicker
            showTime
            value={
              selectedRecord?.logout_time
                ? moment(selectedRecord.logout_time)
                : null
            }
            onChange={(date, dateString) =>
              handleDateChange(date, dateString, "logout_time")
            }
          />
        </div>
      </Modal>
    </div>
  );
}

export default PersonelDetailTable;
