import React from 'react';

const TermsOfUse = () => {
  return (
    <div>
      <iframe src="/terms-of-use.html" title="Terms of Use" style={{ width: '100%', height: '100vh', border: 'none' }} />
    </div>
  );
};

export default TermsOfUse;
