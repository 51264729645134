import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { useAuthContext } from "../../../context/UserContext";
import { DatePicker } from "../../../helpers/AntDesignPicker";
import BarCharts from "../../../helpers/Charts/BarCharts";
import {
  calculateDayWorkingHour,
  calculateDaysBetweenDates,
  groupDataByDate,
  rangePresetsDevice,
} from "../../../helpers/Function/Function";
import { getRequestHttps } from "../../../libs/AllGetRequest/HttpsHelper";
import PersonelDetailTable from "./PersonelDetail/PersonelDetailTable";
import PersonelHeader from "./PersonelDetail/PersonelHeader";
import PersonelLeaveTable from "./PersonelDetail/PersonelLeaveTable";
import PersonelLogTable from "./PersonelDetail/PersonelLogTable";

const PersonelDetail = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const { profil } = useAuthContext();
  const { setTitle } = useNotificationContext();
  const [t, i18n] = useTranslation("global");

  const { selectCompany } = useNotificationContext();

  const { RangePicker } = DatePicker;

  const last5Days = moment().subtract(5, "d");
  const today = moment();
  const last5DaysFormat = last5Days.format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");
  const todayFormat = today.format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");

  const [disabledDates, setDisabledDates] = useState([last5Days, today]);
  const [date, setDate] = useState({
    start: last5DaysFormat,
    end: todayFormat,
  });

  const [graphValue, setGraphValue] = useState({
    time: [],
    value: [],
  });

  const [personnelDetail, setPersonnelDetail] = useState();
  const [personnelData, setPersonnelData] = useState([]);
  const [personnelLeave, setPersonnelLeave] = useState([]);
  const [personnelLog, setPersonnelLog] = useState([]);

  const [usedLeave, setUsedLeave] = useState();

  const [leaveList, setLeaveList] = useState([]);

  const getLeaveList = async () => {
    let data;
    try {
      data = await getRequestHttps("v1/break-labels", {
        company_id: selectCompany?.company_id,
      });

      const value = data?.map((item) => {
        return {
          value: item.id,
          label:
            i18n.language === "en"
              ? item?.label_en
              : i18n.language === "tr"
              ? item?.label_tr || item?.label_en
              : item?.label_x || item?.label_en,
        };
      });
      setLeaveList(value);
    } catch (e) {}
  };

  const getDetailData = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/user-detail`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
      });

      const formValue = {
        personnel_name: data?.name_surname,
        email: data?.email,
        position: data?.work_title,
        branch: data?.branch_name,
        department: data?.department_name,
        phone: data?.phone,
        status: data?.is_record_login,
        hours: data?.hours,
        days: data?.days || [],
        holiday: data?.holiday_credit,
      };
      setPersonnelDetail(formValue);
    } catch (error) {}
  };

  const getWorkReport = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/work-report-userid`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
        limit: 1000,
        start_date: date.start,
        end_date: date.end,
      });

      const groupData = groupDataByDate(data);

      const dataArr = data
        ?.sort((a, b) => {
          return Date.parse(a.login_datetime) > Date.parse(b.login_datetime)
            ? -1
            : 1;
        })
        ?.map((item, i) => {
          return {
            id: item?.id,
            date: item?.login_datetime ? new Date(item?.login_datetime) : null,
            logout_time: item?.logout_datetime,
            branch: item?.branch_name,
            description: item?.description,
            department: item?.department_name,
            // break_login: data[i + 1] && dataArr[i + 1].is_logout_break,
            status_type: !item?.logout_datetime ? false : true,
            status: item?.is_logout_break
              ? "pending"
              : item?.logout_datetime
              ? false
              : true,
          };
        });
      setPersonnelData(dataArr);

      // console.log("groupData", groupData);

      const workHoursData = groupData?.map((item) => {
        const a = item.value.map((itemValue) => {
          return {
            date: itemValue?.login_datetime
              ? new Date(itemValue?.login_datetime)
              : null,
            login_time: itemValue?.login_datetime,
            logout_time: itemValue?.logout_datetime,
            status: itemValue?.is_logout_break ? "pending" : true,
          };
        });
        return a;
      });

      const times = [];
      const values = [];

      workHoursData.map((item) => {
        times.unshift(moment(item[0]?.date).format("DD-MM-YYYY"));
        values.unshift(calculateDayWorkingHour(item, true));
      });

      setGraphValue((prev) => {
        return { time: times, value: values };
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getPersonelDetail = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/work-break`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
        limit: 100,
      });

      const dataArr = data?.map((item, i) => {
        return {
          ids: i,
          start_date: item?.start_date ? new Date(item?.start_date) : null,
          end_date: item?.end_date
            ? item?.end_date === item?.start_date
              ? new Date(item?.end_date)
              : moment(item?.end_date).subtract(1, "day")
            : null,
          day: calculateDaysBetweenDates(item?.start_date, item?.end_date),
          status: item?.status,
          description: item?.description,
          id: item?.id,
          label_en: item?.label_en,
          label_tr: item?.label_tr,
          label_x: item?.label_x,
          break_label_id: item?.break_label_id,
        };
      });

      setPersonnelLeave(dataArr);

      //!kullanılan izin
      let useDays = 0;
      dataArr?.map((item) => {
        if (item?.status) {
          useDays += item?.day;
        }
      });

      setUsedLeave(useDays);
    } catch (error) {}
  };

  const getPersonelLog = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/user-log`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
        limit: 100,
      });

      const dataArr = data
        ?.sort((a, b) => {
          return Date.parse(a.created_at) > Date.parse(b.created_at) ? -1 : 1;
        })
        .map((item) => {
          return {
            date: item?.created_at ? new Date(item?.created_at) : null,
            // event: item?.log_type_id ? userLogType(item?.log_type_id, t) : null,
            event: item?.log_type_id,
            description: item?.description,
          };
        });

      setPersonnelLog(dataArr);
    } catch (error) {}
  };

  useEffect(() => {
    getDetailData(id);
    getWorkReport(id);
    getPersonelDetail(id);
    getPersonelLog(id);
    getLeaveList();
    // const x = setInterval(() => {
    //   getWorkReport(id);
    // }, 5000);
    // return () => clearInterval(x);
  }, [id, i18n.language, date]);

  useEffect(() => {
    setTitle(
      profil.isSuperAdmin !== 2
        ? [{ title: t("sidebar.staff"), href: "/staff" }, t("staff.personnel")]
        : [t("staff.personnel")]
    );
  }, [i18n.language]);

  const onRangeChange = (dates, dateStrings) => {
    setDisabledDates(dates);
    if (dates) {
      setDate({
        end: moment(dateStrings[1]).format("YYYY-MM-DD HH:mm:ss.SSSSSS Z"),
        start: moment(dateStrings[0]).format("YYYY-MM-DD HH:mm:ss.SSSSSS Z"),
      });
    } else {
      setDate({ start: last5DaysFormat, end: todayFormat });
    }
  };

  return (
    <div className="flex flex-col h-full w-full items-center">
      <div className="flex flex-col gap-5 w-full h-full rounded-xl pl-4 pr-5 mt-3">
      <div className="custom-border-b mt-5"></div>
        <div className="flex sticky capitalize top-0 z-50 w-full flex-col ms-3" style={{marginRight:"1px"}}>
          <PersonelHeader usedLeave={usedLeave} data={personnelDetail} />
        </div>
        <div className="custom-select w-fit">
          <RangePicker
            showTime={{ format: "HH:mm:ss" }}
            // onCalendarChange={(val) => {
            //   setDisabledDates(val);
            // }}
            // disabledDate={disabledDate}
            presets={rangePresetsDevice}
            defaultValue={[last5Days, today]}
            size="large"
            // presets={rangePresets}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={onRangeChange}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-5 mt-3">
          <div className="col-span-1 lg:col-span-4">
            <PersonelDetailTable data={personnelData} />
          </div>
          <div className="col-span-1 lg:col-span-2">
            <BarCharts
              height="450px"
              title={t("crud.personnel.work_hours")}
              xAxis={graphValue?.time}
              yAxis={graphValue?.value}
            />
          </div>
          <div className="col-span-1 lg:col-span-3">
            <PersonelLeaveTable
              getPersonelDetail={getPersonelDetail}
              data={personnelLeave}
              leaveList={leaveList}
            />
          </div>
          <div className="col-span-1 lg:col-span-3">
            <PersonelLogTable data={personnelLog} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonelDetail;
