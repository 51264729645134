import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCross1 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { addDepartmentSchema } from "../../../helpers/FormSchemas/FormErrorMessage";
import { inits } from "../../../helpers/FormSchemas/FormSchemas";
import {
  CatchBranchValue,
  ChangeLatlong,
  MultiSelectField,
  SwitchField,
} from "../../../helpers/Function/Function";
import {
  getRequestHttps,
  postOrPutRequestHttps,
} from "../../../libs/AllGetRequest/HttpsHelper";
import BranchMap from "../Branches/BranchMap";
import CitySearchList from "../Branches/CitySearchList";
import { Tooltip } from "antd";

const AddDepartment = () => {
  const [t] = useTranslation("global");
  const { selectCompany } = useNotificationContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const citySearch = "https://nominatim.openstreetmap.org/search?";

  const [departmentDetail, setDepartmentDetail] = useState({
    department_name: "",
    branch: "",
    description: "",
    address: "",
    location: "",
    lat: "",
    long: "",
    radius: 250,
    location_tracking: false,
  });

  const [openList, setOpenList] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [cityPosition, setCityPosition] = useState();

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectBranch, setSelectBranch] = useState();

  const [loading, setLoading] = useState(false);

  const getDetailData = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/department-detail`, {
        company_id: selectCompany?.company_id,
        department_id: serial,
      });

      const formValue = {
        branch: data?.branch_id,
        department_name: data?.name,
        location: data?.lat_long?.isSameAddress ? true : false,
        location_tracking: data?.location_tracking ? true : false,
        address: data?.address,
        description: data?.description,
        lat: data?.lat_long?.lat,
        long: data?.lat_long?.long,
        radius: data?.lat_long?.radius || 250,
      };

      data?.lat_long?.lat &&
        data?.lat_long?.long &&
        setCityPosition([data?.lat_long?.lat, data?.lat_long?.long]);
      setDepartmentDetail(formValue);
    } catch (error) { }
  };

  const getCompanies = async () => {
    let data;

    try {
      data = await getRequestHttps("v1/branch", {
        company_id: selectCompany?.company_id,
      });
      const optionsBranch = data?.map((e) => {
        return {
          label: e?.name,
          value: e?.id,
          lat: e?.lat_long?.lat,
          long: e?.lat_long?.long,
          radius: e?.lat_long?.radius,
        };
      });
      setBranchOptions(optionsBranch);
    } catch (e) {
    } finally {
    }
  };

  useEffect(() => {
    id && getDetailData(id);
    getCompanies();
  }, [id, selectCompany?.company_id]);

  const sumbitFunction = (e, setSubmitting, reset) => {
    console.log("e", e);
    const formData = {
      company_id: selectCompany?.company_id,
      name: e?.department_name,
      description: e?.description,
      address: e?.address,
      location_tracking: !!e?.location_tracking,
      branch_id: selectBranch?.value,
      lat_long: {
        isSameAddress: e?.location,
        lat: e?.location ? selectBranch?.lat : e?.lat,
        long: e?.location ? selectBranch?.long : e?.long,
        radius: e?.location ? selectBranch?.radius || 250 : e?.radius || 250,
      },
    };

    // console.log("formData", formData);

    const updateForm = { ...formData, id: id };

    // console.log("updateForm", updateForm);

    !id
      ? toast.promise(
        postOrPutRequestHttps("v1/department", "POST", formData, {
          company_id: selectCompany?.company_id,
        }),
        {
          pending: t("crud.general.adding"),
          success: {
            onClose: () => {
              navigate("/department");
            },
            autoClose: 1000,
            render: t("crud.general.added"),
            // onClose: () => navigate(`/`),
          },
          error: t("super_admin.add_error"),
        }
      )
      : toast.promise(
        postOrPutRequestHttps(`v1/department`, "PUT", updateForm, {
          company_id: selectCompany?.company_id,
        }),
        {
          pending: t("crud.general.updating"),
          success: {
            onClose: () => {
              navigate("/department");
            },
            autoClose: 1000,
            render: t("crud.general.updated"),
          },
          error: t("super_admin.add_error"),
        }
      );
    setSubmitting(false);
  };

  const fetcher = async (search) => {
    setLoading(true);
    const params = {
      q: search,
      format: "json",
      addressdetails: 1,
      polygon_geojson: 0,
    };
    const queryString = new URLSearchParams(params).toString();

    try {
      const data = await axios(`${citySearch}${queryString}`);
      setCityList(data?.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-5 pl-4 mr-5 p-5 bg-[#ffffff] flex flex-col items-center form-page">
      <div className="custom-border-b justify-content-center w-100">
        <p className="tracking-wider mb-4 capitalize addTitle">
          {/* {id
          ? t("general.edit") + " " + departmentDetail?.department_name
          : t("general.add") + " " + selectCompany?.department} */}

          {id
            ? t("general.edit") + " " + departmentDetail?.department_name
            : t("department.add_department")}
        </p>
      </div>
      <Formik
        validationSchema={addDepartmentSchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={{
          ...(!id ? inits.addDepartment : departmentDetail),
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form
              className="flex flex-col h-full w-full justify-between mt-5"
              style={{ fontFamily: "Poppins" }}
            >
              <CatchBranchValue
                options={branchOptions}
                setSelectItem={setSelectBranch}
                id={selectCompany?.company_id}
                setFieldValue={setFieldValue}
                field={"branch"}
                value={values?.branch}
              />
              <ChangeLatlong
                lat={values?.lat}
                long={values?.long}
                setCityPosition={setCityPosition}
              />
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6 fw-bold">
                        <label htmlFor="location" className="titleForm mt-0">
                          {t("branches.location")}
                        </label>
                        <Field name="location" className="custom-switch ms-4 mb-1" style={{ width: "100px" }} checked={values?.location} checkedChildren={t("general.same")} unCheckedChildren={t("general.different")} component={SwitchField} />
                      </div>
                      {/* <div className="col-6">

                      </div> */}
                      <div className="col-6">
                        <div className="text-red-600">
                          <ErrorMessage name="location" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-6">
                    <label
                      htmlFor="department_name"
                      className="titleForm required"
                    >
                      {/* {selectCompany?.department + " " + t("general.name")} */}
                      {t("department.department_name")}
                    </label>
                    <Field
                      name="department_name"
                      className="inputForm mt-2 px-2"
                    //   options={EncryptionOptions}
                    //   component={MultiSelectField}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="department_name" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="col-span-2 md:col-span-4 xxl:col-span-5">
                      <label htmlFor="branch" className="titleForm required">
                        {/* {selectCompany?.branch + " " + t("general.name")} */}
                        {t("crud.add_branch.branch_name")}
                      </label>{" "}
                      <Field
                        name="branch"
                        // as="select"
                        options={branchOptions}
                        className="inputForm mt-2 px-2"
                        search={false}
                        clear
                        component={MultiSelectField}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="branch" />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="description" className="titleForm">
                      {t("general.description")}
                    </label>
                    <Field
                      name="description"
                      className="inputForm mt-2 px-2"
                    //   options={EncryptionOptions}
                    //   component={MultiSelectField}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="description" />
                    </div>
                  </div>
                  {!values?.location && (
                    <div className=" col-span-2 md:col-span-1 relative changePageAnimation mt-4">
                      <label htmlFor="address" className="titleForm">
                        {t("general.address")}
                      </label>
                      <div className="relative">
                        <Field
                          name="address"
                          className="inputForm mt-2 px-2"
                        //   options={EncryptionOptions}
                        //   component={MultiSelectField}
                        />
                        <button
                          type="button"
                          disabled={values?.address?.length < 3}
                          onClick={() => {
                            setOpenList(true);
                            fetcher(values.address);
                          }}
                          className="absolute top-2 rounded-r-lg right-0 w-20 items-center flex justify-center h-30 bg-gray-300 disabled:bg-gray-400 cursor-pointer"
                        >
                          {t("general.search")}
                        </button>
                        <div className="text-red-600">
                          <ErrorMessage name="address" />
                        </div>
                      </div>
                      <>
                        <div
                          className={`w-full bg-[#f3f2f2] absolute z-10 rounded-xl shadow-md duration-300 overflow-y-auto ${openList ? "h-40" : "h-0"
                            }`}
                        >
                          <div className="absolute right-3 top-3">
                            <button
                              className="duration-300 text-[#858F91] cursor-pointer hover:text-[#407BFF]"
                              type="button"
                              onClick={() => setOpenList(false)}
                            >
                              <RxCross1 />
                            </button>
                          </div>
                          <CitySearchList
                            setFieldValue={setFieldValue}
                            cityList={cityList}
                            setCityList={setCityList}
                            setCityPosition={setCityPosition}
                            setOpenList={setOpenList}
                            loading={loading}
                          />
                        </div>
                      </>
                    </div>
                  )}
                  {!values?.location && (
                    <div className="col-span-2 grid grid-cols-1 md:grid-cols-10 gap-y-3 xl:gap-x-30 gap-x-5 changePageAnimation mt-4">
                      <div className="col-span-1 col md:col-span-4">
                        <label htmlFor="lat" className="titleForm required">
                          {t("general.lat")}
                        </label>
                        <div>
                          <Field name="lat" className="inputForm mt-2 px-2" type="number" />
                          <div className="text-red-600">
                            <ErrorMessage name="lat" />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 md:col-span-4">
                        <label htmlFor="long" className="titleForm required">
                          {t("general.long")}
                        </label>
                        <div>
                          <Field
                            name="long"
                            className="inputForm mt-2 px-2"
                            type="number"
                          />
                          <div className="text-red-600">
                            <ErrorMessage name="long" />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 md:col-span-2">
                        <label htmlFor="radius" className="titleForm required">
                          {t("general.radius")}
                        </label>
                        <div>
                          <Field
                            name="radius"
                            className="inputForm mt-2 px-2"
                            type="number"
                          />
                          <div className="text-red-600">
                            <ErrorMessage name="radius" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!values?.location && (
                <div className="w-full h-96 changePageAnimation">
                  <BranchMap
                    radius={values?.radius}
                    setCityPosition={setCityPosition}
                    cityPosition={cityPosition}
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}
              <div className="container mt-4">
                <div className="row flex">
                  <div className="col-6 justify-content-start text-start flex">
                    <div className="fw-bold me-2">
                      <label htmlFor="location_tracking" className="titleForm mt-0">{t("general.location_tracking")}</label>
                    </div>
                    <div className="">
                      <Tooltip title={t("general.location_tracking_info")}>
                        <Field name="location_tracking" className="custom-switch" checked={values?.location_tracking} checkedChildren={t("general.open_edit")} unCheckedChildren={t("general.close_edit")} component={SwitchField} />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-6 flex justify-content-end">
                      <button
                        type="submit"
                        className="xl:px-20 px-5 py-3 duration-300 relative flex submitButton"
                      >
                        {t("general.save")}
                        {/* {loading ? (
                    <AiOutlineLoading3Quarters className="ml-4 animate-spin absolute right-8 top-4" />
                  ) : (
                    ""
                  )} */}
                      </button>
                  </div>
                </div>
              </div>

            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddDepartment;
