import React from "react";

const BranchesIcon = ({ width, height }) => {
  return (
<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_112_130)">
<path d="M9.42847 4.28566L11.9999 1.71423L14.5713 4.28566" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.57138 17.5714L1.71423 21L5.1428 22.2857" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.4285 17.5714L22.2856 21L18.8571 22.2857" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.2857 20.9999L12 15.4285" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.71423 20.9999L11.9999 15.4285" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 15.4285V1.71423" stroke="#858F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_112_130">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
  );
};

export default BranchesIcon;
