import React from "react";

const CompanyDepartmentIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 472.8 472.8"
    >
      <g>
        <path
          d="M290 258.8h137.6V358H290z"
          fill="#eeeadf"
          data-original="#eeeadf"
        ></path>
        <path
          d="M290 258.8h24V358h-24z"
          fill="#ffffff"
          data-original="#ffffff"
        ></path>
        <path
          d="M7.2 114.8h296V152H7.2z"
          fill="#eb6460"
          data-original="#eb6460"
        ></path>
        <path
          d="M7.2 114.8h37.6V152H7.2z"
          fill="#ea8888"
          data-original="#ea8888"
        ></path>
        <path
          d="M21.6 152h267.6v206H21.6z"
          fill="#eeeadf"
          data-original="#eeeadf"
        ></path>
        <path
          d="M21.6 152h29.6v206H21.6z"
          fill="#ffffff"
          data-original="#ffffff"
        ></path>
        <path
          d="M95.6 274h119.2v84H95.6z"
          fill="#82d3ee"
          data-original="#82d3ee"
        ></path>
        <path
          d="M95.6 274h19.2v84H95.6zM155.2 274h19.2v84h-19.2z"
          fill="#a2dded"
          data-original="#a2dded"
        ></path>
        <path
          d="M7.2 159.2h7.2V358c0 4 3.2 7.2 7.2 7.2h444c4 0 7.2-3.2 7.2-7.2s-3.2-7.2-7.2-7.2h-32.4v-82h24c4 0 7.2-3.2 7.2-7.2s-3.2-7.2-7.2-7.2H296v-95.2h7.2c4 0 7.2-3.2 7.2-7.2v-37.2c0-4-3.2-7.2-7.2-7.2H7.2c-4 0-7.2 3.2-7.2 7.2V152c0 4 3.2 7.2 7.2 7.2zm200.4 122v70h-45.2v-70h45.2zM148 350.8h-45.2v-69.6H148v69.6zm270.8-82v82.4h-52.4V310c0-4-3.2-7.2-7.2-7.2S352 306 352 310v41.2h-56v-82.4h122.8zm-136.8 82H222.4V274c0-4-3.2-7.2-7.2-7.2H95.6c-4 0-7.2 3.2-7.2 7.2v77.2h-60v-192h253.2v191.6h.4zM14.4 122H296v23.2H14.4V122z"
          fill="#1f2b37"
          data-original="#1f2b37"
        ></path>
      </g>
    </svg>
  );
};

export default CompanyDepartmentIcon;
