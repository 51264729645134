import { Table } from "antd";
import React from "react";

const TableComponents = (props) => {
  return (
    <div>
      <Table
        scroll={props.scroll}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "rowClass" : "evenClass rowClass"
        }
        {...props}
        title={props?.title}
        columns={props?.columns}
        dataSource={props?.data}
        className="custom-table"
      />{" "}
    </div>
  );
};

export default TableComponents;
