import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BranchesHome from "../components/Modules/Branches/BranchesHome";
import { useNotificationContext } from "../context/NotifacitionContext";
import { useAuthContext } from "../context/UserContext";
import { getRequestHttps, postOrPutRequestHttps } from "../libs/AllGetRequest/HttpsHelper";

const Branches = () => {
  const [t, i18n] = useTranslation("global");
  const { profil } = useAuthContext();
  const { selectCompany, setTitle } = useNotificationContext();
  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    setTitle([t("sidebar.branches")]);
  }, [i18n.language]);

  const getDepartmentName = (id, dataList) => {
    const data = dataList?.filter((item) => item.branch_id == id && item.title);
    return data;
  };

  const getLoginCount = (targetIds, list) => {
    const data = list.find((item) => item?.branch_id == targetIds && item);
    return data ? data?.total_logged_user : 0;
  };

  const getBranch = async () => {
    setLoading(true);
    try {
      let data = await getRequestHttps("v1/branch", {
        company_id: selectCompany?.company_id,
      });

      const deparmtnet =
        selectCompany?.department &&
        (await getRequestHttps("v1/department", {
          company_id: selectCompany?.company_id,
        }));

      const logoutCount = await getRequestHttps(
        "v1/branch-user-logged-status",
        {
          company_id: selectCompany?.company_id,
        }
      );

      const departmentList = deparmtnet?.map((e) => {
        return {
          id: e?.id,
          title: e?.name,
          branch_id: e?.branch_id,
        };
      });

      const companiesList = data?.map((e) => {
        return {
          id: e?.id,
          title: e?.name,
          description: e?.description || "-",
          total: e?.user_count,
          login:
            logoutCount?.length > 0
              ? getLoginCount(e?.id, logoutCount) || 0
              : 0,
          department:
            e?.id && selectCompany?.department
              ? getDepartmentName(e?.id, departmentList)
              : "-",
          lat: e?.lat_long?.lat,
          long: e?.lat_long?.long,
          radius: e?.radius
        };
      });
      setBranchList(companiesList);
    } catch (e) {
      console.error("Error fetching branches: ", e)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectCompany?.company_id) {
      getBranch();
    }
  }, [selectCompany?.company_id]);

  return (
    <div className="mt-5 pl-4 pr-5">
            <div className="custom-border-b"></div>
      <BranchesHome data={branchList} loading={loading} fetcher={getBranch} disableFields={true} />
    </div>
  );
};

export default Branches;
