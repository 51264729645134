class ModalDismissResultModel {
    constructor(obj = {}) {
      this.isSucceeded = false;
      this.message = "";
      this.actionData = null;
  
      Object.assign(this, obj);
    }
  
    setProps(modalDismissResult) {
      Object.assign(this, modalDismissResult);
    }
  }
  
  export default ModalDismissResultModel;
  