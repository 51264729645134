import { Select, Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../context/NotifacitionContext";
import MapPersonelList from "./MapPersonelList";

const MapLeftSideBar = ({
  data,
  setSelectBranches,
  selectBranches,
  personnelList,
  selectDepartment,
  setSelectDepartment,
}) => {
  const { selectCompany } = useNotificationContext();
  const [t] = useTranslation("global");

  const optionsBranch = data
    .filter((item) => item.type === "branch")
    .map((e) => {
      return {
        label: e.title,
        value: e.id,
        total: e.total,
        login: e.login,
      };
    });

  const departmentOptions = data
    .filter((item) => item.type === "department")
    .map((e) => {
      return {
        label: e.title,
        value: e.id,
        total: e.total,
        login: e.login,
      };
    });

  const onselectBranch = (e) => {
    setSelectBranches(e);
    setSelectDepartment();
  };

  const onSelectDepartment = (e) => {
    setSelectDepartment(e);
  };

  // useEffect(() => {
  //   getPersonel();
  //   const dataArr = Array.from({ length: personnelList?.login }).map(
  //     (_, i) => ({
  //       date: generateRandomDate(new Date(2023, 12, 29), new Date()),
  //       name: `Personnel ${i + 1}`,
  //       status: true,
  //     })
  //   );
  //   setPersonnelListArray(dataArr.sort((a, b) => b.date - a.date));
  // }, [personnelList]);

  return (
    <div className="w-full h-full py-4 px-4 flex flex-col gap-3">
      <div className="flex items-center ms-2">
        <p className="xxl:mt-2 mt-1 p-0 tracking-widest personel-label">
          {t("general.personnel")}
        </p>
        <p className="mt-1 ms-3 personel-label">
        <span className="w-2 h-2 rounded-full inline-block mr-1" style={{backgroundColor:'#3248F2', marginBottom:"2px"}}></span>
        <span>{personnelList?.length || 0}</span>
        </p>
        {/* <p className="mt-1 ">
          <Tag color="blue">{personnelList?.length || 0}</Tag>
        </p> */}
      </div>
      <MapPersonelList lang={t} data={personnelList} itemSize={70}/>
      {selectCompany?.branch && (
        <div className="flex flex-col gap-5 w-full custom-select">
          <Select
            value={selectBranches}
            onChange={(e) => onselectBranch(e)}
            className="w-full"
            optionFilterProp="label"
            allowClear
            showSearch
            options={optionsBranch}
            placeholder={t("map.select_branch")}
          />

          {selectCompany?.department && (
            <Select
              value={selectDepartment}
              onChange={(e) => onSelectDepartment(e)}
              className="w-full"
              optionFilterProp="label"
              allowClear
              showSearch
              options={departmentOptions}
              placeholder={t("map.select_department")}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MapLeftSideBar;
