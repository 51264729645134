import EVENTS from "../Modules/Personel/PersonelWorkCalendar/events";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Calendar } from "./Calendar";

const DnDCalendar = withDragAndDrop(Calendar);

export default function CustomCalendar(props) {
  //   const components = {
  //     event: ({ event }) => {
  //       const data = event?.data;
  //       if (data?.appointment)
  //         return <AppointmentEvent appointment={data?.appointment} />;
  //       if (data?.blockout) return <BlockoutEvent blockout={data?.blockout} />;

  //       return null;
  //     },
  //   };

  const { appointments, blockouts } = EVENTS.reduce(
    (acc, event) => {
      if (event?.data?.appointment) acc.appointments.push(event);
      if (event?.data?.blockout) acc.blockouts.push(event);
      return acc;
    },
    { appointments: [], blockouts: [] }
  );

  return (
    <DnDCalendar
      //   components={components}
      // events={appointments}
      backgroundEvents={blockouts}
      {...props}
    />
  );
}
