import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Map from "../../components/Map/Map";
import { useNotificationContext } from "../../context/NotifacitionContext";
import { getRequestHttps } from "../../libs/AllGetRequest/HttpsHelper";

const MapWrapper = () => {
  const [t, i18n] = useTranslation("global");
  const { setTitle, selectCompany } = useNotificationContext();

  const [selectBranches, setSelectBranches] = useState();
  const [selectDepartment, setSelectDepartment] = useState();

  const [data, setData] = useState([]);
  const [selectData, setSelectData] = useState();

  useEffect(() => {
    setTitle([t("sidebar.map")]);
  }, [i18n.language]);

  const getCompanyInfo = async () => {
    let data;
    try {
      data = await getRequestHttps(`v1/company-dashboard`, {
        company_id: selectCompany?.company_id,
      });
    } catch (err) {
      console.log("err", err);
    }

    const newValue = [
      {
        id: data?.company?.id,
        title: data?.company?.name,
        total: data?.company?.user_count,
        lat: data?.company?.lat_long?.lat,
        long: data?.company?.lat_long?.long,
        radius: data?.company?.lat_long?.radius,
        type: "company",
      },
    ];

    data?.branch?.map((item, i) => {
      newValue.push({
        id: item?.id,
        title: item?.name,
        total: item?.user_count,
        lat: item?.lat_long?.lat,
        long: item?.lat_long?.long,
        radius: item?.lat_long?.radius,
        type: "branch",
      });
    });

    data?.department?.map((item, i) => {
      newValue.push({
        id: item?.id,
        title: item?.name,
        total: item?.user_count,
        lat: item?.lat_long?.lat,
        long: item?.lat_long?.long,
        radius: item?.lat_long?.radius,
        address: item?.lat_long?.isSameAddress,
        type: "department",
      });
    });

    setData(newValue);
  };

  useEffect(() => {
    getCompanyInfo();
  }, []);

  useEffect(() => {
    if (data.length < 1) return;
    const selectBranch = data.filter((e) => e.id === selectBranches);
    setSelectData(selectBranch[0]);
  }, [selectBranches, data]);

  return (
    <div className="w-full relative mobile:min-w-[95vw] laptop:min-w-0 h-full pl-4 pr-5 pt-5">
      <div className="custom-border-b mb-3"></div>
      <Map
        data={data}
        setSelectBranches={setSelectBranches}
        selectBranches={selectBranches}
        selectData={selectData}
        selectDepartment={selectDepartment}
        setSelectDepartment={setSelectDepartment}
      />
    </div>
  );
};

export default MapWrapper;
