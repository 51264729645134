import axios from "axios";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../components/header/Header";
import SideBar from "../components/sidebar/SideBar";
import { useAuthContext } from "../context/UserContext";

const Dashboard = () => {
  const { logOut } = useAuthContext();
  const [t] = useTranslation("global");

  useEffect(() => {
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        console.log("err", err);
        if (err.response.data.message === "Name Or E-mail Exist!") {
          toast.warning(t("response_status.name_email"), {
            autoClose: 2000,
          });
        }
        // if (err.response.data.message === "Conflicting plans found") {
        //   toast.warning(t("Conflicting plans found"), {
        //     autoClose: 2000,
        //   });
        // }
        if (err.response.status === 401) {
          logOut();
          // navigate(0);
          if (!toast.isActive("logout")) {
            setTimeout(() => {
              toast.warning(t("response_status.token_error"), {
                toastId: "logout",
              });
            }, 1000);
          }
        } else if (err?.response?.status === 406) {
          toast.warning(t("response_status.406"), {
            autoClose: 2000,
          });
        } else if (err?.response?.status === 402) {
          toast.warning(t("response_status.402"), {
            autoClose: 2000,
          });
        } else if (err?.response?.status > 499 && err?.response?.status < 600) {
          if (!toast.isActive("server")) {
            setTimeout(() => {
              toast.warning(t("response_status.server_error"), {
                toastId: "server",
              });
            }, 500);
          }
        }
      }
    );
  }, []);

  return (
    <div className="flex w-full">
      <div>
        <SideBar />
      </div>
      <div className="container-fluid dashboard">
        <div className="container-fluid p-4 w-100 h-100 dashboard_content">
          <div className="flex flex-col w-full p-1 h-full" style={{overflowX:"hidden"}}>
            <Header />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
