import React from "react";
import { FixedSizeList as List } from "react-window";

const VirtualList = ({ Row, rowLength, itemSize }) => {
  return (
    <List
      className="mapDeviceList"
      height={800}
      itemCount={rowLength}
      itemSize={itemSize}
    >
      {Row}
    </List>
  );
};

export default VirtualList;
