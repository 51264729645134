import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CompanyHeader from "../components/Modules/Company/CompanyHeader";
import CompanyInfo from "../components/Modules/Company/CompanyInfo/CompanyInfo";
import CompanyLeave from "../components/Modules/Company/CompanyLeave";
import CompanySettings from "../components/Modules/Company/CompanySettings";
import { HomeIcon } from "../components/icons/Sidebar";
import SettingsIcon from "../components/icons/General/SettingsIcon";
import LeaveTypeIcon from "../components/icons/General/LeaveTypeIcon";
import { CalendarIcon } from "../components/icons/General";
import CalendarWiev from "../components/Modules/Personel/PersonelWorkCalendar/PersonelWorkPlan/CalendarWiev";

const Company = () => {
  const [t] = useTranslation("global");

  const [station, setStation] = useState(0);

  const tabsArray = [
    {
      id: 0,
      title: t("company.company_info"),
      image: <HomeIcon width={22} />,
    },
    { id: 1, title: t("general.settings"), image: <SettingsIcon width={22} />, },
    { id: 2, title: t("general.leave"), image: <LeaveTypeIcon width={22} />, },
    // { id: 3, title: t("general.calendar"), image: <CalendarIcon width={22} /> }
  ];

  const selectComponent = (data) => {
    switch (data) {
      case 0:
        return <CompanyInfo />;
      case 1:
        return <CompanySettings setStation={setStation} />;
      case 2:
        return <CompanyLeave />;
      // case 3:
      //   return <CalendarWiev />;
      default:
        return <CompanyInfo />;
    }
  };

  return (
    <div className="flex flex-col h-full w-full items-center">
      <div className="flex sticky w-full">
        <CompanyHeader
          tabsArray={tabsArray}
          setStation={setStation}
          station={station}
        />
      </div>
      <div className="changePageAnimation z-0 w-full h-full">
        {selectComponent(station)}
      </div>
    </div>
  );
};

export default Company;
