import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../../context/NotifacitionContext";
import { addPersonnelSchema } from "../../../../helpers/FormSchemas/FormErrorMessage";
import { inits } from "../../../../helpers/FormSchemas/FormSchemas";
import { MultiSelectField } from "../../../../helpers/Function/Function";
import {
  getRequestHttps,
  postOrPutRequestHttps,
} from "../../../../libs/AllGetRequest/HttpsHelper";

const AddUser = () => {
  const [t] = useTranslation("global");
  const { selectCompany } = useNotificationContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const pathName = location?.pathname?.split("/")[2];

  const [companyOptions, setCompanyOptions] = useState([]);

  const [personnelDetail, setPersonnelDetail] = useState({
    personnel_name: "",
    email: "",
  });

  const getDetailData = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/user-detail`, {
        user_id: serial,
      });

      const superFormValue = {
        personnel_name: data?.name_surname,
        email: data?.email,
      };
      const managerFormValue = {
        personnel_name: data?.name_surname,
        email: data?.email,
        company_ids: data?.company_ids,
      };

      data?.type_id === 1
        ? setPersonnelDetail(managerFormValue)
        : setPersonnelDetail(superFormValue);
    } catch (error) {}
  };

  const getCompanies = async () => {
    let data;
    try {
      data = await getRequestHttps("v1/company");
      const companiesList = data?.map((e, i) => {
        return {
          label: e?.name,
          value: e?.id,
        };
      });
      setCompanyOptions(companiesList);
    } catch (e) {}
  };

  useEffect(() => {
    id && getDetailData(id);
    (pathName === "add_manager" || pathName === "edit_manager") &&
      getCompanies();
  }, [id]);

  const sumbitFunction = (e, setSubmitting, reset) => {
    console.log("e", e);

    const formData = {
      name_surname: e?.personnel_name,
      email: e?.email,
      password: e?.password || null,
      company_ids: e?.company_ids,
      type_id:
        pathName === "add_super_admin" || pathName === "edit_super_admin"
          ? 0
          : 1,
    };
    (pathName === "add_super_admin" || pathName === "edit_super_admin") &&
      delete formData.company_ids;

    const updateForm = { ...formData, id: +id };

    // console.log("first", updateForm);
    !id
      ? toast.promise(
          postOrPutRequestHttps("v1/user", "POST", formData, {
            company_id: selectCompany?.company_id,
          }),
          {
            pending: t("crud.general.adding"),
            success: {
              onClose: () => {
                navigate("/users");
              },
              autoClose: 1000,
              render: t("crud.general.added"),
              // onClose: () => navigate(`/`),
            },
            error: t("super_admin.add_error"),
          }
        )
      : toast.promise(
          postOrPutRequestHttps(`v1/user`, "PUT", updateForm, {
            company_id: selectCompany?.company_id,
          }),
          {
            pending: t("crud.general.updating"),
            success: {
              onClose: () => {
                navigate("/users");
              },
              autoClose: 1000,
              render: t("crud.general.updated"),
            },
            error: t("super_admin.add_error"),
          }
        );

    setSubmitting(false);
  };

  return (
    <div className="mt-5 ml-10 mr-5 p-5 bg-[#ffffff] flex flex-col items-center">
      <p className="font-semibold text-lg tracking-wider mb-5">
        {pathName === "add_super_admin" || pathName === "edit_super_admin"
          ? id
            ? t("crud.add_super_admin.edit")
            : t("crud.add_super_admin.title")
          : id
          ? t("manager.edit")
          : t("manager.add")}
      </p>
      <Formik
        validationSchema={addPersonnelSchema(t, id)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={id ? personnelDetail : inits.addPersonnel}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form
              className="flex flex-col h-full w-full justify-between"
              style={{ fontFamily: "Poppins" }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 xl:gap-x-30 gap-x-5">
                <div className=" capitalize">
                  <label
                    htmlFor="personnel_name"
                    className="formTitle required"
                  >
                    {pathName === "add_super_admin" ||
                    pathName === "edit_super_admin"
                      ? t("crud.add_super_admin.name")
                      : t("manager.name")}
                  </label>
                  <div>
                    <Field name="personnel_name" className="formInput" />
                    <div className="text-red-600">
                      <ErrorMessage name="personnel_name" />
                    </div>
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="formTitle required">
                    {pathName === "add_super_admin" ||
                    pathName === "edit_super_admin"
                      ? t("crud.add_super_admin.email")
                      : t("manager.email")}
                  </label>
                  <div>
                    <Field name="email" className="formInput" />
                    <div className="text-red-600">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className={`formTitle ${id ? "" : "required"}`}
                  >
                    {t("general.password")}
                  </label>
                  <div>
                    <Field name="password" className="formInput" />
                    <div className="text-red-600">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="confirm_password"
                    className={`formTitle ${id ? "" : "required"}`}
                  >
                    {t("crud.personnel.confirm_password")}
                  </label>
                  <div>
                    <Field name="confirm_password" className="formInput" />
                    <div className="text-red-600">
                      <ErrorMessage name="confirm_password" />
                    </div>
                  </div>
                </div>
                {(pathName === "add_manager" ||
                  pathName === "edit_manager") && (
                  <div>
                    <label htmlFor="company_ids" className="formTitle">
                      {t("company.company_list")}
                    </label>{" "}
                    <Field
                      name="company_ids"
                      mode="multiple"
                      placeholder={t("general.select_company")}
                      options={companyOptions}
                      className="formInput"
                      optionFilterProp="label"
                      search
                      clear
                      component={MultiSelectField}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="company_ids" />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-end mt-5">
                <button
                  type="submit"
                  className="bg-[#407BFF] font-semibold text-base xl:text-lg text-white xl:px-20 px-5 py-3 rounded-xl hover:bg-[#3db5e4] duration-300 relative flex"
                >
                  {t("general.save")}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddUser;
