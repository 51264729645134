import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrPowerReset } from "react-icons/gr";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { useAuthContext } from "../../../context/UserContext";
import { addPersonnelSchema } from "../../../helpers/FormSchemas/FormErrorMessage";
import { inits } from "../../../helpers/FormSchemas/FormSchemas";
import {
  MultiSelectField,
  SelectDepartmentList,
  SingleSelectField,
  convertMinutesToTime,
} from "../../../helpers/Function/Function";
import {
  getRequestHttps,
  postOrPutRequestHttps,
} from "../../../libs/AllGetRequest/HttpsHelper";

const AddPersonel = () => {
  const [t] = useTranslation("global");
  const { selectCompany } = useNotificationContext();
  const { profil } = useAuthContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [personnelDetail, setPersonnelDetail] = useState({
    personnel_name: "",
    email: "",
    position: "",
    branch: [],
    department: [],
    type_id: "",
    phone: "",
    stuff_id: "",
    hours: "",
    days: [],
    phone_identity: "",
  });

  const [branchOptions, setBranchOptions] = useState([]);

  const [departmentOptions, setDepartmentOptions] = useState([]);

  const [departmentList, setDepatmentList] = useState([]);

  const daysOptions = [
    {
      value: 0,
      label: t("days.sunday"),
    },
    {
      value: 1,
      label: t("days.monday"),
    },
    {
      value: 2,
      label: t("days.tuesday"),
    },
    {
      value: 3,
      label: t("days.wednesday"),
    },
    {
      value: 4,
      label: t("days.thursday"),
    },
    {
      value: 5,
      label: t("days.friday"),
    },
    {
      value: 6,
      label: t("days.saturday"),
    },
  ];

  const getDetailData = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/user-detail`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
      });

      const formValue = {
        personnel_name: data?.name_surname,
        email: data?.email,
        position: data?.work_title,
        branch: data?.branch_ids || [],
        type_id: data?.type_id || 2,
        department: data?.department_ids || [],
        phone: data?.phone,
        staff_id: data?.stuff_id_info,
        hours: data?.work_hour ? convertMinutesToTime(data?.work_hour) : null,
        days: data?.days || [],
        holiday: data?.holiday_credit,
        phone_identity: data?.phone_identity || "",
      };

      setPersonnelDetail(formValue);
    } catch (error) { }
  };

  const getBranch = async () => {
    let data;

    try {
      data = await getRequestHttps("v1/branch", {
        company_id: selectCompany?.company_id,
      });
      const optionsBranch = data?.map((e) => {
        return {
          label: e?.name,
          value: e?.id,
          lat: e?.lat_long?.lat,
          long: e?.lat_long?.long,
          radius: e?.lat_long?.radius,
        };
      });
      setBranchOptions(optionsBranch);
    } catch (e) {
    } finally {
    }
  };

  const getDepartment = async () => {
    let data;

    try {
      data = await getRequestHttps("v1/department", {
        company_id: selectCompany?.company_id,
      });
      const optionsBranch = data?.map((e) => {
        return {
          label: e?.name,
          value: e?.id,
          branch_id: e?.branch_id,
        };
      });
      setDepatmentList(optionsBranch);
    } catch (e) {
    } finally {
    }
  };

  useEffect(() => {
    selectCompany?.department && getDepartment();
    selectCompany?.branch && getBranch();
    id && getDetailData(id);
  }, [selectCompany?.company_id]);

  const sumbitFunction = (e, setSubmitting, reset) => {
    // console.log("e", e);

    const formData = {
      // work_hour: e?.hours ? convertTimeToMinutes(e?.hours) : null,
      work_hour: null,
      name_surname: e?.personnel_name,
      email: e?.email,
      password: e?.password || null,
      branch_ids: e?.branch?.length > 0 ? e?.branch : null,
      department_ids: e?.department?.length > 0 ? e?.department : null,
      phone: e?.phone,
      work_title: e?.position,
      type_id: e?.type_id,
      phone: e?.phone,
      stuff_id_info: e?.staff_id,
      holiday_credit: e?.holiday ? e?.holiday : 0,
      company_id: selectCompany?.company_id,
    };
    const updateForm = {
      ...formData,
      id: +id,
      phone_identity: e?.phone_identity || "",
    };

    // console.log("updateForm", updateForm);
    //todo test huseyın

    !id
      ? toast.promise(
        postOrPutRequestHttps("v1/user", "POST", formData, {
          company_id: selectCompany?.company_id,
        }),
        {
          pending: t("crud.general.adding"),
          success: {
            onClose: () => {
              navigate("/staff");
            },
            autoClose: 1000,
            render: t("crud.general.added"),
            // onClose: () => navigate(`/`),
          },
          error: t("super_admin.add_error"),
        }
      )
      : toast.promise(
        postOrPutRequestHttps(`v1/user`, "PUT", updateForm, {
          company_id: selectCompany?.company_id,
        }),
        {
          pending: t("crud.general.updating"),
          success: {
            onClose: () => {
              navigate("/staff");
            },
            autoClose: 1000,
            render: t("crud.general.updated"),
          },
          error: t("super_admin.add_error"),
        }
      );

    setSubmitting(false);
  };

  const userOptions = [
    { value: 1, label: "Manager" },
    { value: 2, label: "Staff" },
  ];

  return (
    <div className="mt-5 pl-4 mr-5 p-5 bg-[#ffffff] flex flex-col items-center form-page">
      <div className="custom-border-b justify-content-center w-100">
        <p className="tracking-wider mb-4 capitalize addTitle">
          {id ? t("staff.edit_personnel") : t("staff.add_personnel")}
        </p>
      </div>
      <Formik
        validationSchema={addPersonnelSchema(t, id)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={id ? personnelDetail : inits.addPersonnel}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form
              className="flex flex-col h-full w-full justify-between mt-5"
              style={{ fontFamily: "Poppins" }}
            >
              <SelectDepartmentList
                selectDepartment={values?.department}
                selectBranch={values?.branch}
                setFieldValue={setFieldValue}
                departmentList={departmentList}
                setDepartmentOptions={setDepartmentOptions}
              />
              <div className="row">
                <div className="col-4">
                  <div className=" capitalize">
                    <label
                      htmlFor="personnel_name"
                      className="titleForm required"
                    >
                      {t("crud.personnel.personnel_name")}
                    </label>
                    <div>
                      <Field name="personnel_name" className="inputForm mt-2 px-2" />
                      <div className="text-red-600">
                        <ErrorMessage name="personnel_name" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="email" className="titleForm required">
                    {t("staff.staff_email")}
                  </label>
                  <div>
                    <Field name="email" className="inputForm mt-2 px-2"
                      onChange={e => {
                        const { name, value } = e.target;
                        setFieldValue(name, value.toLocaleLowerCase('tr-TR'));
                      }} />
                    <div className="text-red-600">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="phone" className="titleForm ">
                    {t("general.phone")}
                  </label>
                  <div>
                    <Field name="phone" className="inputForm mt-2 px-2" />
                    <div className="text-red-600">
                      <ErrorMessage name="phone" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-4">
                  <label htmlFor="staff_id" className="titleForm">
                    {t("crud.personnel.id")}
                  </label>
                  <div>
                    <Field name="staff_id" className="inputForm mt-2 px-2" />
                    <div className="text-red-600">
                      <ErrorMessage name="staff_id" />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className={`${profil.isSuperAdmin == 0 ? "col-12" : "col-12"
                      }`}
                  >
                    <label htmlFor="position" className="titleForm">
                      {t("staff.title")}
                    </label>
                    <div>
                      <Field name="position" className="inputForm mt-2 px-2" />
                      <div className="text-red-600">
                        <ErrorMessage name="position" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  {selectCompany?.branch && (
                    <div className="row">
                      <div
                        className={
                          selectCompany?.department ? "col-6" : "col-6"
                        }
                      >
                        <label htmlFor="branch" className="titleForm">
                          {/* {selectCompany?.branch} */}
                          {t("staff.branch")}
                        </label>
                        <div>
                          <Field
                            name="branch"
                            className="inputForm mt-2 px-2 single-line-select"
                            mode="multiple"
                            optionFilterProp="label"
                            clear
                            options={branchOptions}
                            component={MultiSelectField}
                          />
                          <div className="text-red-600">
                            <ErrorMessage name="branch" />
                          </div>
                        </div>
                      </div>
                      {selectCompany?.department && (
                        <div className="col-6">
                          <label htmlFor="department" className="titleForm">
                            {/* {selectCompany?.department} */}
                            {t("sidebar.department")}
                          </label>
                          <div>
                            <Field
                              name="department"
                              className="inputForm mt-2 px-2"
                              mode="single"
                              optionFilterProp="label"
                              disabled={values?.branch?.length < 1}
                              clear
                              options={departmentOptions}
                              component={SingleSelectField}
                            />
                            <div className="text-red-600">
                              <ErrorMessage name="department" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-2">
                  <label htmlFor="type_id" className="titleForm">
                    {t("staff.user_type")}
                  </label>
                  <div>
                    <Field
                      name="type_id"
                      className="inputForm mt-2 px-2"
                      search={false}
                      options={userOptions}
                      component={MultiSelectField}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="type_id" />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <label htmlFor="holiday" className="titleForm">
                    {t("crud.personnel.holiday_credit")}
                  </label>
                  <div>
                    <Field
                      name="holiday"
                      className="inputForm mt-2 px-2"
                      type="number"
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="holiday" />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <label
                    htmlFor="password"
                    className={`titleForm ${id ? "" : "required"}`}
                  >
                    {t("general.password")}
                  </label>
                  <div>
                    <Field name="password" className="inputForm mt-2 px-2" />
                    <div className="text-red-600">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <label
                    htmlFor="confirm_password"
                    className={`titleForm ${id ? "" : "required"}`}
                  >
                    {t("crud.personnel.confirm_password")}
                  </label>
                  <div>
                    <Field name="confirm_password" className="inputForm mt-2 px-2" />
                    <div className="text-red-600">
                      <ErrorMessage name="confirm_password" />
                    </div>
                  </div>
                </div>
                <div className="row flex mt-5">
                  <div className="col-6 flex justify-content-start">
                  {id &&(
                    <div className="relative row">
                      <div className="col-10">
                        {" "}
                        <label htmlFor="phone_identity" className="titleForm">
                          {t("crud.personnel.phone_identity")}
                        </label>
                        <div>
                          <Field
                            disabled
                            name="phone_identity"
                            className="inputForm mt-2 px-2 disabled:bg-[#c4c1c1]"
                          />
                          <div className="text-red-600">
                            <ErrorMessage name="phone_identity" />
                          </div>
                        </div>
                      </div>
                      <div className="col-2 mt-3 items-center justify-start">
                        <label className="titleForm">
                          {" "}
                          {t("general.reset")}{" "}
                        </label>
                        <GrPowerReset
                          color="blue"
                          className="cursor-pointer hover:scale-125 duration-200"
                          onClick={() => setFieldValue("phone_identity", "")}
                          size={20}
                        />
                      </div>
                    </div>
                  )}
                  </div>
                  <div className="col-6 flex align-items-end justify-content-end">
                    <button
                      type="submit"
                      className="xl:px-20 px-5 py-3 duration-300 relative flex submitButton"
                    >
                      {t("general.save")}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddPersonel;
