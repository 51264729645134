import { Tag, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import VirtualList from "../../helpers/VirtualList/VirtualList";

const MapPersonelList = ({ lang, data, itemSize }) => {
  const VirtulData = ({ index, style }) => (
    <div style={style}>
      <div className="bg-[#FCFCFC] text-[#53636f] rounded-[4px] px-2 py-2 flex justify-between items-center gap-3 border-[1px] border-[#858f910f]">
        <div className="flex flex-col items-start justify-between tracking-widest">
          <Tooltip
            title={data[index]?.name?.length > 14 ? data[index]?.name : null}
          >
            <p>
              {" "}
              {data[index]?.name?.slice(0, 14)}
              {data[index]?.name?.length > 14 && "..."}{" "}
            </p>
          </Tooltip>
          <p className="text-[#ADB8BA] font-semibold text-xs">
            {data[index]?.date
              ? moment(data[index]?.date).format("DD/MM/YYYY HH:mm")
              : "Kayıt Yok"}
          </p>
        </div>
        <div className="flex flex-col gap-[4px]">
          {" "}
          <div className="min-w-16 w-16">
            {" "}
            <Tag
              className="w-full flex flex-col items-center"
              color={data[index]?.type == "2" ? "yellow" : "blue"}
            >
              {data[index]?.type == "2"
                ? lang("sidebar.staff")
                : lang("companies.manager")}
            </Tag>
          </div>
          <div className="min-w-16 w-16">
            {" "}
            <Tag
              className="w-full flex flex-col items-center"
              color={data[index]?.status ? "green" : "red"}
            >
              {data[index]?.status ? "Login" : "LogOut"}
            </Tag>{" "}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <VirtualList
      Row={VirtulData}
      rowLength={data?.length}
      itemSize={itemSize}
    />
  );
};

export default MapPersonelList;
