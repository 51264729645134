import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { LuLogIn } from "react-icons/lu";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ManuelLoginForm } from "../components/Modules/Personel";
import TableComponents from "../components/Table/TableComponents";
import {
  CalendarIcon,
  EditIcon,
  ExcelReportIcon,
  TrashIcon,
  PlusIcon,
} from "../components/icons/General";
import AddPersonelICon from "../components/icons/Personel/AddPersonelICon";
import { useNotificationContext } from "../context/NotifacitionContext";
import { useAuthContext } from "../context/UserContext";
import {
  deleteRequestHttps,
  getRequestHttps,
} from "../libs/AllGetRequest/HttpsHelper";

const Personel = () => {
  const { profil } = useAuthContext();
  const [t, i18n] = useTranslation("global");
  const { isEditMode, setTitle, selectCompany } = useNotificationContext();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [personel, setPersonel] = useState([]);
  const [personelSearchList, setPersonelSearchList] = useState([]);
  const [tableColumn, setTableColumn] = useState("");

  const [userId, setUserId] = useState(0);

  const [department, setDepartment] = useState([]);
  const [branch, setBranch] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const searchInput = useRef(null);

  const { Search } = Input;

  useEffect(() => {
    setTitle([t("sidebar.staff")]);
  }, [i18n.language]);

  const getBranchName = (targetIds, list) => {
    if (targetIds?.length === 0 || list?.length === 0) {
      return [];
    }
    return targetIds?.map((id) => {
      const foundItem = list?.find((item) => item.value === id);
      return foundItem ? foundItem.label : null;
    });
  };

  const getDepartmentName = (targetIds, list) => {
    if (targetIds?.length === 0 || list?.length === 0) {
      return [];
    }
    return targetIds?.map((id) => {
      const foundItem = list?.find((item) => item.value === id);
      return foundItem ? foundItem.label : null;
    });
  };

  const getPersonel = async () => {
    setLoading(true);
    let data;

    try {
      data = await getRequestHttps(
        "v1/user",
        {
          company_id: selectCompany?.company_id,
        },
        "result"
      );

      const branchList =
        selectCompany?.branch &&
        (await getRequestHttps("v1/branch", {
          company_id: selectCompany?.company_id,
        }));
      const optionsBranch = branchList?.map((e) => {
        return {
          label: e?.name,
          value: e?.id,
        };
      });

      const departmentList =
        selectCompany?.department &&
        (await getRequestHttps("v1/department", {
          company_id: selectCompany?.company_id,
        }));
      const optionsDepartment = departmentList?.map((e) => {
        return {
          label: e?.name,
          value: e?.id,
        };
      });

      setDepartment(optionsDepartment);
      setBranch(optionsBranch);

      const personelList = data?.map((e, i) => {
        return {
          field1: e?.name_surname,
          field2: e?.email,
          field3: e?.work_title,
          field4: e?.type_id,
          logStatus: e?.is_record_login ? true : false,
          serial: e?.id,
          branch:
            e?.branch_ids?.length > 0
              ? getBranchName(e?.branch_ids, optionsBranch)
              : "",
          department:
            e?.department_ids?.length > 0
              ? getDepartmentName(e?.department_ids, optionsDepartment)
              : "",
          id: e?.id,
        };
      });
      setPersonel(personelList);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearchStaff = async (value) => {
    setLoading(true);
    let data;
    try {
      data = await getRequestHttps(
        "v1/user",
        {
          company_id: selectCompany?.company_id,
          keyword: value,
        },
        "result"
      );
      const personelList = data?.map((e, i) => {
        return {
          field1: e?.name_surname,
          field2: e?.email,
          field3: e?.work_title,
          field4: e?.type_id,
          logStatus: e?.is_record_login ? true : false,
          serial: e?.id,
          branch:
            e?.branch_ids?.length > 0
              ? getBranchName(e?.branch_ids, branch)
              : "",
          department:
            e?.department_ids?.length > 0
              ? getDepartmentName(e?.department_ids, department)
              : "",
          id: e?.id,
        };
      });
      setPersonelSearchList(personelList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonel();
  }, [selectCompany?.company_id]);

  useEffect(() => {
    searchValue?.length > 3 && handleSearchStaff(searchValue);
  }, [searchValue]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const getColumnSearchProps = (dataIndex, selectOptions) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        {selectOptions && (
          <Select
            ref={searchInput}
            placeholder={t("general.reset")}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          >
            {selectOptions.map((option, key) => {
              return (
                <Select.Option key={key} value={`${option.value}`}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {!selectOptions && (
          <Input
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            onInput={() => {
              setSearchedColumn(dataIndex);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}
        <Space>
          <Button
            type="link"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("general.search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 80,
            }}
          >
            {t("general.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (!value || !record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    // defaultFilteredValue: storageData[dataIndex].filter,
  });

  const handleDelete = (id) => {
    toast.promise(
      deleteRequestHttps(
        `v1/user`,
        {},
        {
          company_id: selectCompany?.company_id,
          id: id,
        }
      ),
      {
        pending: t("crud.general.deleting"),
        success: {
          onClose: () => {
            getPersonel();
          },
          autoClose: 1000,
          render: t("crud.general.deleted"),
        },
        error: t("super_admin.add_error"),
      }
    );
  };

  const columns = [
    {
      title: t("general.name"),
      dataIndex: "field1",
      ...getColumnSearchProps("field1"),
      render: (_, { field1, serial }) => (
        <div className="tableRowText text-[#407BFF]  hover:text-[#009BDA] duration-300 capitalize">
          <Link state={{ name: field1 }} to={`personel_detail/${serial}`}>
            {field1}
          </Link>{" "}
        </div>
      ),
    },
    {
      title: t("staff.staff_email"),
      dataIndex: "field2",
      ...getColumnSearchProps("field2"),
      render: (text) => (
        <div className="tableRowText normal-case">{text || ""}</div>
      ),
    },
    {
      title: t("staff.status"),
      dataIndex: "logStatus",
      ...getColumnSearchProps("logStatus", [
        { value: true, label: t("general.login") },
        { value: false, label: t("general.logout") },
      ]),
      render: (text) => {
        return (
          <div className="tableRowText">
            <Tag color={text === true ? "green" : "red"}>
              {text === true ? t("general.login") : t("general.logout")}
            </Tag>
          </div>
        );
      },
    },
    {
      title: t("staff.title"),
      dataIndex: "field3",
      ...getColumnSearchProps("field3"),
      render: (text) => <div className="tableRowText">{text || ""}</div>,
    },
    {
      title: t("staff.type"),
      dataIndex: "field4",
      ...getColumnSearchProps("field4", [
        { value: "1", label: t("companies.manager") },
        { value: "2", label: t("sidebar.staff") },
      ]),
      render: (text) => (
        <div className="tableRowText">
          {" "}
          <Tag color={text == "2" ? "yellow" : "blue"}>
            {text == "2" ? t("sidebar.staff") : t("companies.manager")}
          </Tag>
        </div>
      ),
    },
    {
      // title: selectCompany?.branch,
      title: selectCompany?.branch ? t("sidebar.branches") : null,
      dataIndex: "branch",
      ...getColumnSearchProps("branch"),
      render: (text) => {
        return (
          <div className="tableRowText">
            {text?.length > 0 ? text?.map((item) => <p>{item}</p>) : "-"}
          </div>
        );
      },
    },
    {
      // title: selectCompany?.department,
      title: selectCompany?.department ? t("sidebar.department") : null,
      dataIndex: "department",
      ...getColumnSearchProps("department"),
      render: (text) => {
        return (
          <div className="tableRowText">
            {text?.length > 0 ? text?.map((item) => <p>{item}</p>) : "-"}
          </div>
        );
      },
    },

    {
      title: t("general.actions"),
      dataIndex: "serial",
      align: "center",
      width: "8%",
      render: (
        _,
        { field1, field2, field3, branch, department, serial, logStatus }
      ) => {
        return (
          <div className="flex text-[#858F91] gap-1">
            {/* {!logStatus ? ( */}
            <Tooltip title={t("general.manuel_login")}>
              <LuLogIn
                onClick={() => {
                  setUserId(serial);
                  setOpenModal(true);
                }}
                className="tableActionIcon"
              />
            </Tooltip>
            <Tooltip title={`${t("general.calendar")}`}>
              <div>
                <Link
                  className="tableActionIcon"
                  to={`personel_work_plan/${serial}`}
                >
                  <CalendarIcon width={20} height={20} />
                </Link>
              </div>
            </Tooltip>
            <Tooltip title={t("general.report")} placement="topRight">
              <Link
                to={`personel_report/${serial}`}
                className="tableActionIcon"
              >
                <ExcelReportIcon width={20} height={20} />
              </Link>
            </Tooltip>
            <Tooltip title={`${t("general.edit")} ${field1}`}>
              <div>
                <Link
                  className="tableActionIcon"
                  to={`edit_personel/${serial}`}
                >
                  <EditIcon width={20} height={20} />
                </Link>
              </div>
            </Tooltip>
            <Tooltip title={`${t("general.delete")} ${field1}`}>
              <Popconfirm
                onConfirm={() => handleDelete(serial)}
                title={() => <p className="capitalize"> {field1} </p>}
                description={t("general.confirm_delete")}
                placement="topLeft"
                icon={
                  <FaExclamationCircle
                    color="red"
                    className="mr-2 mt-[2px]"
                    size={18}
                  />
                }
                okType="danger"
              >
                {/* <div className="tableActionIcon">
                  <TrashIcon />
                </div> */}
                <div className="tableActionIcon">
                  <TrashIcon width={20} height={20} />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   dataIndex: "",
    //   width: "4%",
    //   title: (
    //     <div className="flex gap-1 items-center">
    //       {/* <Tooltip title={t("general.excel_import")} placement="topRight">
    //         <Link
    //           to={"import_personel_list"}
    //           className="w-8 h-8 text-[#eaeaea] bg-[#bdbdbd] p-1 rounded-lg hover:bg-[#cecccc] cursor-pointer duration-300 hover:text-[#407BFF]"
    //         >
    //           <ExcelUploadIcon />
    //         </Link>
    //       </Tooltip> */}
    //       <Tooltip title={t("staff.add_personnel")} placement="topRight">
    //         <div className="text-[#eaeaea] bg-[#bdbdbd] p-1 rounded-lg hover:bg-[#cecccc] cursor-pointer ">
    //           <Link
    //             className="duration-300 hover:text-[#407BFF]"
    //             to="add_personel"
    //           >
    //             <AddPersonelICon width={23} height={23} />
    //           </Link>
    //           {/* <WifiIcon width={20} height={20} /> */}
    //         </div>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    const data = columns.filter((item) => {
      return item?.title;
    });
    const newColumns = isEditMode ? data : data.slice(0, -2);
    setTableColumn(newColumns);
  }, [isEditMode, i18n.language, selectCompany?.company_id]);

  return (
    <>
      <Modal
        title=""
        centered
        open={openModal}
        // open={true}
        // onOk={() => setOpenModal(false)}
        onCancel={() => {
          setUserId(0);
          setOpenModal(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {/* <Button>Custom Button</Button> */}
            {/* <CancelBtn /> */}
            {/* <OkBtn /> */}
          </>
        )}
        width={800}
      >
        <ManuelLoginForm
          department={department}
          branch={branch}
          setOpenModal={setOpenModal}
          getPersonel={getPersonel}
          userId={userId}
        />
      </Modal>
      <div className="mt-5 pl-4 pr-5">
      <div className="custom-border-b mb-5"></div>
      {" "}
      <Tooltip title={t("staff.add_personnel")} placement="topLeft">
        <div
          role="button"
          className="addButton bg-[#3248F2] row p-1 rounded-lg cursor-pointer"
        >
          <Link to="add_personel" className="linkref">
          <label className="buttonLabel cursor-pointer">{t("staff.add_personnel")} </label>
          <div className="addIcon cursor-pointer">
            <PlusIcon width={12} height={12} />
          </div>
          </Link>
        </div>
      </Tooltip>
        <TableComponents
          scroll={
            personel?.length > 1000 && {
              y: 700,
            }
          }
          // title={() => (
          //   <div>
          //     <input
          //       // loading
          //       className="formInput"
          //       allowClear
          //       onChange={(e) => setSearchValue(e.target.value)}
          //       placeholder={t("staff.select_staff")}
          //     />
          //   </div>
          // )}
          title={false}
          bordered={false}
          loading={loading}
          size="large"
          pagination={false}
          columns={tableColumn}
          data={searchValue?.length > 3 ? personelSearchList : personel}
          // virtual
        />
      </div>
    </>
  );
};

export default Personel;
