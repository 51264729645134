//local
// const environment = {
//   checkoutKey: "test-checkout-key-80edd637523640659a6be7e866c9435c",
//   netsDibspayment: "https://test.checkout.dibspayment.eu/v1/checkout.js?v=1",
// };

//live
const environment = {
  checkoutKey: "live-checkout-key-a92273ff71194fa59f2716419277cfd9",
  netsDibspayment: "https://checkout.dibspayment.eu/v1/checkout.js?v=1",
};

export default environment;