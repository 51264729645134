import React from "react";

const DownloadIcon = ({ width = 30, height = 30 }) => {
  return (
<svg width={width} height={height} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_354_57)">
<rect x="4" y="1" width="30" height="30" rx="5" fill="#3248F2"/>
<path d="M27 19V22.3333C27 22.7754 26.8244 23.1993 26.5118 23.5118C26.1993 23.8244 25.7754 24 25.3333 24H13.6667C13.2246 24 12.8007 23.8244 12.4882 23.5118C12.1756 23.1993 12 22.7754 12 22.3333V19" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.333 14.8334L19.4997 19L23.6663 14.8334" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.5 19V9" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_d_354_57" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_354_57"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_354_57" result="shape"/>
</filter>
</defs>
</svg>

  );
};

export default DownloadIcon;
