import React from "react";

const CompanyHeader = ({ tabsArray, station, setStation }) => {
  return (
    <div className="flex flex-col w-full p-3">
      <div className="flex justify-between custom-border-b">
        <div className="flex gap-2 md:gap-5 text-sm md:text-base">
          {tabsArray?.map(({ id, title, image }, index) => (
            <div
              key={index}
              onClick={() => setStation(id)}
              className="flex flex-col items-center group mr-16"
            >
              <div className="flex items-center">
                <div className="mr-2"
                >
                  {image}
                </div>
                <p
                className={`font-semibold group-hover:text-[#858f91b3] capitalize cursor-pointer ease-linear duration-300 ${id === station ? "text-[#858f91b3]" : "text-[#858f91b3]"
                  }`}
                key={index}
              >
                {title}
              </p>
              </div>

              <div
                style={{ marginBottom: "-2px" }}
                className={`w-[100%] ease-linear duration-300 group-hover:custom-border-b group-hover:border-[#009BDA] ${id === station ? "custom-border-a border-[#4B59EF] " : ""
                  }`}
              />
            </div>

          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyHeader;
