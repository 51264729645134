import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { useNotificationContext } from "../../context/NotifacitionContext";
import Department from "../../pages/Department";
import AddDepartment from "../Modules/Department/AddDepartment";
import CalendarWiev from "../Modules/Personel/PersonelWorkCalendar/PersonelWorkPlan/CalendarWiev";

const DepartmentRoutes = () => {
  const { setTitle, selectCompany } = useNotificationContext();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    // setTitle([selectCompany?.department]);
    setTitle([t("sidebar.department")]);
  }, [i18n.language]);

  return (
    <Routes>
      <Route index path="/" element={<Department />} />
      <Route path="/add_department" element={<AddDepartment />} />
      <Route path="/edit_department/:id" element={<AddDepartment />} />
      <Route path="/work_plan/:id" element={<CalendarWiev />} />
    </Routes>
  );
};

export default DepartmentRoutes;
