import { createContext, useContext, useState } from "react";
import { useAuthContext } from "./UserContext";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const { profil } = useAuthContext();
  const [title, setTitle] = useState();

  const [isEditMode, setIsEditMode] = useState(
    profil?.isSuperAdmin === 0
      ? JSON.parse(localStorage.getItem("editMode"))
      : true
  );
  const [selectCompany, setSelectCompany] = useState(
    profil?.isSuperAdmin === 0
      ? JSON.parse(localStorage.getItem("selectCompany"))
      : profil
  );

  const [selectData, setSelectData] = useState(null);

  const values = {
    title,
    setTitle,
    isEditMode,
    setIsEditMode,
    selectCompany,
    setSelectCompany,
    selectData,
    setSelectData,
  };

  return (
    <NotificationContext.Provider value={values}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotificationContext = () => useContext(NotificationContext);

export { NotificationProvider, useNotificationContext };
