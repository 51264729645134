import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { forgotPasswordSchema } from "../../../helpers/FormSchemas/FormErrorMessage";
import { postOrPutRequestHttps } from "../../../libs/AllGetRequest/HttpsHelper";
import { toast } from "react-toastify";

const ForgotPassword = ({ setOpenModal2 }) => {
  const [t] = useTranslation("global");

  const [loading, setLoading] = useState(false);

  const sumbitFunction = async (values, setSubmitting, reset) => {
    setSubmitting(false);
    console.log("e", values);
    setLoading(true);

    try {
      const response = await postOrPutRequestHttps("v1/forgot-password", "POST", null, values)
      setOpenModal2(false);
      reset();
      toast.success("Din anmodning om nulstilling af adgangskode er blevet sendt.");
    } catch (error) {
      console.log(error);
      toast.error("Der er opstået en fejl. Tjek venligst din e-mailadresse.");
    }
    finally{
      setLoading(false);
    }
  };

  return (
    <div>
      <p className="font-semibold text-lg tracking-wider text-center text-[#858F91] capitalize mb-1">
        {t("general.reset_password")}
      </p>
      <Formik
        validationSchema={forgotPasswordSchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={{ email: "" }}
      >
        {() => {
          return (
            <Form
              className="flex flex-col h-full w-full"
              style={{ fontFamily: "Poppins" }}
            >
              <div>
                <div className="flex flex-col">
                  <label htmlFor="email" className="formTitle required">
                    {t("staff.staff_email")}
                  </label>
                  <div>
                    <Field name="email" className="formInput" />
                    <div className="text-red-600">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-5 gap-3">
                <button
                  onClick={() => setOpenModal2(false)}
                  className="border-[#407BFF] border font-semibold text-base xl:text-lg text-[#407BFF] px-5 py-1 rounded-lg hover:bg-[#3db5e4] hover:border-[#3db5e4] hover:text-white duration-300 relative flex"
                >
                  {t("general.cancel")}
                </button>
                <button
                  type="submit"
                  className="bg-[#407BFF] flex items-center font-semibold text-base xl:text-lg text-white px-5 py-1 rounded-lg hover:bg-[#3db5e4] duration-300"
                >
                  {t("general.send")}
                  {loading ? (
                    <AiOutlineLoading3Quarters className="ml-4 animate-spin  right-8 top-4" />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
