import { Modal, Popconfirm, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import {
  deleteRequestHttps,
  getRequestHttps,
} from "../../../libs/AllGetRequest/HttpsHelper";
import TableComponents from "../../Table/TableComponents";
import { LeaveIcon, TrashIcon,PencilIcon } from "../../icons/General";
import CompanyLeaveForm from "./CompanyInfo/CompanyLeaveForm";

const CompanyLeave = () => {
  const [t, i18n] = useTranslation("global");
  const { isEditMode, selectCompany } = useNotificationContext();

  const [tableColumn, setTableColumn] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leaveList, setLeaveList] = useState([]);

  const getLeaveList = async () => {
    setLoading(true);
    let data;
    try {
      data = await getRequestHttps("v1/break-labels", {
        company_id: selectCompany?.company_id,
      });
      setLeaveList(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeaveList();
  }, [selectCompany?.company_id]);

  const handleDelete = async (id) => {
    toast.promise(
      deleteRequestHttps(
        `v1/break-labels`,
        {},
        {
          company_id: selectCompany?.company_id,
          id: id,
        }
      ),
      {
        pending: t("crud.general.deleting"),
        success: {
          onClose: () => {
            getLeaveList();
          },
          autoClose: 1000,
          render: t("crud.general.deleted"),
        },
        error: t("super_admin.add_error"),
      }
    );
  };

  const columns = [
    {
      title: t("language.en"),
      dataIndex: "label_en",
      render: (text) => <div className="py-0 tableRowText">{text}</div>,
    },
    {
      title: t("language.da"),
      dataIndex: "label_x",
      render: (text) => <div className="py-0 tableRowText">{text}</div>,
    },
    {
      title: t("language.tr"),
      dataIndex: "label_tr",
      render: (text) => <div className="py-0 tableRowText">{text}</div>,
    },
    {
      title: t("general.actions"),
      dataIndex: "id",
      width: "7%",
      render: (_, { title, id }) => {
        return (
          <div className="flex text-[#858F91] gap-3 items-center">
            <Tooltip title={`${t("general.delete")} `}>
              <Popconfirm
                title={() => <p className="capitalize"> {title} </p>}
                description={t("general.confirm_delete")}
                onConfirm={() => handleDelete(id)}
                placement="topLeft"
                icon={
                  <FaExclamationCircle
                    color="red"
                    className="mr-2 mt-[2px]"
                    size={18}
                  />
                }
                okType="danger"
              >
                <div className="tableActionIcon">
                  <TrashIcon />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   dataIndex: "id",
    //   width: "2%",
    //   title: (
    //     <Tooltip title={t("companies.edit_leave")} placement="topLeft">
    //       <div
    //         role="button"
    //         onClick={() => setOpenModal(true)}
    //         className="bg-[#bdbdbd] p-1 rounded-lg hover:bg-[#cecccc] cursor-pointer"
    //       >
    //         <LeaveIcon width={23} height={23} />
    //       </div>
    //     </Tooltip>
    //   ),
    //   dataIndex: "",
    //   width: "1%",
      
    // },
  ];

  useEffect(() => {
    const newColumns = isEditMode ? columns : columns.slice(0, -2);
    setTableColumn(newColumns);
  }, [isEditMode, i18n.language]);

  return (
    <div className="mt-5 pl-4 pr-5">
      {" "}
      <Tooltip title={t("companies.add_leave")} placement="topLeft">
          <div
            role="button"
            onClick={() => setOpenModal(true)}
            className="leaveEditButton bg-[#3248F2] row p-1 rounded-lg  cursor-pointer"
          >
          <label className="buttonLabel">{t("companies.add_leave")} </label>  
                  <div className="editIconLeave"> <PencilIcon  width={20} height={20} /> </div> 
          </div>
        </Tooltip>
      <TableComponents
        title={false}
        bordered={false}
        // scroll={{
        //   x: 1260,
        // }}
        loading={loading}
        size="large"
        pagination={false}
        columns={tableColumn}
        data={leaveList}
        // virtual
      />
      <Modal
        title={false}
        centered
        open={openModal}
        // open={true}
        // onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        footer={false}
        width={800}
      >
        <CompanyLeaveForm
          getLeaveList={getLeaveList}
          selectCompany={selectCompany}
          setOpenModal={setOpenModal}
        />
      </Modal>
    </div>
  );
};

export default CompanyLeave;
