import React, { useState } from 'react';
import { Modal, Button } from 'antd'; // Ant Design Modal ve Button bileşenleri
import styles from './MessageModal.module.css'; // CSS dosyası
import { useTranslation } from "react-i18next";

const MessageModal = ({ onSendMessage, openModal, setOpenModal }) => {
    const [message, setMessage] = useState('');
    const [t, i18n] = useTranslation("global");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim()) {
            onSendMessage(message);
            setMessage('');
            setOpenModal(false); // Mesaj gönderildikten sonra modalı kapat
        }
    };

    const closeModal = () => {
        setOpenModal(false); // Modalı kapatır
    };

    return (
        <Modal
            style={{ zIndex: 100 }}
            title={t("general.submit_message")}
            centered
            open={openModal} // Modal açılma durumu
            onCancel={closeModal} // Modal dışına tıklayınca veya kapatma butonuna basınca kapatma
            footer={null}
            width={600}
        >
            <div className={styles.modalContent}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formGroup}>
                        <textarea
                            className={styles.messageInput}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder={t("general.write_message")}
                        />
                    </div>
                    <div className={styles.buttonGroup}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={styles.sendButton}
                        >
                            {t("general.send")}
                        </Button>
                        {/* <Button onClick={closeModal} className={styles.cancelButton}>
                            İptal
                        </Button> */}
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default MessageModal;
