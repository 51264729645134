import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Popconfirm, Switch, Upload } from "antd";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/UserContext";
import { addCompaniesSchema } from "../../../helpers/FormSchemas/FormErrorMessage";
import { inits } from "../../../helpers/FormSchemas/FormSchemas";
import {
  ChangeLatlong,
  ChangeTier2Status,
  MultiSelectField,
  SwitchField,
  beforeUpload,
  getBase64,
} from "../../../helpers/Function/Function";
import {
  getRequestHttps,
  postOrPutRequestHttps,
} from "../../../libs/AllGetRequest/HttpsHelper";
import BranchMap from "../Branches/BranchMap";
import CitySearchList from "../Branches/CitySearchList";

const AddCompanies = () => {
  const { profil } = useAuthContext();
  const [t] = useTranslation("global");
  const { id } = useParams();
  const navigate = useNavigate();
  

  const citySearch = "https://nominatim.openstreetmap.org/search?";

  const [detailValue, setDetailValue] = useState({
    company_logo: "",
    company_name: "",
    company_description: "",
    country: "",
    company_address: "",
    lat: "",
    long: "",
    radius: 250,

    manager: [],
    is_active: false,
    tax_number: "",
    max_stuff_count: "",

    branch: false,
    branch_label: "",
    department: false,
    department_label: "",
    location_tracking: false,

    manager_name: "",
    manager_email: "",
    manager_password: "",
    manager_confirm_password: "",
    manager_phone: "",
  });

  const [managerOptions, setManagerOptions] = useState([]);
  const [selectManager, setSelectManager] = useState();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  const [previewOpen, setPreviewOpen] = useState(false);

  const [openList, setOpenList] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [cityPosition, setCityPosition] = useState();

  const [station, setStation] = useState(id ? 1 : 0);

  const tabsArray = [
    {
      id: 0,
      title: t("manager.add"),
    },
    { id: 1, title: t("manager.select") },
  ];

  const fetcher = async (search) => {
    setLoading(true);
    const params = {
      q: search,
      format: "json",
      addressdetails: 1,
      polygon_geojson: 0,
    };
    const queryString = new URLSearchParams(params).toString();

    try {
      const data = await axios(`${citySearch}${queryString}`);
      setCityList(data?.data);
    } finally {
      setLoading(false);
    }
  };

  const getDetailData = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/Company-detail`, {
        company_id: serial,
      });

      const formValue = {
        company_logo: data?.logo || "",
        company_name: data?.name || "",
        company_description: data?.description || "",
        country: "",
        company_address: data?.address || "",
        lat: data?.lat_long?.lat,
        long: data?.lat_long?.long,
        radius: data?.lat_long?.radius || 250,

        is_active: data?.is_active,
        tax_number: data?.tax_number,
        max_stuff_count:
          data?.max_stuff_count > 0 ? data?.max_stuff_count : 1000,

        branch: data?.branch_label ? true : false,
        branch_label: data?.branch_label || t("sidebar.branches"),
        department: data?.department_label ? true : false,
        department_label: data?.department_label || "Department",
        location_tracking: data?.location_tracking ? true : false,

        manager:
          data?.manager_ids?.length > 0
            ? data?.manager_ids
            : data?.manager_user_id || [],
        // manager_name: data?.manager || "",
        // manager_email: data?.manager_email || "",
        // manager_password: "",
        // manager_confirm_password: "",
        // manager_phone: data?.manager_phone || "",
      };
      setDetailValue(formValue);
    } catch (error) { }
  };

  const getUsers = async () => {
    let data;

    try {
      data = await getRequestHttps("v1/user", {}, "result");
      const userList = data
        ?.filter((user) => user?.type_id === 1)
        ?.map((e, i) => {
          return {
            label: e?.email,
            value: e?.id,
          };
        });
      setManagerOptions(userList);
    } catch (e) { }
  };

  useEffect(() => {
    getUsers();
    if (id) {
      getDetailData(id);
    }
  }, [id]);

  const submitFunction = async (e, setSubmitting, resetForm) => {
    console.log("e", e);
    const planIdValue = licenceOptions.find(f=>f.value==e?.max_stuff_count).planId;
    const formData = {
      company_logo: e?.company_logo,
      company_name: e?.company_name,
      company_description: e?.company_description,
      company_address: e?.company_address,
      department_label: e?.department ? e?.department_label : null,
      branch_label: e?.branch ? e?.branch_label : null,
      location_tracking: e?.location_tracking || false,

      is_active: e?.is_active,
      tax_number: e?.tax_number,
      max_stuff_count: e?.max_stuff_count,
      subscriptionPlanId:planIdValue,
      manager_user_id: station && e?.manager?.length > 0 ? e?.manager : null,

      manager_name: (station === 0 && e?.manager_name) || null,
      manager_email: (station === 0 && e?.manager_email) || null,
      manager_password: (station === 0 && e?.manager_password) || null,
      manager_phone: (station === 0 && e?.manager_phone) || null,
      lat_long: {
        lat: e?.lat,
        long: e?.long,
        radius: e?.radius,
      },
    };
    console.log("formData", formData);

    const updateForm = {
      name: e?.company_name,
      tax_number: e?.tax_number,
      max_stuff_count: e?.max_stuff_count,
      description: e?.company_description,
      address: e?.company_address,
      is_active: e?.is_active,
      logo: e?.company_logo,
      department_label: e?.department ? e?.department_label : null,
      branch_label: e?.branch ? e?.branch_label : null,
      location_tracking: e?.location_tracking,
      lat_long: {
        lat: e?.lat,
        long: e?.long,
        radius: e?.radius,
      },
      manager_user_id: station && e?.manager?.length > 0 ? e?.manager : null,
      id: +id,
    };

    !id
      ? toast.promise(
        postOrPutRequestHttps("v1/createCompany", "POST", formData),
        {
          pending: t("crud.general.adding"),
          success: {
            onClose: () => {
              navigate("/companies");
            },
            autoClose: 1000,
            render: t("crud.general.added"),
            // onClose: () => navigate(`/`),
          },
          error: t("super_admin.add_error"),
        }
      )
      : toast.promise(
        postOrPutRequestHttps(`v1/company-edit`, "PUT", updateForm, {
          company_id: id,
        }),
        {
          pending: t("crud.general.updating"),
          success: {
            onClose: () => {
              navigate("/companies");
            },
            autoClose: 1000,
            render: t("crud.general.updated"),
          },
          error: t("super_admin.add_error"),
        }
      );
    setSubmitting(false);
  };

  const handleChange = (info, setFieldValue) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "removed") {
      setFieldValue("company_logo", "");
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setFieldValue("company_logo", url);
      });
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const licenceOptions = [
    { value: 1,planId:1, label: t("license.beginner") },
    { value: 5,planId:2, label: t("license.standart") },
    { value: 15,planId:3 ,label: t("license.advanced") },
    { value: 1000,planId:4, label: t("license.pro") },
    { value: 10000,planId:5, label: t("license.free") }
  ];

  const confirm = (setFieldValue, values) => {
    setFieldValue("is_active", !values);
  };

  return (
    <div className="mt-5 ml-10 mr-5 p-5 bg-[#ffffff] flex flex-col items-center">
      <p className="font-semibold text-lg tracking-wider">
        {id ? t("companies.edit_company") : t("companies.add_company")}
      </p>
      <Formik
        validationSchema={addCompaniesSchema(t, id, station)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          submitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize={true}
        initialValues={id ? detailValue : inits.addCompanies}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form
              className="flex flex-col h-full w-full justify-between"
              style={{ fontFamily: "Poppins" }}
            >
              {/* <CatchBranchValue
                options={managerOptions}
                setSelectItem={setSelectManager}
                id={id}
                setFieldValue={setFieldValue}
                field={"manager"}
                value={values?.manager}
              /> */}
              <ChangeLatlong
                lat={values?.lat}
                long={values?.long}
                setCityPosition={setCityPosition}
              />
              <ChangeTier2Status
                field={"department"}
                value={values?.branch}
                setValue={values?.department}
                setFieldValue={setFieldValue}
              />
              <p className="py-3 border-b mb-3 text-xl text-[#595e5fb3] font-semibold capitalize">
                {t("company.company_info")}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-5 gap-y-3 xl:gap-x-30 ">
                <div className="col-span-2 md:col-span-1">
                  <label htmlFor="company_logo" className="formTitle ">
                    {t("crud.company.logo")}
                  </label>
                  <div>
                    <Field name="company_logo" className="formInput">
                      {() => {
                        return (
                          <>
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              //   className="avatar-uploader"
                              showUploadList={false}
                              accept=".png, .jpeg, .jpg, .svg"
                              customRequest={({ file, onSuccess }) => {
                                setTimeout(() => {
                                  onSuccess("ok");
                                }, 0);
                              }}
                              maxCount={1}
                              beforeUpload={beforeUpload}
                              onPreview={() => setPreviewOpen(true)}
                              onChange={(e) => handleChange(e, setFieldValue)}
                            >
                              {values.company_logo ? (
                                <img
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={values.company_logo}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                            <Modal
                              open={previewOpen}
                              //   title={previewTitle}
                              footer={null}
                              onCancel={() => setPreviewOpen(false)}
                            >
                              <img
                                alt="example"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                                src={values.company_logo}
                              />
                            </Modal>
                          </>
                        );
                      }}
                    </Field>
                    <div className="text-red-600">
                      <ErrorMessage name="company_logo" />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 col-span-4 gap-y-3 xl:gap-x-30 gap-x-5">
                  {/* <div>
                    <label
                      htmlFor="company_name"
                      className="formTitle required"
                    >
                      {t("crud.company.company_name")}
                    </label>
                    <div>
                      <Field
                        name="company_name"
                        className="formInput disabled:bg-[#c4c1c1]"
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="company_name" />
                      </div>
                    </div>
                  </div> */}
                  <div className="col-span-1 grid grid-cols-5 gap-y-3 xl:gap-x-30 gap-x-5">
                    {profil.userType === 0 && (
                      <div className="col-span-1 xxl:col-span-1 flex flex-col items-start gap-5">
                        <label htmlFor="is_active" className="formTitle mt-0">
                          {t("general.isActive")}
                        </label>{" "}
                        {/* <Field
                          name="is_active"
                          style={{
                            backgroundColor: values?.is_active
                              ? "#b2db9e"
                              : "#cf132f",
                          }}
                          checked={values?.is_active}
                          checkedChildren={t("general.active")}
                          unCheckedChildren={t("general.passive")}
                          component={SwitchField}
                        /> */}
                        <Popconfirm
                          title={t("general.isActive")}
                          description={
                            values.is_active
                              ? t("crud.company.passive")
                              : t("crud.company.active")
                          }
                          onConfirm={() =>
                            confirm(setFieldValue, values.is_active)
                          }
                          okType="danger"
                        >
                          <Switch
                            name="is_active"
                            style={{
                              backgroundColor: values?.is_active
                                ? "#b2db9e"
                                : "#cf132f",
                            }}
                            checked={values?.is_active}
                            checkedChildren={t("general.active")}
                            unCheckedChildren={t("general.passive")}
                            component={SwitchField}
                          />
                        </Popconfirm>
                        <div className="text-red-600">
                          <ErrorMessage name="is_active" />
                        </div>
                      </div>
                    )}
                    <div
                      className={`${profil.userType === 0 ? "col-span-4" : "col-span-5"
                        }`}
                    >
                      <label
                        htmlFor="company_name"
                        className="formTitle required"
                      >
                        {t("crud.company.company_name")}
                      </label>
                      <div>
                        <Field
                          name="company_name"
                          className="formInput disabled:bg-[#c4c1c1]"
                        />
                        <div className="text-red-600">
                          <ErrorMessage name="company_name" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="tax_number" className="formTitle required">
                      {t("crud.company.tax_number")}
                    </label>
                    <div>
                      <Field
                        name="tax_number"
                        className="formInput disabled:bg-[#c4c1c1]"
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="tax_number" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="max_stuff_count" className="formTitle">
                      {t("crud.company.license")}
                    </label>
                    <div>
                      <Field
                        name="max_stuff_count"
                        component={MultiSelectField}
                        options={licenceOptions}
                        className="formInput disabled:bg-[#c4c1c1]"
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="max_stuff_count" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="company_description" className="formTitle">
                      {t("general.description")}
                    </label>
                    <div>
                      <Field
                        name="company_description"
                        className="formInput disabled:bg-[#c4c1c1]"
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="company_description" />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 grid grid-cols-5 gap-y-3 xl:gap-x-30 gap-x-5">
                    <div className="col-span-1 xxl:col-span-1 flex flex-col items-start gap-5">
                      <label htmlFor="branch" className="formTitle mt-0">
                        {t("general.tier1")}
                      </label>{" "}
                      <Field
                        name="branch"
                        style={{
                          minWidth: "70px",
                          backgroundColor: values?.branch
                            ? "#407BFF"
                            : "#acb3b5",
                        }}
                        checked={values?.branch}
                        checkedChildren={t("general.open_edit")}
                        unCheckedChildren={t("general.close_edit")}
                        component={SwitchField}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="branch" />
                      </div>
                    </div>
                    <div className="col-span-4 ">
                      <label htmlFor="branch_label" className="formTitle">
                        {t("general.tier1")}
                      </label>
                      <div>
                        <Field
                          name="branch_label"
                          className={`formInput cursor-not-allowed ${!values.branch && "bg-gray-400"
                            }`}
                          // disabled={!values.branch}
                          disabled
                        />
                        <div className="text-red-600">
                          <ErrorMessage name="branch_label" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 grid grid-cols-5 gap-y-3 xl:gap-x-30 gap-x-5">
                    <div className="col-span-1 xxl:col-span-1 flex flex-col items-start gap-5">
                      <label htmlFor="department" className="formTitle mt-0">
                        {t("general.tier2")}
                      </label>{" "}
                      <Field
                        name="department"
                        style={{
                          minWidth: "70px",
                          backgroundColor: values?.department
                            ? "#407BFF"
                            : "#acb3b5",
                        }}
                        disabled={!values?.branch}
                        checked={values?.department}
                        checkedChildren={t("general.open_edit")}
                        unCheckedChildren={t("general.close_edit")}
                        component={SwitchField}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="department" />
                      </div>
                    </div>
                    <div className="col-span-4 ">
                      <label htmlFor="department_label" className="formTitle">
                        {t("general.tier2")}
                      </label>
                      <div>
                        <Field
                          name="department_label"
                          className={`formInput cursor-not-allowed ${!values.department && "bg-gray-400"
                            }`}
                          // disabled={!values.department}
                          disabled
                        />
                        <div className="text-red-600">
                          <ErrorMessage name="department_label" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-5 grid grid-cols-1 md:grid-cols-10 gap-y-3 xl:gap-x-30 gap-x-5">
                  <div className="col-span-1 md:col-span-3 relative">
                    <label htmlFor="company_address" className="formTitle">
                      {t("general.address")}
                    </label>
                    <div className="relative">
                      <Field
                        name="company_address"
                        className="formInput"
                      //   options={EncryptionOptions}
                      //   component={MultiSelectField}
                      />
                      <button
                        type="button"
                        disabled={values?.address?.length < 3}
                        onClick={() => {
                          setOpenList(true);
                          fetcher(values.company_address);
                        }}
                        className="absolute top-2 rounded-r-lg right-0 w-20 items-center flex justify-center h-12 bg-gray-300 disabled:bg-gray-400 cursor-pointer"
                      >
                        {t("general.search")}
                      </button>
                      <div className="text-red-600">
                        <ErrorMessage name="address" />
                      </div>
                    </div>
                    <>
                      <div
                        className={`w-full bg-[#f3f2f2] absolute z-10 rounded-xl shadow-md duration-300 overflow-y-auto ${openList ? "h-40" : "h-0"
                          }`}
                      >
                        <div className="absolute right-3 top-3">
                          <button
                            className="duration-300 text-[#858F91] cursor-pointer hover:text-[#407BFF]"
                            type="button"
                            onClick={() => setOpenList(false)}
                          >
                            <RxCross1 />
                          </button>
                        </div>
                        <CitySearchList
                          setFieldValue={setFieldValue}
                          cityList={cityList}
                          setCityList={setCityList}
                          setCityPosition={setCityPosition}
                          setOpenList={setOpenList}
                          loading={loading}
                        />
                      </div>
                    </>
                  </div>
                  <div className="col-span-1 col md:col-span-3">
                    <label htmlFor="lat" className="formTitle required">
                      {t("general.lat")}
                    </label>
                    <div>
                      <Field name="lat" className="formInput" type="number" />
                      <div className="text-red-600">
                        <ErrorMessage name="lat" />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 md:col-span-3">
                    <label htmlFor="long" className="formTitle required">
                      {t("general.long")}
                    </label>
                    <div>
                      <Field name="long" className="formInput" type="number" />
                      <div className="text-red-600">
                        <ErrorMessage name="long" />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 md:col-span-1">
                    <label htmlFor="radius" className="formTitle">
                      {t("general.radius")}
                    </label>
                    <div>
                      <Field
                        name="radius"
                        className="formInput"
                        type="number"
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="radius" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-96">
                <BranchMap
                  radius={values?.radius}
                  setCityPosition={setCityPosition}
                  cityPosition={cityPosition}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="col-span-1 xxl:col-span-1 flex flex-col items-end gap-2 mt-4">
                <label htmlFor="location_tracking" className="formTitle mt-0">
                  {t("general.location_tracking")}
                </label>{" "}
                <Field
                  name="location_tracking"
                  style={{
                    minWidth: "70px",
                    backgroundColor: values?.location_tracking
                      ? "#407BFF"
                      : "#acb3b5",
                  }}
                  checked={values?.location_tracking}
                  checkedChildren={t("general.open_edit")}
                  unCheckedChildren={t("general.close_edit")}
                  component={SwitchField}
                />
                <div className="text-red-600">
                  <ErrorMessage name="department" />
                </div>
              </div>

              <div className="mb-5 mt-3 border-b">
                <p className="py-2 text-xl text-[#595e5fb3] font-semibold capitalize">
                  {t("companies.manager_info")}
                </p>
                {!id && (
                  <div className="flex gap-2 md:gap-5 text-sm md:text-base">
                    {tabsArray?.map(({ id, title }, index) => (
                      <div
                        key={index}
                        onClick={() => setStation(id)}
                        className="flex flex-col items-center group"
                      >
                        <p
                          className={`font-semibold group-hover:text-[#009BDA] capitalize cursor-pointer ease-linear duration-300 ${id === station
                            ? "text-[#009BDA]"
                            : "text-[#858f91b3]"
                            }`}
                          key={index}
                        >
                          {title}
                        </p>
                        <div
                          className={`w-[80%] ease-linear duration-300  group-hover:border-b-[1px] group-hover:border-[#009BDA] ${id === station
                            ? "border-b-[1px] border-[#009BDA] "
                            : ""
                            }`}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {station ? (
                <div className="w-full md:w-[50%] lg:w-[25%]">
                  <label htmlFor="manager" className="formTitle required">
                    {t("companies.manager")}
                  </label>{" "}
                  <Field
                    name="manager"
                    // as="select"
                    mode="multiple"
                    options={managerOptions}
                    className="formInput"
                    optionFilterProp="label"
                    search
                    component={MultiSelectField}
                  />
                  <div className="text-red-600">
                    <ErrorMessage name="manager" />
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 xl:gap-x-30 gap-x-5 ">
                  <div className="col-span-2 md:col-span-1">
                    <label
                      htmlFor="manager_name"
                      className="formTitle required"
                    >
                      {t("manager.name")}
                    </label>
                    <div>
                      <Field
                        name="manager_name"
                        className="formInput disabled:bg-[#c4c1c1]"
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="manager_name" />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <label
                      htmlFor="manager_email"
                      className="formTitle required"
                    >
                      {t("manager.email")}
                    </label>
                    <div>
                      <Field
                        name="manager_email"
                        className="formInput disabled:bg-[#c4c1c1]"
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="manager_email" />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 col-span-2 gap-y-3 xl:gap-x-30 gap-x-5">
                    <div>
                      <label
                        htmlFor="manager_password"
                        className={`formTitle ${id ? "" : "required"}`}
                      >
                        {t("general.password")}
                      </label>
                      <div>
                        <div className="relative">
                          <Field
                            type={showPassword.password ? "text" : "password"}
                            name="manager_password"
                            className="formInput"
                          />
                          <span
                            className="absolute right-3 top-[45%] cursor-pointer"
                            onClick={() =>
                              setShowPassword((prev) => {
                                return { ...prev, password: !prev.password };
                              })
                            }
                          >
                            {showPassword.password ? (
                              <RiEyeLine />
                            ) : (
                              <RiEyeCloseLine />
                            )}
                          </span>
                        </div>
                        <div className="text-red-600">
                          <ErrorMessage name="manager_password" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="manager_confirm_password"
                        className={`formTitle ${id ? "" : "required"}`}
                      >
                        {t("crud.personnel.confirm_password")}
                      </label>
                      <div>
                        <div className="relative">
                          <Field
                            type={showPassword.confirm ? "text" : "password"}
                            name="manager_confirm_password"
                            className="formInput"
                          />
                          <span
                            className="absolute right-3 top-[45%] cursor-pointer"
                            onClick={() =>
                              setShowPassword((prev) => {
                                return { ...prev, confirm: !prev.confirm };
                              })
                            }
                          >
                            {showPassword.confirm ? (
                              <RiEyeLine />
                            ) : (
                              <RiEyeCloseLine />
                            )}
                          </span>
                        </div>
                        <div className="text-red-600">
                          <ErrorMessage name="manager_confirm_password" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="manager_phone" className="formTitle">
                        {t("general.phone")}
                      </label>
                      <div>
                        <Field
                          name="manager_phone"
                          className="formInput disabled:bg-[#c4c1c1]"
                        />
                        <div className="text-red-600">
                          <ErrorMessage name="manager_phone" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end mt-5">
                <button
                  type="submit"
                  className="bg-[#407BFF] font-semibold text-base xl:text-lg text-white xl:px-20 px-5 py-3 rounded-xl hover:bg-[#3db5e4] duration-300 relative flex"
                >
                  {t("general.save")}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddCompanies;
