import * as React from "react";
import MyDatePicker from "./DatePicker";

const TimePicker = React.forwardRef((props, ref) => (
  <MyDatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

TimePicker.displayName = "TimePicker";

export default TimePicker;
