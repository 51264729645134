import axios from "axios";
import React, { useState, useEffect } from "react";
import { verifyCompanyCodeHttps } from "../libs/AllGetRequest/HttpsHelper";
import '../styles/MailVerification.css';

export const MailVerification = ({ email, onVerified }) => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(true);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes

  useEffect(() => {
    if (isCodeSent && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isCodeSent, timeLeft]);

  const handleSendCode = async () => {
    try {
      await axios.post('https://your-api-endpoint/send-code', { email });
      setMessage('Verification code was sent. Please check your email.');
      setIsCodeSent(true);
      setTimeLeft(300); // Restart timer
    } catch (error) {
      setMessage('An error occurred while sending the verification code.');
      console.error(error.message);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      const verifyMailCodeRequestModel = { Email: email, VerifyCode: code };
      const response = await verifyCompanyCodeHttps("v1/verify-mail-code", "POST", verifyMailCodeRequestModel);
      if (response === "Verification Success") {
        setMessage('Verification successful!');
        onVerified(true); // Only call onVerified(true) on success
      } else {
        setMessage('Verification failed. Please try again.');
        // Do not close the modal on failure
      }
    } catch (error) {
      setMessage('The code you entered is incorrect.');
      console.error(error.message);
      // Do not close the modal on error
    }
  };

  return (
    <div>
      {!isCodeSent ? (
        <div>
          <button className="send-btn" onClick={handleSendCode}>Send Verification Code</button>
          {message && <p className="message">{message}</p>}
        </div>
      ) : (
        <form onSubmit={handleVerifyCode}>
          <input className="code-input"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter verification code"
            required
          />
          <button type="submit" className="verify-btn">Verify</button>
          {message && <p className="message">{message}</p>}
          {timeLeft > 0 ? (
            <p className="timer">Time left: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}</p>
          ) : (
            <p className="expired">Verification code expired. Please request a new code.</p>
          )}
        </form>
      )}
    </div>
  );
};

export default MailVerification;
