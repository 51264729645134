import { Modal, Tag } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { RxCheck, RxCross2 } from "react-icons/rx";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../../context/NotifacitionContext";
import { useAuthContext } from "../../../../context/UserContext";
import { manuelLeaveChangeStatus } from "../../../../helpers/FormSchemas/FormErrorMessage";
import { MultiSelectField } from "../../../../helpers/Function/Function";
import { getRequestHttps } from "../../../../libs/AllGetRequest/HttpsHelper";
import TableComponents from "../../../Table/TableComponents";

const PersonelLeaveTable = ({ getPersonelDetail, data, leaveList }) => {
  const [t, i18n] = useTranslation("global");
  const { profil } = useAuthContext();
  const { selectCompany } = useNotificationContext();
  const { id } = useParams();

  const [tableColumn, setTableColumn] = useState("");

  const [openModel, setOpenModel] = useState(false);
  const [leaveValue, setLeaveValue] = useState();

  const sumbitFunction = (e, setSubmitting, reset) => {
    // console.log("e", e);

    const formData = {
      company_id: selectCompany?.company_id,
      user_id: +id,
      status: e.status,
      id: e?.changeLeaveId,
      leave_type_id: e?.leave_type_id || null,
    };

    if (
      e?.status === e?.old_status &&
      (e?.old_leave_type_id === e?.leave_type_id ||
        e?.leave_type_id === e?.old_leave_type_name)
    ) {
      toast.warning(t("general.noChanges"));
      return;
    }

    // console.log("formData", formData);
    toast.promise(
      getRequestHttps("v1/confirm-break", {
        company_id: selectCompany?.company_id,
        user_id: +id,
        status: e.status,
        id: e?.changeLeaveId,
        leave_type_id:
          typeof e?.leave_type_id === "string"
            ? e?.old_leave_type_id
            : e?.leave_type_id || null,
      }),
      {
        pending: t("general.sending"),
        success: {
          onClose: () => {
            setOpenModel(false);
            getPersonelDetail(id);
          },
          autoClose: 1000,
          render: t("general.send"),
        },
        error: t("super_admin.add_error"),
      }
    );

    setSubmitting(false);
  };

  const onApprove = (status, leaveId) => {
    const formData = {
      id: +id,
      status: status,
      company_id: selectCompany?.company_id,
    };

    console.log("formData", formData);

    toast.promise(
      getRequestHttps("v1/confirm-break", {
        company_id: selectCompany?.company_id,
        user_id: +id,
        status: status,
        id: +leaveId,
        leave_type_id: null,
      }),
      {
        pending: t("general.sending"),
        success: {
          onClose: () => {
            getPersonelDetail(id);
          },
          autoClose: 1000,
          render: t("general.send"),
          // onClose: () => navigate(`/`),
        },
        error: t("super_admin.add_error"),
      }
    );
  };

  const columns = [
    {
      title: t("general.start_date"),
      dataIndex: "start_date",
      sorter: (a, b) => a.start_date - b.start_date,
      render: (last_seen) => {
        if (!last_seen) return <p className="tableRowText">Bulunamadı</p>;
        return (
          <div className="tableRowText">
            {moment(last_seen).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      title: t("general.end_date"),
      dataIndex: "end_date",
      sorter: (a, b) => a.end_date - b.end_date,
      render: (last_seen) => {
        if (!last_seen) return <p className="tableRowText">Bulunamadı</p>;
        return (
          <div className="tableRowText">
            {moment(last_seen).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      title: t("general.day"),
      dataIndex: "day",
      width: "10%",
      render: (text) => {
        return <div className="tableRowText lowercase"> {text}d </div>;
      },
    },
    {
      title: t("general.leave"),
      dataIndex: "description",
      render: (_, { label_en, label_tr, label_x }) => (
        <div className="tableRowText">
          {i18n.language === "tr"
            ? label_tr
            : i18n.language === "da"
              ? label_x
              : label_en}
        </div>
      ),
    },
    {
      title: t("staff.status"),
      dataIndex: "status",
      render: (
        _,
        { status, id, ids, label_en, label_tr, label_x, break_label_id }
      ) => (
        <>
          {profil?.isSuperAdmin === 2 ? (
            <Tag
              color={
                status === true ? "green" : status === false ? "red" : "yellow"
              }
              className="tableRowText"
            >
              {status === true
                ? t("staff.approved")
                : status === false
                  ? t("staff.denied")
                  : t("staff.pending")}
            </Tag>
          ) : status === null ? (
            <div className="flex gap-3 tableRowText">
              <RxCheck
                onClick={() => onApprove(true, id)}
                className="cursor-pointer hover:scale-125 duration-300"
                color="green"
                size={24}
              />
              <RxCross2
                onClick={() => onApprove(false, id)}
                className="cursor-pointer hover:scale-110 duration-300"
                color="red"
                size={24}
              />
            </div>
          ) : (
            <div className="flex items-center gap-5">
              <Tag
                color={status === true ? "green" : "red"}
                className="tableRowText"
              >
                {status === true ? t("staff.approved") : t("staff.denied")}
              </Tag>
              {ids === 0 ? (
                <div
                  onClick={() => {
                    setOpenModel(true);
                    setLeaveValue({
                      changeLeaveId: id,
                      status: status,
                      old_status: status,
                      old_leave_type_id: break_label_id,
                      old_leave_type_name:
                        i18n.language === "tr"
                          ? label_tr
                          : i18n.language === "da"
                            ? label_x
                            : label_en,
                      leave_type_id:
                        i18n.language === "tr"
                          ? label_tr
                          : i18n.language === "da"
                            ? label_x
                            : label_en,
                    });
                  }}
                >
                  <FaExchangeAlt />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const data = columns.filter((item) => {
      return item?.title;
    });
    setTableColumn(data);
  }, [i18n.language]);

  return (
    <div className="overflow-y-auto h-full rounded-lg min-h-[300px]">
      <Modal
        title=""
        centered
        open={openModel}
        // open={true}
        // onOk={() => setOpenModal(false)}
        onCancel={() => {
          setOpenModel(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => <></>}
      >
        <Formik
          validationSchema={manuelLeaveChangeStatus(t)}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            sumbitFunction(values, setSubmitting, resetForm);
          }}
          enableReinitialize={true}
          initialValues={leaveValue}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form
                className="flex flex-col h-full w-full justify-between"
                style={{ fontFamily: "Poppins" }}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 xl:gap-x-30 gap-x-5">
                  <div className="">
                    <label htmlFor="status" className="formTitle required">
                      {t("staff.status")}
                    </label>
                    <div>
                      <Field
                        name="status"
                        className="formInput"
                        options={[
                          { value: false, label: t("staff.denie") },
                          { value: true, label: t("staff.approve") },
                        ]}
                        component={MultiSelectField}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="status" />
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      selectCompany?.department ? "col-span-1" : "col-span-2"
                    }
                  >
                    <label
                      htmlFor="leave_type_id"
                      className="formTitle required"
                    >
                      {t("general.leave")}
                    </label>
                    <div>
                      <Field
                        name="leave_type_id"
                        className="formInput"
                        optionFilterProp="label"
                        options={leaveList}
                        component={MultiSelectField}
                      />
                      <div className="text-red-600">
                        <ErrorMessage name="leave_type_id" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-5">
                  <button
                    type="submit"
                    className="bg-[#407BFF] font-semibold text-base text-white xl:px-10 px-5 py-3 rounded-xl hover:bg-[#3db5e4] duration-300 relative flex"
                  >
                    {t("general.save")}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <TableComponents
        title={() => (
          <p className="capitalize text-[#53626f] font-semibold text-base md:text-lg">
            {t("staff_detail.permission")}
          </p>
        )}
        bordered={false}
        scroll={
          data?.length > 4
            ? {
              y: 350,
            }
            : {}
        }
        //   loading={loading}
        size="large"
        pagination={false}
        columns={tableColumn}
        data={data}
      // virtual
      />
    </div>
  );
};

export default PersonelLeaveTable;
