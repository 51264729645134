import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNotificationContext } from "../../../../../context/NotifacitionContext";
import { DatePicker, TimePicker } from "../../../../../helpers/AntDesignPicker";
import {
  MultiSelectField,
  checkDateOverlap,
  SwitchField,
} from "../../../../../helpers/Function/Function";
import {
  deleteRequestHttps,
  getRequestHttps,
  postOrPutRequestHttps,
  postOrPutHttps,
} from "../../../../../libs/AllGetRequest/HttpsHelper";
import Dashboard from "../../../../../helpers/Dashboard";
import { useAuthContext } from "../../../../../context/UserContext";

const WorkForm = ({
  calendarData,
  event,
  personnel,
  company,
  getDetailData,
  locationName,
}) => {
  const event_id = event?.id;
  const { selectCompany } = useNotificationContext();
  const [t, i18n] = useTranslation("global");
  const { id } = useParams();
  const navigate = useNavigate();
  const [branchOptions, setBranchOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [branchName, setBranchName] = useState();
  const [departmentName, setDepartmentName] = useState();
  const [workPlanOptions, setWorkPlanOptions] = useState([]);
  const [conflictOption, setConflictOption] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [handleConflictChoice, setHandleConflictChoice] = useState(null);
  const { profil } = useAuthContext();
  let [workPlanData, setWorkPlanData] = useState({
    isWorkPlan: false,
    name: '',
  });
  const [workDetail, setWorkDetail] = useState({
    company_id: company?.company_id || '',
    company_name: company?.company_name || '',
    branch_id: event?.branch_id || null,
    department_id: event?.department_id || null,
    start_date: event?.start ? moment(event.start).format("YYYY-MM-DD HH:mm") : moment().format("YYYY-MM-DD HH:mm"),
    end_date: event?.end ? moment(event.end).format("YYYY-MM-DD HH:mm") : moment().format("YYYY-MM-DD HH:mm"),
    break_start: event?.break_start || moment().startOf('hour'),
    break_end: event?.break_end || moment().startOf('hour'),
    type: event?.type,
    color: event?.color,
    description: event?.description
  });

  useEffect(() => {
    if (event) {
      setWorkDetail({
        company_id: company?.company_id || '',
        company_name: company?.company_name || '',
        branch_id: event?.branch_id || null,
        department_id: event?.department_id || null,
        start_date: event?.start ? moment(event.start).format("YYYY-MM-DD HH:mm") : moment().format("YYYY-MM-DD HH:mm"),
        end_date: event?.end ? moment(event.end).format("YYYY-MM-DD HH:mm") : moment().format("YYYY-MM-DD HH:mm"),
        break_start: event?.break_start || moment().startOf('hour'),
        break_end: event?.break_end || moment().startOf('hour'),
        type: event?.type,
        color: event?.color,
        description: event?.description
      });
      setWorkPlanData({
        isWorkPlan: false,
        name: '',
      });
    }
  }, [event]);

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const getData = async (endpoint, setOptions, setName = null) => {
    try {
      const data = await getRequestHttps(endpoint, {
        company_id: company?.company_id,
      });

      // locationName'e göre verileri filtrele
      if (locationName === "branches" ? endpoint === "v1/branch" : locationName === endpoint.split('/')[1] && setName) {
        const filteredData = data?.filter((e) => e.id === +id);
        setName(filteredData[0]?.name);
        console.log(`Filtered Data for ${endpoint}:`, filteredData);
        console.log(`Filtered Data Value:`, filteredData[0]?.name);
      } else {
        // Tüm verileri map ederek seçenekleri ayarla
        const options = data?.map((e) => ({
          label: e?.name,
          value: e?.id,
          lat: e?.lat_long?.lat,
          long: e?.lat_long?.long,
          radius: e?.lat_long?.radius,
          ...(endpoint === "v1/work-plan" && { // Ek alanlar yalnızca work-plan için
            company_id: e?.company_id,
            branch_id: e?.branch_id,
            department_id: e?.department_id,
            start: e?.start,
            end: e?.end,
            break_start: e?.break_start,
            break_end: e?.break_end,
            type: e?.type,
            color: e?.color,
            description: e?.description
          })
        }));
        setOptions(options);
        console.log(`Data from ${endpoint}:`, data);
        console.log(`Options for ${endpoint}:`, options);
      }
    } catch (e) {
      console.error(`Error fetching data from ${endpoint}:`, e);
    }
  };

  const getBranch = () => getData("v1/branch", setBranchOptions, setBranchName);
  const getDepartment = () => getData("v1/department", setDepartmentOptions, setDepartmentName);
  const getWorkPlan = () => getData("v1/work-plan", setWorkPlanOptions);

  useEffect(() => {
    getWorkPlan();
    if (company) {
      company?.department && getDepartment();
      company?.branch && getBranch();
    }
  }, [company]);

  const catchUrlPoint = (params) => {
    return locationName === "staff"
      ? "v1/user"
      : locationName === "department"
        ? "v1/department"
        : locationName === "branches"
          ? "v1/branch"
          : "";
  };

  const handleDateChange = (date, time, fieldName, setFieldValue) => {
    const newDateTime = date ? moment(date) : moment();
    if (time) {
      newDateTime.set('hour', time.hour()).set('minute', time.minute());
    }
    setFieldValue(fieldName, newDateTime);
  };

  const handleTimeChange = (time, fieldName, setFieldValue) => {
    setFieldValue(fieldName, time ? time.format("HH:mm") : null);
  }

  const handleDelete = (event_id) => {
    const formData = {
      company_id: selectCompany?.company_id,
      type: event?.type,
      ...(locationName === "staff"
        ? { user_id: +id }
        : locationName === "department"
          ? { department_id: +id }
          : locationName === "branches"
            ? { branch_id: +id }
            : null)
    };

    if (locationName === "staff") {
      let url;
      switch (event?.type) {
        case "company":
          url = `v1/delete-company-work-plan-for-user/${event_id}`;
          break;
        case "branch":
          url = `v1/delete-branch-work-plan-for-user/${event_id}`;
          break;
        case "department":
          url = `v1/delete-department-work-plan-for-user/${event_id}`;
          break;
        case "staff":
          url = `v1/user-work-plan/${event_id}`;
          break;
        default:
          return; // Uygun bir eventType yoksa işlemi sonlandır
      }

      if (event?.type !== "staff") {
        toast.promise(
          postOrPutRequestHttps(url, "PUT", formData, {
            company_id: selectCompany?.company_id,
            user_id: +id,
          }),
          {
            pending: t("crud.general.deleting"),
            success: {
              onClose: () => {
                getDetailData(id);  // Yeniden veri çekme
              },
              autoClose: 1000,
              render: t("crud.general.deleted"),
            },
            error: t("super_admin.add_error"),
          }
        );
      }

      if (event?.type === "staff") {
        toast.promise(
          deleteRequestHttps(url, "DELETE", formData, {
            company_id: selectCompany?.company_id,
          }),
          {
            pending: t("crud.general.deleting"),
            success: {
              onClose: () => {
                getDetailData(id);  // Yeniden veri çekme
              },
              autoClose: 1000,
              render: t("crud.general.deleted"),
            },
            error: t("super_admin.add_error"),
          }
        );
      }
    }

    const baseUrl = catchUrlPoint(); // Dinamik URL noktası belirleniyor

    // URL'nin son kısmını locationName'e göre oluştur
    let catchUrl = "";
    switch (locationName) {
      case "department":
        catchUrl = `v1/department-work-plan/${event_id}`;
        break;
      case "branches":
        catchUrl = `v1/branch-work-plan/${event_id}`;
        break;
      case "company":
        catchUrl = `v1/company-work-plan/${event_id}`;
        break;
      default:
        console.log("Geçersiz locationName");
        return; // Uygun bir locationName yoksa işlemi sonlandır
    }

    // Silme işlemi ve toast bildirimi
    if (locationName !== "staff") {
      toast.promise(
        deleteRequestHttps(catchUrl, "DELETE", formData, {
          company_id: selectCompany?.company_id,
        }),
        {
          pending: t("crud.general.deleting"),
          success: {
            onClose: () => {
              getDetailData(id); // Yeniden veri çekme
            },
            autoClose: 1000,
            render: t("crud.general.deleted"),
          },
          error: t("super_admin.add_error"),
        }
      );
    }

    console.log("Silinecek veri: ", formData);
    console.log("Event ID: ", event_id);
  }

  const sumbitFunction = async (values, setSubmitting, reset) => {

    const dynamicType = locationName === "company" ? "company" : locationName === "branches" ? "branch" : locationName === "department" ? "department" : "staff";
    const dynamicColor = locationName === "company" ? "gray" : locationName === "branches" ? "#f39393" : locationName === "department" ? "#b2db9e" : "#79b8e4";

    const checkBeforLeave = calendarData?.map((item) => {
      if (item.id !== event_id) {
        return checkDateOverlap(
          values.start_date,
          values.end_date,
          item.start,
          item.end
        );
      }
      return false;
    });

    if (checkBeforLeave?.some((item) => item === true)) {
      toast.warning(t("general.dateError"), {
        autoClose: 3000,
      });
      setSubmitting(false);
      return;
    }

    // Tüm takvim verileri birleştiriliyor
    const eventValue = {
      start: values.start_date,
      end: values.end_date,
      break_start: moment(values.break_start, "HH:mm").format("HH:mm:ss"),  // TimeSpan formatına uygun şekilde dönüştür
      break_end: moment(values.break_end, "HH:mm").format("HH:mm:ss"),
      type: dynamicType,
      color: dynamicColor,
      branch_id: locationName === "branches" ? +id : values.branch_id || null,
      department_id: locationName === "department" ? +id : values.department_id || null,
      description: values.description
    };

    const formData = {
      company_id: selectCompany?.company_id,
      ...eventValue,
      ...(locationName === "staff"
        ? { user_id: +id }
        : locationName === "department"
          ? { department_id: +id }
          : locationName === "branches"
            ? { branch_id: +id }
            : null),
    };

    // İş Planı için her gün ayrı bir id ile verileri gönderme
    if (workPlanData.name != '') {
      workPlanData = {
        ...workPlanData,
        ...eventValue,
        company_id: selectCompany?.company_id,
      }
      const request = postOrPutRequestHttps("v1/work-plan", "POST", workPlanData, {
        company_id: selectCompany?.company_id,
        user_id: profil.userId
      });

      toast.promise(request, {});
      console.log("Work Plan Data: ", workPlanData);
    }

    const baseUrlMap = {
      company: locationName === "staff" ? "v1/update-company-work-plan-for-user" : "v1/company-work-plan",
      branches: locationName === "staff" ? "v1/update-branch-work-plan-for-user" : "v1/branch-work-plan",
      department: locationName === "staff" ? "v1/update-department-work-plan-for-user" : "v1/department-work-plan",
      staff: values.type === "company" ? "v1/update-company-work-plan-for-user"
        : values.type === "branch" ? "v1/update-branch-work-plan-for-user"
          : values.type === "department" ? "v1/update-department-work-plan-for-user"
            : "v1/user-work-plan"
    }

    const baseData = {
      event_id: event_id,
      company_id: parseInt(selectCompany?.company_id),
      start: moment(eventValue?.start).toDate(),
      end: moment(eventValue?.end).toDate(),
      break_start: moment(eventValue?.break_start, "HH:mm").format("HH:mm:ss"),  // TimeSpan formatına uygun şekilde dönüştür
      break_end: moment(eventValue?.break_end, "HH:mm").format("HH:mm:ss"),
      type: eventValue?.type,
      color: eventValue?.color,
      description: eventValue?.description,
      ...(locationName === "staff" && {
        user_id: +id,
        branch_id: parseInt(eventValue?.branch_id),
        department_id: parseInt(eventValue?.department_id),
      }),
      ...(locationName === "branches" && { branch_id: +id }),
      ...(locationName === "department" && { department_id: +id }),
      ...(locationName === "staff" && event_id && {
        branch_name: eventValue?.branch_name,
        department_name: eventValue?.department_name
      })
    };

    const method = event_id ? "PUT" : "POST";
    const url = event_id ? `${baseUrlMap[locationName]}/${event_id}` : baseUrlMap[locationName];
    const successMessage = event_id ? t("crud.general.updated") : t("crud.general.added");
    const pendingMessage = event_id ? t("crud.general.updating") : t("crud.general.adding");
    console.log("Event Type: ", eventValue?.type);

    try {
      await toast.promise(
        postOrPutHttps(url, method, event_id ? baseData : formData, {
          company_id: selectCompany?.company_id,
          ...(locationName === "staff" && { user_id: +id }),
        }),
        {
          pending: pendingMessage,
          success: {
            onClose: () => {
              if (locationName === "staff") reset();
              getDetailData(id);
            },
            autoClose: 1000,
            render: successMessage,
          },
        }
      );
    } catch (error) {
      console.error("Hata oluştu:", error.response?.data?.message);
      console.log("Error status: ", error.response?.status);

      if (error.response?.status === 409) {
        console.log("409 hatası alındı, modal açılıyor...")
        setModalOpen(true);  // Kullanıcıya seçim yaptırmak için modalı aç
        console.log("Modal durumu:", isModalOpen);
        const conflictChoice = async (choice) => {
          setConflictOption(choice);
          setModalOpen(false);

          // Conflict çözümü için isteği tekrar gönder
          try {
            await toast.promise(
              postOrPutHttps(url, method, event_id ? baseData : formData, {
                company_id: selectCompany?.company_id,
                conflictOption: choice,
                ...(locationName === "staff" && { user_id: +id }),
              }),
              {
                pending: pendingMessage,
                success: {
                  onClose: () => {
                    if (locationName === "staff") reset();
                    getDetailData(id);
                  },
                  autoClose: 1000,
                  render: successMessage,
                }
              }
            );
          } catch (retryError) {
            console.error("Tekrar denemede hata oluştu: ", retryError.response?.data?.message);
          }
        };
        setHandleConflictChoice(() => conflictChoice);
      }
    }

    setSubmitting(false);
  };

  const handleFieldChange = (selectedValues) => {
    // Seçili öğeyi buluyoruz
    const selectedItem = workPlanOptions.find(option => option.value === selectedValues);

    // Seçili öğenin varlığını kontrol ediyoruz
    if (selectedItem) {
      setWorkDetail({
        branch_id: selectedItem.branch_id || '', // branch varsa kullan, yoksa boş string
        department_id: selectedItem.department_id || '', // department varsa kullan, yoksa company name'i döndür
        start_date: selectedItem.start || moment().format("YYYY-MM-DD HH:mm"), // start varsa kullan, yoksa mevcut zaman
        end_date: selectedItem.end || moment().format("YYYY-MM-DD HH:mm"), // end varsa kullan, yoksa mevcut zaman
        break_start: selectedItem.break_start || moment().format("HH:mm"),
        break_end: selectedItem.break_end || moment().format("HH:mm"),
        description: selectedItem.description
      });
    } else {
      // Eğer selectedItem bulunamazsa, bir hata mesajı gösterebilirsiniz veya varsayılan değerlere geçebilirsiniz
      console.error('Selected item or work calendar is undefined');
    }
  };

  return (
    <div className="w-full py-1 flex flex-col gap-3">
      <div>
        <p className="text-lg font-semibold capitalize">
          {locationName === "staff"
            ? `${t("general.select")} ${t("general.work_days")} `
            : locationName === "department" || locationName === "branches"
              ? `${t("general.select")} ${locationName} ${t(
                "general.holiday_days"
              )} `
              : ""}
        </p>
        <p className="capitalize"> {personnel?.name} </p>
        <p> {personnel?.email} </p>

        {locationName === "staff" && (
          <p>
            {t("crud.personnel.holiday_credit")} : {personnel?.holiday || "-"}
          </p>
        )}
      </div>
      <Formik
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sumbitFunction(values, setSubmitting, resetForm);
        }}
        enableReinitialize
        initialValues={workDetail}
      >
        {({ values, setFieldValue }) => (
          <Form
            className="flex flex-col h-full w-full justify-between"
            style={{ fontFamily: "Poppins" }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-y-2 xl:gap-x-30 gap-x-5">
              <div>
                <label htmlFor="work_plan_name" className="formTitle">
                  {t("general.select_work_plan")}
                </label>
                <Field
                  name="work_plan_name"
                  className="formInput"
                  options={workPlanOptions}
                  component={MultiSelectField}
                  onChange={handleFieldChange}
                />
                <ErrorMessage name="work_plan_name" component="div" className="text-red-600" />
              </div>

              {company?.branch && locationName === "staff" && (
                <div>
                  <label htmlFor="branch_id" className="formTitle">
                    {t("sidebar.branches")}
                  </label>
                  <Field
                    name="branch_id"
                    className="formInput"
                    clear
                    options={branchOptions}
                    component={MultiSelectField}
                  />
                  <ErrorMessage name="branch_id" component="div" className="text-red-600" />
                </div>
              )}
              {company?.branch && locationName === "branches" && (
                <div>
                  <label htmlFor="branch_id" className="formTitle">
                    {t("sidebar.branches")}
                  </label>
                  <Field
                    name="branch_id"
                    className="formInput"
                    clear
                    value={branchName}
                    disabled
                  />
                  <ErrorMessage name="branch_id" component="div" className="text-red-600" />
                </div>
              )}
              {company?.department && locationName === "staff" && (
                <div>
                  <label htmlFor="department_id" className="formTitle">
                    {t("sidebar.department")}
                  </label>
                  <Field
                    name="department_id"
                    className="formInput"
                    clear
                    options={departmentOptions}
                    component={MultiSelectField}
                  />
                  <ErrorMessage name="department_id" component="div" className="text-red-600" />
                </div>
              )}
              {company?.department && locationName === "department" && (
                <div>
                  <label htmlFor="department_id" className="formTitle">
                    {t("sidebar.department")}
                  </label>
                  <Field
                    name="department_id"
                    className="formInput"
                    clear
                    value={departmentName}
                    disabled
                  />
                  <ErrorMessage name="department_id" component="div" className="text-red-600" />
                </div>
              )}
              <div>
                <label htmlFor="description" className="formTitle">
                  {t("general.description")}
                </label>
                <Field
                  name="description"
                  className="formInput"
                  value={values.description}
                  onChange={(e) => setFieldValue('description', e.target.value)}
                />
                <ErrorMessage name="description" component="div" className="text-red-600" />
              </div>

              <div>
                <label htmlFor="start_date" className="formTitle">
                  {t("general.start_date")}
                </label>
                <div>
                  <DatePicker
                    value={values.start_date ? moment(values.start_date) : ""}
                    format={"YYYY-MM-DD"}
                    onChange={(date) => handleDateChange(date, moment(values.start_date), 'start_date', setFieldValue)}
                  />
                </div>
                <div>
                  <TimePicker
                    value={values.start_date ? moment(values.start_date) : ""}
                    minuteStep={15}
                    format={"HH:mm"}
                    onChange={(time) => handleDateChange(moment(values.start_date), time, 'start_date', setFieldValue)}
                  />
                </div>
                <div>
                  <label htmlFor="break_start" className="formTitle">
                    {t("general.break_start")}
                  </label>
                  <div>
                    <TimePicker
                      value={values.break_start ? moment(values.break_start, "HH:mm") : null}
                      minuteStep={15}
                      format="HH:mm"
                      onChange={(time) => handleTimeChange(time, "break_start", setFieldValue)}
                    />
                  </div>
                  {/* <TimePicker
                    value={values.break_start ? moment(values.break_start) : ""}
                    minuteStep={15}
                    format={"HH:mm"}
                    onChange={(time) => handleDateChange(moment(values.break_start), time, 'break_start', setFieldValue)}
                  /> */}
                </div>
                <div>
                  <label htmlFor="break_end" className="formTitle">
                    {t("general.break_end")}
                  </label>
                  <div>
                    <TimePicker
                      value={values.break_end ? moment(values.break_end, "HH:mm") : null}
                      minuteStep={15}
                      format="HH:mm"
                      onChange={(time) => handleTimeChange(time, "break_end", setFieldValue)}
                    />
                  </div>
                  {/* <TimePicker
                    value={values.break_end ? moment(values.break_end) : ""}
                    minuteStep={15}
                    format={"HH:mm"}
                    onChange={(time) => handleDateChange(moment(values.break_end), time, 'break_end', setFieldValue)}
                  /> */}
                </div>
              </div>
              <div>
                <label htmlFor="end_date" className="formTitle">
                  {t("general.end_date")}
                </label>
                <div>
                  <DatePicker
                    value={values.end_date ? moment(values.end_date) : ""}
                    format={"YYYY-MM-DD"}
                    onChange={(date) => handleDateChange(date, moment(values.end_date), 'end_date', setFieldValue)}
                  />
                </div>
                <div>
                  <TimePicker
                    value={values.end_date ? moment(values.end_date) : ""}
                    minuteStep={15}
                    format={"HH:mm"}
                    onChange={(time) => handleDateChange(moment(values.end_date), time, 'end_date', setFieldValue)}
                  />
                </div>
              </div>

              {/* todo mete check */}
              <div className="col-span-1 xxl:col-span-1 flex flex-col items-start gap-3 mt-4">
                <label htmlFor="isWorkPlan" className="formTitle mt-0">
                  {t("general.save_work_plan")}
                </label>
                <Field
                  name="isWorkPlan"
                  component={SwitchField}
                  style={{
                    backgroundColor: values.isWorkPlan ? "#407BFF" : "#acb3b5",
                  }}
                  checked={values.isWorkPlan}
                  checkedChildren={t("general.open_edit")}
                  unCheckedChildren={t("general.close_edit")}
                  onChange={(checked) => {
                    setFieldValue("isWorkPlan", checked);
                    setWorkPlanData((prev) => ({ ...prev, isWorkPlan: checked }));
                  }}
                />
              </div>
              {values.isWorkPlan && (
                <div>
                  <label htmlFor="workPlanName" className="formTitle">
                    {t("general.work_plan_name")}
                  </label>
                  <Field
                    name="name"
                    className="formInput"
                    onChange={(e) => setWorkPlanData({ ...workPlanData, name: e.target.value })}
                  />
                  <ErrorMessage name="workPlanName" component="div" className="text-red-600" />
                </div>
              )}
            </div>
            <div className="col-span-2 grid grid-cols-2 mt-5 gap-y-3 xl:gap-x-30 gap-x-5">
              {event_id && (
                <div
                  onClick={() => handleDelete(event_id)}
                  className="cursor-pointer bg-[#d43c3c] flex font-semibold items-center justify-center text-white p-3 rounded-xl hover:bg-[#e95454] duration-300 col-start-0"
                >
                  {t("general.delete")}
                </div>
              )}
              <button
                type="submit"
                className="bg-[#407BFF] font-semibold items-center justify-center text-white p-3 rounded-xl hover:bg-[#3db5e4] duration-300 col-start-2"
              >
                {event_id ? t("general.update") : t("general.save")}
              </button>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setModalOpen(false)}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Arka planı daha koyu yaparak odak arttır
                    zIndex: 1000,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  content: {
                    width: "600px",
                    maxWidth: "90%",
                    padding: "20px",
                    margin: "0 auto",
                    borderRadius: "12px", // Daha yumuşak köşeler
                    position: "relative",
                    inset: "auto",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)", // Daha belirgin gölge
                    backgroundColor: "#f9f9f9", // Hafif gri arka plan
                    border: "1px solid #e0e0e0" // İnce çerçeve
                  },
                }}
              >
                <h2 style={{ fontSize: "1.6rem", fontWeight: "bold", color: "#333", marginBottom: "1rem", textAlign: "center" }}>
                  {t("work_plan.detected_conflict")}
                </h2>
                <p style={{ marginBottom: "1.5rem", lineHeight: "1.5", color: "#666", textAlign: "center" }}>
                  {t("work_plan.choose_option")}
                </p>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", padding: "0 1rem" }}>
                  <button
                    onClick={() => handleConflictChoice("A")}
                    style={{
                      flex: 1,
                      padding: "8px",
                      backgroundColor: "#407BFF",
                      color: "#fff",
                      borderRadius: "8px", // Daha yuvarlak köşeler
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Butona gölge
                      transition: "background-color 0.3s", // Hover geçiş efekti
                    }}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#407BFF")}
                  >
                    {t("work_plan.option_a")}
                  </button>
                  <button
                    onClick={() => handleConflictChoice("B")}
                    style={{
                      flex: 1,
                      padding: "8px",
                      backgroundColor: "#407BFF",
                      color: "#fff",
                      borderRadius: "8px",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#407BFF")}
                  >
                    {t("work_plan.option_b")}
                  </button>
                  <button
                    onClick={() => handleConflictChoice("C")}
                    style={{
                      flex: 1,
                      padding: "8px",
                      backgroundColor: "#407BFF",
                      color: "#fff",
                      borderRadius: "8px",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#407BFF")}
                  >
                    {t("work_plan.option_c")}
                  </button>
                </div>
              </Modal>
              {/* <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} style={{ overlay: { zIndex: 1000 } }}>
                <h2>Kesişme tespit edildi. Bir seçenek belirleyin:</h2>
                <button onClick={() => handleConflictChoice("A")}> A Seçeneği</button>
                <button onClick={() => handleConflictChoice("B")}> B Seçeneği</button>
                <button onClick={() => handleConflictChoice("C")}> C Seçeneği</button>
              </Modal> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default WorkForm;
