import moment from "moment";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
// import AppointmentEvent from "./AppointmentEvent";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Calendar } from "../../../Calendar/Calendar";
import AppointmentEvent from "./AppointmentEvent";
import "./index.css";

const localizer = momentLocalizer(moment);

const initProps = {
  localizer: localizer,
  defaultDate: moment(),
  step: 15,
  timeslots: 4,
};

const DndCalendar = withDragAndDrop(BigCalendar);

const PersonelCalendarTwo = ({ calendarData, onShowAppointmentView }) => {
  const location = useLocation();

  const locationName = location.pathname?.split("/")[1];

  const [data, setData] = useState(calendarData);

  useEffect(() => {
    setData(calendarData);
  }, [calendarData]);

  const components = {
    event: ({ event }) => {
      const data = event?.data?.item;
      if (data)
        return <AppointmentEvent value={data} onDoubleClick={() => { }} />;
        console.log("Event: ", data)
      return null;
    },
  };

  console.log("components: ", components);

  const appointments = data?.map((item) => ({
    start: new Date(item.start),
    end: new Date(item.end),
    data: { item },
  }));

    console.log("appointments", appointments);

  return (
    <Calendar
      style={{ height: "100%" }}
      onSelectSlot={({ start, end }) => {
        onShowAppointmentView({ start, end });
      }}
      onDoubleClickEvent={(event) => {
        const selectData = event?.data?.item;
        event && onShowAppointmentView(selectData);
        console.log("event: ", selectData);
      }}
      events={appointments}
      components={components}
      selectable
      popup
      resizable
      {...initProps}
    />
  );
};

export default PersonelCalendarTwo;
