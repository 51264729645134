import { ConfigProvider } from "antd";
import da_DK from "antd/es/locale/da_DK";
import en from "antd/es/locale/en_US";
import tr_TR from "antd/es/locale/tr_TR";
import "dayjs/locale/tr";
import TimeAgo from "javascript-time-ago";
import da from "javascript-time-ago/locale/da.json";
import us from "javascript-time-ago/locale/en.json";
import tr from "javascript-time-ago/locale/tr.json";
import "leaflet/dist/leaflet.css";

import "react-big-calendar/lib/css/react-big-calendar.css";

import "moment/locale/da";
import "moment/locale/en-in";
import "moment/locale/tr";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import RoutesPage from "./components/routes/routes";
import { NotificationProvider } from "./context/NotifacitionContext";
import { AuthProvider } from "./context/UserContext";

import React, { useState } from 'react';
import CheckoutComponent from "./components/checkout/checkout-component";
import { Button } from "antd";

function App() {
  const [t, i18n] = useTranslation("global");
  TimeAgo.addLocale(tr);
  TimeAgo.addLocale(us);
  TimeAgo.addLocale(da);

  const [showModal, setShowModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const handleShowModal = (code) => {
    setVerificationCode(code);
    setShowModal(true);
  };

  const handleCloseModal = (result) => {
    console.log(result);
    setShowModal(false);
  };

  return (
    <>
      <ConfigProvider
        locale={
          i18n.language === "tr" ? tr_TR : i18n.language === "da" ? da_DK : en
        }
      >
        <AuthProvider>
          <NotificationProvider>
            <RoutesPage />
            {/* <Button onClick={() => handleShowModal("80edd637523640659a6be7e866c9435c")}>
              Open Checkout Modal
            </Button> */}
            <CheckoutComponent
              verificationCode={verificationCode}
              showModal={showModal}
              handleClose={handleCloseModal}
            />
            <ToastContainer />
          </NotificationProvider>
        </AuthProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
