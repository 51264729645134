import React from "react";

const CompanyBranchesIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M136.533 38.4h238.933v460.8H136.533z"
          fill="#000"
          data-original="#000"
        ></path>
        <path
          d="M136.533 371.2v128h238.934V105.719z"
          fill="#000"
          data-original="#000"
        ></path>
        <path
          d="M170.667 4.267h170.667V38.4H170.667z"
          fill="#000"
          data-original="#000"
        ></path>
        <path
          d="M170.667 21.333h170.667V38.4H170.667z"
          fill="#000"
          data-original="#000"
        ></path>
        <path
          d="M170.667 81.067H204.8V115.2h-34.133zM238.933 81.067h34.133V115.2h-34.133zM307.2 81.067h34.133V115.2H307.2zM170.667 149.333H204.8v34.133h-34.133zM238.933 149.333h34.133v34.133h-34.133zM307.2 149.333h34.133v34.133H307.2zM170.667 217.6H204.8v34.133h-34.133zM238.933 217.6h34.133v34.133h-34.133zM307.2 217.6h34.133v34.133H307.2zM170.667 285.867H204.8V320h-34.133zM238.933 285.867h34.133V320h-34.133zM307.2 285.867h34.133V320H307.2zM170.667 354.133H204.8v34.133h-34.133zM238.933 354.133h34.133v34.133h-34.133zM307.2 354.133h34.133v34.133H307.2zM204.8 430.933h102.4V499.2H204.8z"
          fill="#87ced9"
          data-original="#87ced9"
        ></path>
        <path
          d="M247.467 430.933h17.067V499.2h-17.067z"
          fill="#61acc9"
          data-original="#61acc9"
        ></path>
        <path
          d="M83.627 364.792a34.417 34.417 0 0 0 .657-19.285 33.817 33.817 0 0 0-24.866-24.55c-18.338-4.506-36.855 6.707-41.361 25.045a33.941 33.941 0 0 0 .205 17.066 49.307 49.307 0 0 1-4.011 37.999c-11.785 20.412-4.787 46.498 15.616 58.283s46.507 4.787 58.283-15.616a42.681 42.681 0 0 0 .7-41.412 48.002 48.002 0 0 1-5.223-37.53z"
          fill="#88b337"
          data-original="#88b337"
        ></path>
        <path
          d="M42.667 354.133h17.067v153.6H42.667z"
          fill="#79a125"
          data-original="#79a125"
        ></path>
        <path
          d="M493.227 364.792a34.417 34.417 0 0 0 .657-19.285 33.817 33.817 0 0 0-24.866-24.55c-18.338-4.506-36.855 6.707-41.361 25.045a33.941 33.941 0 0 0 .205 17.066 49.32 49.32 0 0 1-4.011 38.033c-11.785 20.412-4.787 46.498 15.616 58.283 20.403 11.785 46.498 4.787 58.283-15.616a42.681 42.681 0 0 0 .7-41.412 48.016 48.016 0 0 1-5.223-37.564z"
          fill="#88b337"
          data-original="#88b337"
        ></path>
        <path
          d="M452.267 354.133h17.067v153.6h-17.067z"
          fill="#79a125"
          data-original="#79a125"
        ></path>
        <path
          d="M0 490.667h512v17.067H0z"
          fill="#e0d2c1"
          data-original="#e0d2c1"
        ></path>
      </g>
    </svg>
  );
};

export default CompanyBranchesIcon;
