import moment from "moment";

export default [
  {
    id: 1,
    start: moment("2024-02-10 10:00").format("YYYY-MM-DD HH:mm"),
    end: moment("2024-02-11 11:00").format("YYYY-MM-DD HH:mm"),
    break_start: moment("12:00:00").format("HH:mm"),
    break_end: moment("13:00:00").format("HH:mm"),
    branch_name: "Branch 1",
    department_name: "Department 1",
    description: "Deneme"
  },
  {
    id: 2,
    start: moment("2024-02-10 11:00").format("YYYY-MM-DD HH:mm"),
    end: moment("2024-02-10 11:30").format("YYYY-MM-DD HH:mm"),
    break_start: moment("12:15:00").format("HH:mm"),
    break_end: moment("13:15:00").format("HH:mm"),
    branch_name: "Branch 2",
    department_name: "Department 2",
    description: "Deneme"
  },
  {
    id: 3,
    start: moment("2024-02-10 14:00").format("YYYY-MM-DD HH:mm"),
    end: moment("2024-02-10 15:30").format("YYYY-MM-DD HH:mm"),
    break_start: moment("12:00:30").format("HH:mm"),
    break_end: moment("13:00:30").format("HH:mm"),
    branch_name: "Branch 3",
    department_name: "Department 3",
    description: "Deneme"
  },
  {
    id: 4,
    start: moment("2024-02-11 12:00").format("YYYY-MM-DD HH:mm"),
    end: moment("2024-02-11 13:00").format("YYYY-MM-DD HH:mm"),
    break_start: moment("12:15:30").format("HH:mm"),
    break_end: moment("13:15:30").format("HH:mm"),
    branch_name: "Branch 4",
    department_name: "Department 4",
    description: "Deneme"
  },
  {
    id: 5,
    start: moment("2024-02-12 09:00").format("YYYY-MM-DD HH:mm"),
    end: moment("2024-02-12 14:59").format("YYYY-MM-DD HH:mm"),
    break_start: moment("12:15:29").format("HH:mm"),
    break_end: moment("13:15:29").format("HH:mm"),
    branch_name: "Branch 5",
    department_name: "Department 5",
    description: "Deneme"
  },
];
