import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReceivedMessages from "../components/Modules/Message/ReceivedMessages";
import SentMessages from "../components/Modules/Message/SentMessages";
import SubmitMessage from "../components/Modules/Message/SubmitMessage";
import MessageIcon from "../components/icons/General/MessageIcon";
import MessageIconWithCount from "../components/icons/Sidebar/MessageIconWithCount";
import CompanyHeader from "../components/Modules/Company/CompanyHeader";
import { useNotificationContext } from "../context/NotifacitionContext";
import { useAuthContext } from "../context/UserContext";

const Message = () => {
  const [t, i18n] = useTranslation("global");
  const [station, setStation] = useState(0);
  const { setTitle } = useNotificationContext();
  const [showReceivedMessages, setShowReceivedMessages] = useState(false);
  const [showSentMessages, setShowSentMessages] = useState(false);
  const { profil } = useAuthContext();

  const tabsArray = [
    {
      id: 0,
      title: t("general.submit_message"),
      image: <MessageIcon width={22} />,
    },
    {
      id: 1,
      title: t("general.received_messages"),
      image: <MessageIconWithCount user_id={profil?.userId} />,
      onClick: () => {
        setShowReceivedMessages(true);
    }},
    {
      id: 2, title: t("general.sent_messages"),
      image: <MessageIcon width={22} />,
      onClick: () => setShowSentMessages(true)
    },
  ];

  const selectComponent = (data) => {
    switch (data) {
      case 0:
        return <SubmitMessage />;
      case 1:
        return <ReceivedMessages show={setShowReceivedMessages} onCancel={() => setStation(0)}/>;
      case 2:
        return <SentMessages show={setShowSentMessages} onCancel={() => setStation(0)} />;
      default:
        return <SubmitMessage />;
    }
  };

  useEffect(() => {
    console.log("profil: ", profil)
    setTitle([t("sidebar.message")]);
  }, [i18n.language]);

  return (
    <div className="flex flex-col h-full w-full items-center">
      <div className="flex sticky w-full">
        <CompanyHeader
          tabsArray={tabsArray}
          setStation={setStation}
          station={station}
        />
      </div>
      <div className="changePageAnimation z-0 w-full h-full">
        {selectComponent(station)}
      </div>
      <ReceivedMessages
        show={showReceivedMessages}
        onCancel={() => {
          setShowReceivedMessages(false);
          setStation(0);
        }}
      />
      <SentMessages
        show={showSentMessages}
        onCancel={() => {
          setShowSentMessages(false);
          setStation(0);
        }}
      />
    </div>
  );
};

export default Message;
